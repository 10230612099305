<template>
  <v-container v-if="currentAccountLoaded" grid-list-md fluid>
    <v-layout row wrap class="mb-2">
      <div>
        <span class="title pb-1"
          >{{ currentAccount.name }} ({{ currentAccount.nameShort }})</span
        >
        <p class="secondary--text">
          View and manage the account information for
          <b>{{ currentAccount.nameShort }}</b
          >.
        </p>
        <div>
          <a :href="chargebeeUrl" target="_blank"
            >Click here to open Chargebee site</a
          >
        </div>

        <a :href="hubspotUrl" target="_blank"
          >Click here to open Hubspot site</a
        >
      </div>
      <v-spacer></v-spacer>
      <preferred-managers :current-account="currentAccount" />
    </v-layout>

    <v-layout row wrap>
      <v-flex xs12>
        <v-tabs
          :value="activeTabIndex"
          light
          slider-color="light-blue"
          show-arrows
        >
          <v-tab @click="goRoute('editAccount')">
            <span class="light-blue--text">Overview</span>
          </v-tab>
          <v-tab @click="goRoute('accountConsents')">
            <span class="light-blue--text">Consents</span>
          </v-tab>
          <v-tab @click="goRoute('accountAgreements')">
            <span class="light-blue--text">Agreements</span>
          </v-tab>
          <v-tab @click="goRoute('accountChecklists')">
            <span class="light-blue--text">Checklists</span>
          </v-tab>
          <v-tab @click="goRoute('accountSubscription')">
            <span class="light-blue--text">Subscription</span>
          </v-tab>
        </v-tabs>
      </v-flex>
    </v-layout>

    <transition name="slide-fade" mode="out-in">
      <router-view
        :data-last-fetched-at="dataLastFetchedAt"
        :current-account="currentAccount"
      />
    </transition>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import PreferredManagers from './AdminAccountPreferredManagers';

export default {
  name: 'AdminAccountMain',
  components: {
    PreferredManagers
  },
  data() {
    return {
      accountKey: this.$route.params.accountKey
    };
  },
  computed: {
    ...mapGetters('accounts', ['accountByKey', 'dataLastFetchedAt']),
    currentAccount() {
      return this.accountByKey(this.accountKey);
    },
    currentAccountLoaded() {
      return !!this.currentAccount;
    },
    activeTabIndex() {
      // Set correct slider position on reload
      return this.$route.meta.tabIndex || 0;
    },
    chargebeeUrl() {
      const chargebeeLink = process.env.VUE_APP_CHARGEBEE_SITE || 'getquorum';

      return `https://${chargebeeLink}.chargebee.com/d/customers/${this.$route.params.accountKey}`;
    },

    hubspotUrl() {
      const hubspotSite = process.env.VUE_APP_HUBSPOT_SITE || '24343778';

      const companyId = this.currentAccount.hubspotCompanyId;
      return `https://app.hubspot.com/contacts/${hubspotSite}/company/${companyId}`;
    }
  },
  async created() {
    // Reload the latest account data to prevent stale data

    await this.getAccount({
      key: this.accountKey,
      includeConsents: true,
      includeAgreements: true,
      includePreferred: true,
      includeMeetings: true,
      includeAgents: true,
      includeUsers: true,
      includeSubscription: true,
      includeAccountInvoices: true,
      includeInvoices: true
    });
  },
  methods: {
    ...mapActions('accounts', ['getAccount']),
    goRoute(name) {
      this.$router.replace({ name });
    }
  }
};
</script>

<style scoped></style>
