<template>
  <div>
    <v-tooltip v-if="consentStatus" bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-chip
          small
          color="green lighten-1"
          text-color="white"
          class="px-1"
          v-bind="attrs"
          v-on="on"
        >
          <v-icon left>{{ icon }}</v-icon>
          {{ labelText }}
        </v-chip>
      </template>
      <span>Consented on: {{ formatDateReadable(timestamp) }}</span>
    </v-tooltip>
    <v-tooltip v-else-if="consentStatus === false" bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-chip
          small
          color="red lighten-1"
          text-color="white"
          class="px-1"
          v-bind="attrs"
          v-on="on"
        >
          <v-icon left>not_interested</v-icon>
          {{ labelText }}
        </v-chip>
      </template>
      <span>Rejected on: {{ formatDateReadable(timestamp) }}</span>
    </v-tooltip>
  </div>
</template>

<script>
import { formatDateHumanReadable } from '@/helpers';

export default {
  name: 'OwnerMultiConsentChip',
  props: {
    consentStatus: {
      type: Boolean,
      default: null
    },
    icon: {
      type: String,
      default: ''
    },
    labelText: {
      type: String,
      default: ''
    },
    timestamp: {
      type: String,
      default: '',
      validator: function(value) {
        const date = new Date(value);
        return !isNaN(date);
      }
    }
  },
  methods: {
    formatDateReadable(date) {
      // Check if date is valid
      const parsedDate = date ? new Date(date) : NaN;
      return isNaN(parsedDate)
        ? 'Invalid date'
        : formatDateHumanReadable(date, false, this.meetingTimezone);
    }
  }
};
</script>

<style scoped></style>
