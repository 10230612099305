<template>
  <v-card
    flat
    color="grey lighten-4"
    class="rounded-card"
    height="100%"
    hover
    @click.native="ownerClicked(voter)"
  >
    <v-card-text class="px-4 py-4">
      <v-layout row align-start>
        <v-icon class="mr-3">account_circle</v-icon>

        <div style="width: 100%;">
          <v-layout row align-center>
            <div style="width: 100%;">
              <v-layout row align-center>
                <b class="px-1 mr-1">{{ voter.name }}</b>
                <v-tooltip v-if="voter.isPanelist" bottom>
                  <v-chip
                    slot="activator"
                    small
                    color="primary"
                    text-color="white"
                    class="px-1"
                  >
                    Panelist
                    <v-icon right>voice_chat</v-icon>
                  </v-chip>
                  <span>Owner is a Panelist</span>
                </v-tooltip>

                <v-spacer></v-spacer>

                <!-- RSVP Chips -->
                <v-tooltip v-if="voter.attending" bottom>
                  <v-chip
                    slot="activator"
                    small
                    color="blue lighten-1"
                    text-color="white"
                    class="px-1"
                    ><v-icon left>how_to_reg</v-icon>
                    RSVP
                  </v-chip>
                  <span>Owner has RSVP'd</span>
                </v-tooltip>

                <!-- Consented email chips -->
                <div
                  v-if="
                    displayConsentDetails && consentFormat !== 'multi-consent'
                  "
                >
                  <v-chip
                    v-if="hasStatusAndRealEmail"
                    small
                    color="green lighten-1"
                    text-color="white"
                    class="px-1"
                  >
                    <v-icon left>mail</v-icon>
                    Consented:
                    {{
                      voter.consent.status === 'consented'
                        ? formatDateReadable(voter.consent.timestamp)
                        : 'N/A'
                    }}
                  </v-chip>
                  <v-chip
                    v-else-if="voter.consent.status === 'rejected'"
                    small
                    color="red lighten-1"
                    text-color="white"
                    class="px-1"
                  >
                    <v-icon left>mail</v-icon>
                    Rejected:
                    {{
                      voter.consent.status === 'rejected'
                        ? formatDateReadable(voter.consent.timestamp)
                        : 'N/A'
                    }}
                  </v-chip>
                </div>

                <!-- Multi-consent chips -->
                <div
                  v-if="
                    displayConsentDetails && consentFormat === 'multi-consent'
                  "
                >
                  <v-layout row wrap>
                    <owner-multi-consent-chip
                      :consent-status="voter.consent.notice_consent"
                      icon="mail"
                      label-text="Notices"
                      :timestamp="voter.consent.timestamp"
                    />

                    <owner-multi-consent-chip
                      :consent-status="voter.consent.voting_consent"
                      icon="ballot"
                      label-text="Voting"
                      :timestamp="voter.consent.timestamp"
                    />
                  </v-layout>
                </div>
              </v-layout>
              <div>
                {{ voter.email }}
              </div>
            </div>
          </v-layout>

          <v-layout row space-between class="mt-3">
            <v-flex xs6>
              <b>Voter ID:</b>
              <div>{{ voter.id }}</div>
            </v-flex>
            <v-flex xs-6>
              <v-layout row justify-end>
                <div v-if="voter.lastInviteDate">
                  <div>Last meeting invite was sent on:</div>
                  <div>
                    {{
                      voter.lastInviteDate
                        | formatDateHumanReadableAlt(true, meetingTimezone)
                    }}
                  </div>
                </div>
                <v-btn
                  :disabled="isInvalidEmail(voter.email)"
                  outline
                  color="primary"
                  @click.stop="sendInvite(voter)"
                  ><v-icon class="mr-2">mail_outline</v-icon>Email Voter</v-btn
                >
              </v-layout>
            </v-flex>
          </v-layout>

          <v-divider v-if="hasAdditionalInfo" class="my-3"></v-divider>

          <div>
            <v-tooltip bottom v-if="voter.inAttendance">
              <v-chip
                v-if="voter.inAttendance"
                slot="activator"
                small
                color="purple lighten-1"
                text-color="white"
                class="px-1"
              >
                In Attendance
                <v-icon right>person_pin_circle</v-icon>
              </v-chip>

              <span>Owner is attending event</span>
            </v-tooltip>

            <v-tooltip v-if="voter.communicationPreferences.isTestVoter" bottom>
              <v-chip
                slot="activator"
                small
                color="primary"
                text-color="white"
                class="px-1"
              >
                Test voter
                <v-icon right>account_box</v-icon>
              </v-chip>
              <span>Owner is a Test voter</span>
            </v-tooltip>

            <v-tooltip
              v-if="voter.communicationPreferences.fromRegistration"
              bottom
            >
              <v-chip
                slot="activator"
                small
                color="primary"
                text-color="white"
                class="px-1"
              >
                Self-Registered
                <v-icon right>confirmation_number</v-icon>
              </v-chip>
              <span>Voter Self-Registered with Control Number</span>
            </v-tooltip>

            <v-tooltip
              v-if="voter.communicationPreferences.disableConsentReminders"
              bottom
            >
              <v-chip
                slot="activator"
                small
                color="red lighten-1"
                text-color="white"
                class="px-1"
              >
                Reminders
                <v-icon right>unsubscribe</v-icon>
              </v-chip>

              <span>Owner has unsubscribed from consent reminders</span>
            </v-tooltip>

            <v-tooltip
              v-if="voter.communicationPreferences.disableAllEmails"
              bottom
            >
              <v-chip
                slot="activator"
                small
                color="red lighten-1"
                text-color="white"
                class="px-1"
              >
                Emails
                <v-icon right>speaker_notes_off</v-icon>
              </v-chip>

              <span>Owner has unsubscribed from all emails</span>
            </v-tooltip>

            <v-tooltip v-if="voter.isMeetingHost" bottom>
              <v-chip slot="activator" small class="px-1">
                Host
                <v-icon right>mic</v-icon>
              </v-chip>

              <span>Owner is Custom Zoom Meeting Host</span>
            </v-tooltip>
          </div>

          <v-layout
            v-if="voter.parsedBrowserData"
            row
            space-between
            d-flex
            class="mt-3"
          >
            <div class="text-capitalize" v-if="voter.parsedBrowserData.version">
              <b>Device: </b> {{ voter.parsedBrowserData.device }}
            </div>
            <div v-if="voter.parsedBrowserData.os">
              <b>OS: </b>{{ voter.parsedBrowserData.os }}
            </div>
            <div v-if="voter.parsedBrowserData.browser">
              <b>Browser: </b>{{ voter.parsedBrowserData.browser }}
              {{ voter.parsedBrowserData.version }}
            </div>
          </v-layout>
        </div>
      </v-layout>
    </v-card-text>
  </v-card>
</template>

<script>
import { formatDateHumanReadable } from '@/helpers';
import { formatDateHumanReadableAlt } from '@/filters';
import OwnerMultiConsentChip from './OwnerMultiConsentChip';

export default {
  name: 'OwnersChip',
  components: {
    OwnerMultiConsentChip
  },
  props: {
    voter: {
      type: Object,
      default: () => {
        return {};
      }
    },
    meetingTimezone: {
      type: String,
      required: true
    },
    displayConsentDetails: {
      type: Boolean,
      default: true
    },
    consentFormat: {
      type: String,
      default: ''
    }
  },
  filters: {
    formatDateHumanReadableAlt
  },
  computed: {
    hasAdditionalInfo() {
      return (
        this.voter.inAttendance ||
        this.voter.parsedBrowserData ||
        this.voter.isMeetingHost ||
        this.voter.communicationPreferences.isTestVoter ||
        this.voter.communicationPreferences.disableConsentReminders ||
        this.voter.communicationPreferences.disableAllEmails
      );
    },
    hasStatusAndRealEmail() {
      return (
        this.voter.consent.status === 'consented' &&
        this.voter.email !== 'noemail@getquorum.com'
      );
    }
  },
  methods: {
    ownerClicked(voter) {
      this.$emit('owner-clicked', voter);
    },
    sendInvite(voter) {
      this.$emit('send-invite', voter);
    },
    formatDateReadable(date) {
      return formatDateHumanReadable(date, false, this.meetingTimezone);
    },
    isInvalidEmail(email) {
      return email === 'noemail@getquorum.com';
    }
  }
};
</script>

<style scoped>
.rounded-card {
  border-radius: 20px;
}
</style>
