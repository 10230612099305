import { render, staticRenderFns } from "./MeetingUnitsEditVoterDialog.vue?vue&type=template&id=5deed4dc&scoped=true"
import script from "./MeetingUnitsEditVoterDialog.vue?vue&type=script&lang=js"
export * from "./MeetingUnitsEditVoterDialog.vue?vue&type=script&lang=js"
import style0 from "./MeetingUnitsEditVoterDialog.vue?vue&type=style&index=0&id=5deed4dc&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5deed4dc",
  null
  
)

export default component.exports