import Vue from 'vue';
import VueRouter from 'vue-router';
import multiguard from 'vue-router-multiguard';

Vue.use(VueRouter);

import { loggedIn, clearAuth } from '@/auth';

// Login
import Login from '@/views/LoginView';
import LoginMFAView from '@/views/LoginMFAView';
import ForgotPassword from '@/views/ForgotPasswordView';
import ResetPassword from '@/views/ResetPasswordView';
import Unauthenticated from '@/views/UnauthenticatedView';
import UnderMaintenance from '@/views/UnderMaintenanceView';
import ChangePassword from '@/views/ChangePasswordView';
import InactiveProfile from '@/views/InactiveProfileView';

// Billing
import Billing from '@/views/BillingRedirectView';

// Meetings
import Meetings from '@/views/meetings/MeetingsMain';
import meetingsRoutes from './meetings';

// Admin
import AdminMain from '@/views/admin/AdminMain';
import adminRoutes from './admin';

// Voters
import Voters from '@/views/voters/VotersMain';
import votersRoutes from './voters';

// The Vuex Store
import store from '@/vuex/store';
import { requiredAdminLevel } from './utils';
import _debug from '@/lib/debug';

export default new VueRouter({
  mode: 'history',
  base: __dirname,
  routes: [
    {
      name: 'login',
      path: '/',
      component: Login
    },
    {
      name: 'verify',
      path: '/verify',
      component: LoginMFAView,
      props: true
    },
    {
      name: 'forgotPassword',
      path: '/forgot-password',
      component: ForgotPassword
    },
    {
      name: 'resetPassword',
      path: '/reset-password',
      component: ResetPassword
    },
    {
      name: 'changePassword',
      path: '/change-password',
      component: ChangePassword,
      beforeEnter: requireAuth
    },
    {
      name: 'inactiveProfile',
      path: '/inactive-profile',
      component: InactiveProfile
    },
    {
      name: 'invite',
      path: '/invite',
      component: ResetPassword
    },
    {
      name: 'meetings',
      path: '/meetings',
      component: Meetings,
      beforeEnter: requireAuth,
      children: meetingsRoutes
    },
    {
      path: '/admin',
      component: AdminMain,
      beforeEnter: multiguard([requireAuth, requiredAdminLevel]),
      children: adminRoutes
    },
    {
      path: '/billing',
      name: 'billing',
      component: Billing,
      beforeEnter: requireAuth
    },
    {
      name: 'unauthenticated',
      meta: {},
      path: '/unauthenticated',
      component: Unauthenticated
    },
    {
      name: 'maintenance',
      meta: {},
      path: '/maintenance',
      component: UnderMaintenance
    },
    {
      name: 'logout',
      path: '/logout',
      beforeEnter(to, from, next) {
        clearAuth();
        window.location.href = '/';
        next();
      }
    },
    {
      path: '/voters',
      component: Voters,
      beforeEnter: requireAuth,
      children: votersRoutes
    },
    {
      path: '*',
      redirect: '/unauthenticated'
    }
  ]
});

/**
 * Hook for routes that require user authentication before entering. Redirects
 * to `login` page if not authenticated.
 *
 * @param {string} to - Route: the target Route Object being navigated to.
 * @param {string} from - Route: the current route being navigated away from.
 * @param {string} next - Function: this function must be called to resolve the hook.
 */
async function requireAuth(to, from, next) {
  const redirect = window.location.href;
  try {
    // Check if a current login session exists (ie. that app has been open for some time).
    // If so we can depend on protected API route calls to ensure user has access to
    // resources within that route.
    if (loggedIn()) {
      return next();
    }

    // Otherwise, this may be the first browser session after a long time away. We need
    // to get the user's profile and check if the `access_token` is still valid
    const user = await store.dispatch('getUserProfile');

    // If userprofile not returned, means `access_token` is not valid. We need to check
    // if session is still valid refreshing the `access_token`.
    if (!user) {
      await store.dispatch('doRefreshToken');
    }

    // We'll get here if session is still valid.
    if (loggedIn()) {
      return next();
    }
    // Session has expired
    return next({
      name: 'login',
      query: { alert: 'session-expired', redirect: redirect }
    });
  } catch (err) {
    _debug('Session no longer valid', to, err);
    next({
      name: 'login',
      query: { alert: 'unauthenticated', redirect: redirect }
    });
  }
}
