<template>
  <v-container>
    <div class="gq-cell--section-title">
      <span>Proxy Questions</span>
    </div>

    <!-- Action bar -->
    <v-toolbar dense dark color="grey lighten-4 elevation-1">
      <v-spacer />
      <v-btn
        class="secondary"
        dark
        @click="addAllToLiveVote"
        :loading="loading.copyAll"
      >
        <v-icon>add</v-icon>Copy All to Live Vote
      </v-btn>

      <v-btn
        class="primary"
        dark
        v-if="isAdmin"
        @click="dialog.addBusiness = true"
      >
        <v-icon>add</v-icon>Add Question
      </v-btn>
      <br />
    </v-toolbar>

    <v-data-table
      :headers="headers"
      :items="businessState"
      hide-actions
      class="elevation-2"
    >
      <template slot="items" slot-scope="props">
        <tr :key="props.item.id">
          <td class="handle" width="10px" v-if="isAdmin">
            <v-icon>drag_handle</v-icon>
          </td>
          <td
            class="text-xs-left pointer"
            @click="handleClick(props.item.id)"
            v-if="isAdmin"
          >
            <span>{{ props.item.order + 1 }}. {{ props.item.name }}</span
            ><br />
            <span v-if="hasRestrictions(props.item)" class="caption fade"
              >Limit voting to
              <b>{{ props.item | formatRestrictions }}</b></span
            >
          </td>
          <!-- IF not admin, show the question header text.
          The question "name" field is only relevant for labels on proxy forms -->
          <td class="text-xs-left pointer" v-if="!isAdmin">
            <span>{{ props.item.header }}</span
            ><br />
            <span v-if="hasRestrictions(props.item)" class="caption fade"
              >Limit voting to
              <b>{{ props.item | formatRestrictions }}</b></span
            >
          </td>

          <td class="pointer">
            <v-tooltip bottom v-if="props.item.positions === 1">
              <v-icon slot="activator" color="primary">person</v-icon>
              <span>Single Choice</span>
            </v-tooltip>
            <v-tooltip bottom v-else>
              <v-icon slot="activator" color="primary">group_add</v-icon>
              <span>Multiple Choice</span>
            </v-tooltip>
            <v-tooltip bottom v-if="props.item.type === 'owner-occupied'">
              <v-icon slot="activator" color="deep-orange">home</v-icon>
              <span>Owner-Occupied</span>
            </v-tooltip>
            <v-tooltip bottom v-if="props.item.type === 'by-law'">
              <v-icon
                slot="activator"
                size="19"
                class="icon-padding ml-1"
                color="blue"
                >gavel</v-icon
              >
              <span>By-Law</span>
            </v-tooltip>
          </td>

          <!-- Only admins can toggle enabled/disabled states for questions -->
          <td class="text-xs-left pointer" v-if="isAdmin">
            <v-btn
              small
              depressed
              :class="!props.item.disabledAt ? 'success' : 'disabled'"
              class="ml-0 lighten-1"
              @click="statusToggleClick(props.item.id, !props.item.disabledAt)"
            >
              <strong>{{
                !props.item.disabledAt ? 'Enabled' : 'Disabled'
              }}</strong>
            </v-btn>

            <v-btn
              small
              depressed
              class="lighten-1"
              @click="updateBusiness(props.item)"
            >
              <v-icon left>{{
                !props.item.isSecret ? 'visibility' : 'visibility_off'
              }}</v-icon>
              <span>{{
                !props.item.isSecret ? 'results visible to all' : 'admin only'
              }}</span>
            </v-btn>
          </td>

          <!-- Unclickable chips are substituted in for partner-hosts -->
          <td class="text-xs-left" v-else>
            <v-chip
              small
              flat
              disabled
              label
              :class="!props.item.disabledAt ? 'success' : 'disabled'"
              class="ml-0 lighten-1 white--text"
            >
              <strong>{{
                !props.item.disabledAt ? 'ENABLED' : 'DISABLED'
              }}</strong>
            </v-chip>
          </td>
          <td class="text-xs-left pointer">
            <v-menu offset-y>
              <template v-slot:activator="{ on }">
                <v-btn class="ml-0" v-on="on" depressed fab small>
                  <v-icon>more_vert</v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-tile @click="addToRTVQuestions(props.item)">
                  <v-list-tile-avatar
                    ><v-icon>control_point</v-icon></v-list-tile-avatar
                  >
                  <v-list-tile-content
                    >Add to Live Vote</v-list-tile-content
                  ></v-list-tile
                >
              </v-list>
            </v-menu>
          </td>
        </tr>
      </template>
    </v-data-table>

    <!-- Dialogs -->
    <meeting-business-add-dialog
      v-model="dialog.addBusiness"
      :loading="loading.addBusiness"
      @close="dialog.addBusiness = false"
      @add="addBusiness"
    />
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Sortable from 'sortablejs';
import MeetingBusinessAddDialog from './MeetingBusinessAddDialog';
import _ from 'lodash';

import { formatRestrictions } from '@/filters';

export default {
  name: 'MeetingBusinessListView',
  components: {
    MeetingBusinessAddDialog
  },
  props: {
    currentMeeting: {
      type: Object,
      required: true
    },
    businessData: {
      type: Array,
      default: function() {
        return [];
      }
    }
  },
  filters: { formatRestrictions },
  data() {
    return {
      shortCode: this.$route.params.shortcode,
      headers: [
        {
          text: '',
          align: 'left',
          sortable: false,
          adminOnly: true
        },
        {
          text: 'Question',
          align: 'left',
          value: 'name',
          sortable: false
        },
        {
          text: 'Type',
          align: 'left',
          value: 'type',
          sortable: false
        },
        {
          text: '',
          align: 'left',
          value: 'disabledAt',
          sortable: false
        },
        {
          text: 'Options',
          align: 'left',
          sortable: false
        }
      ],
      businessState: [],
      dialog: {
        addBusiness: false
      },
      loading: {
        addBusiness: false,
        copyAll: false
      }
    };
  },
  computed: {
    ...mapGetters(['isAdmin']),
    businessStateOrderArray() {
      return this.businessState.map(d => d.id);
    }
  },
  watch: {
    // Update local business state on order change
    businessData: function() {
      this.initState();
    }
  },
  created() {
    this.initState();
  },
  mounted() {
    let table = document.querySelector('.v-datatable tbody');
    const _self = this;

    Sortable.create(table, {
      handle: '.handle',
      onEnd({ newIndex, oldIndex }) {
        const rowSelected = _self.businessState.splice(oldIndex, 1)[0]; // Get the selected row and remove it
        _self.businessState.splice(newIndex, 0, rowSelected); // Move it to the new index

        let arrayOrder = _self.businessState.map(d => d.id);
        _self.updateBusinessOrder(arrayOrder);
      }
    });
  },
  methods: {
    ...mapActions('meetings/business', [
      'changeMeetingBusinessOrder',
      'changeMeetingBusinessStatus',
      'createMeetingBusiness',
      'updateMeetingBusiness'
    ]),
    ...mapActions('meetings/rtvQuestions', ['createMeetingRTVQuestion']),
    initState() {
      this.businessState = _.cloneDeep(this.businessData);

      // If you're not an admin, filter out admin only headers
      if (!this.isAdmin) {
        this.headers = this.headers.filter(header => !header.adminOnly);
      }
    },
    hasRestrictions(business) {
      return !_.isEmpty(business.restrictions);
    },
    handleClick(id) {
      this.$router.push({
        name: 'meetingEditBusiness',
        params: {
          shortcode: this.shortCode,
          questionId: id
        }
      });
    },
    async updateBusiness(business) {
      business.isSecret = !business.isSecret;
      business.snippet = business.snippet ? business.snippet : '<p></p>';
      try {
        await this.updateMeetingBusiness({
          business,
          shortCode: this.shortCode,
          questionId: business.id
        });
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      }
    },
    async statusToggleClick(id, disabledAt) {
      try {
        const statusObj = { status: disabledAt ? 'disable' : 'enable' };
        await this.changeMeetingBusinessStatus({
          id,
          statusObj,
          shortCode: this.shortCode
        });
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      }
    },
    async addBusiness(payload) {
      this.loading.addBusiness = true;
      const openBallot = this.currentMeeting.options.openBallot ? true : false;
      const isOntario =
        this.currentMeeting.options.proxyTemplate === 'on-bill-106';
      try {
        await this.createMeetingBusiness({
          shortCode: this.shortCode,
          newBusiness: payload,
          order: this.businessStateOrderArray.length,
          openBallot,
          isOntario
        });
        this.loading.addBusiness = false;
        this.dialog.addBusiness = false;
      } catch (err) {
        this.dialog.addBusiness = false;
        this.$events.$emit('showErrorDialog', err.response);
      }
    },
    async updateBusinessOrder(arrayOrder) {
      try {
        await this.changeMeetingBusinessOrder({
          shortCode: this.shortCode,
          orderArray: arrayOrder
        });
        this.$events.$emit('toastEvent', 'Business Order Updated');
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      }
    },
    async addToRTVQuestions(question, copyAll = false) {
      // Copy an existing Advance Vote question into the Live Vote table
      try {
        // Retrieve and sanitize fields necessary to create RTV Question object
        const questionLabel =
          question.header === 'Add a header'
            ? 'New Question!'
            : question.header;

        let questionChoices = [];
        if (question.type === 'by-law') {
          // check if the question has custom label for choices
          if (!_.isEmpty(question.meta) && question.meta.options.toggles) {
            const {
              options: { toggles = {} }
            } = question.meta;

            _.unset(toggles, 'defer'); // exclude defer from choices

            questionChoices = _.map(toggles, (toggle, index) => {
              if (toggle.visible) {
                return toggle.label;
              } else {
                if (index === 'abstain') {
                  return 'I choose not to vote';
                }

                return _.startCase(index);
              }
            });
          } else {
            questionChoices = [`Yes`, `No`, 'I choose not to vote'];
          }
        } else {
          questionChoices =
            question.options.length === 0
              ? ['Enter choices here...']
              : question.options;
        }

        const questionMaxChoices = question.positions || 1;

        const questionOwnerOccupied =
          question.type === 'owner-occupied' ? true : false;

        // Create a new Question object with sanitized fields
        const copiedQuestion = {
          label: questionLabel,
          choices: questionChoices,
          maxChoices: questionMaxChoices,
          startAt: null,
          endAt: null,
          meta: {},
          ownerOccupied: questionOwnerOccupied,
          questionKey: question.questionKey
        };

        // Create restrictions for the new Question as well
        const restrictions = question.restrictions;

        // POST the new RTV Question
        await this.createMeetingRTVQuestion({
          shortCode: this.shortCode,
          question: { ...copiedQuestion, actionLogTag: 'copy' },
          restrictions
        });
        if (!copyAll) {
          this.$events.$emit('toastEvent', 'Added to Live Voting');
        }
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      }
    },
    addAllToLiveVote() {
      this.loading.copyAll = true;
      this.businessState.forEach(question => {
        this.addToRTVQuestions(question, true);
      });
      this.loading.copyAll = false;
      this.$events.$emit('toastEvent', 'Copied to Live Voting');
    }
  }
};
</script>

<style lang="scss" scoped>
.handle {
  cursor: move;
}
.pointer {
  cursor: pointer;
}
.icon-padding {
  position: relative;
  bottom: 3px;
}
.fade {
  opacity: 0.74;
}
</style>
