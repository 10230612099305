<template>
  <v-container grid-list-sm>
    <v-expansion-panel v-model="panel" expand class="mt-2">
      <v-expansion-panel-content>
        <template v-slot:header>Contact</template>
        <v-card class="px-4 py-4">
          <v-text-field
            prepend-icon="person"
            label="Name"
            :value="contact.name"
            @input="emitUpdate('contact.name', $event)"
            :rules="rules.name"
            required
          />
          <v-text-field
            prepend-icon="person"
            label="Preferred Name"
            :value="contact.preferredName"
            @input="emitUpdate('contact.preferredName', $event)"
            :rules="rules.preferredName"
            required
          />
          <v-text-field
            prepend-icon="email"
            label="Email"
            :value="contact.email"
            hint="sample@email.com"
            @input="emitUpdate('contact.email', $event)"
            :rules="rules.email"
            required
          />
          <v-text-field
            prepend-icon="phone"
            label="Phone"
            :value="contact.phone"
            hint="(555) 555 - 5555"
            @input="emitUpdate('contact.phone', $event)"
            :rules="rules.phone"
            required
          />
        </v-card>
      </v-expansion-panel-content>
      <v-expansion-panel-content>
        <template v-slot:header>Billing Contact</template>
        <v-card class="px-4 py-4">
          <v-card class="mb-4" tile>
            <v-card-text>
              <v-layout row align-center justify-center>
                <v-flex xs6>
                  <div>
                    Select an option to pre-populate the billing contact:
                  </div></v-flex
                >
                <v-flex xs6
                  ><v-select
                    :items="billingContactOptions"
                    v-model="prepopulatedBillingContact"
                    dense
                    item-text="text"
                    item-value="value"
                  ></v-select>
                </v-flex> </v-layout
            ></v-card-text>
          </v-card>
          <div v-if="prepopulatedBillingContact">
            <v-text-field
              prepend-icon="person"
              label="Billing contact name"
              :value="billingContact.name"
              :disabled="samePrimary"
              @input="emitUpdate('data.billingContact.name', $event)"
            />
            <v-text-field
              prepend-icon="person"
              label="Billing contact preferred name"
              :value="billingContact.preferredName"
              :disabled="samePrimary"
              @input="emitUpdate('data.billingContact.preferredName', $event)"
            />
            <v-text-field
              prepend-icon="email"
              label="Billing contact email"
              :value="billingContact.email"
              hint="sample@email.com"
              :disabled="samePrimary"
              @input="emitUpdate('data.billingContact.email', $event)"
            />
            <v-text-field
              prepend-icon="phone"
              label="Billing contact name phone"
              :value="billingContact.phone"
              hint="(555) 555 - 5555"
              :disabled="samePrimary"
              @input="emitUpdate('data.billingContact.phone', $event)"
            />
          </div>
        </v-card>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-container>
</template>

<script>
import validator from 'email-validator';
export default {
  name: 'InputContact',
  props: {
    contact: {
      type: Object,
      default() {
        return {};
      }
    },
    billingContact: {
      type: Object,
      default() {
        return {};
      }
    },
    samePrimary: {
      type: Boolean,
      default() {
        return true;
      }
    },
    agents: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  watch: {
    prepopulatedBillingContact() {
      if (this.prepopulatedBillingContact === 'custom') {
        this.emitSetBillingValues(false);
        this.prepopulate({
          name: '',
          email: ''
        });
      } else if (this.prepopulatedBillingContact === 'primary') {
        this.emitSetBillingValues(true);
        this.prepopulate({
          name: '',
          email: ''
        });
      } else {
        this.emitSetBillingValues(false);
        const agent = this.agents.find(
          a => a.key === this.prepopulatedBillingContact
        );
        this.prepopulate({
          name: agent.billingName,
          email: agent.billingEmail
        });
      }
    }
  },
  computed: {
    billingContactOptions() {
      // Create list of options for "pre-populate" dropdown menu
      const defaultOptions = [
        { text: `Use account's primary contact`, value: 'primary' },
        { text: 'Enter new billing contact', value: 'custom' }
      ];
      const agentOptions = this.agents.map(agt => {
        return {
          text: `Use ${agt.name}'s billing contact`,
          value: agt.key
        };
      });

      return [...agentOptions, ...defaultOptions];
    }
  },
  data() {
    return {
      panel: [true, true],
      rules: {
        name: [v => !!v || 'name is required'],
        preferredName: [v => !!v || 'preferred name is required'],
        phone: [v => !!v || 'phone is required'],
        email: [
          v => !!v || 'E-mail is required',
          v => validator.validate(v) || 'E-mail must be valid'
        ]
      },
      prepopulatedBillingContact: null
    };
  },
  methods: {
    prepopulate(contactObj) {
      this.$emit('update', {
        key: 'data.billingContact.name',
        value: contactObj.name || null
      });
      this.$emit('update', {
        key: 'data.billingContact.preferredName',
        value: contactObj.name || null
      });
      this.$emit('update', {
        key: 'data.billingContact.email',
        value: contactObj.email || null
      });
    },
    emitUpdate(key, value) {
      this.$emit('update', { key, value });
    },
    emitSetBillingValues(value) {
      this.$emit('set-billing-values', value);
    }
  }
};
</script>

<style scoped></style>
