<template>
  <div>
    <div class="loading" v-if="loading">
      <v-progress-circular indeterminate color="primary" />
    </div>
    <div v-else>
      <!-- Renders Advance Vote table -->
      <router-view
        name="advance"
        :current-meeting="currentMeeting"
        :business-data="meetingBusinessData"
        :business-list="meetingBusinessList"
      />
      <!-- Renders Live Vote table -->
      <router-view
        name="live"
        :current-meeting="currentMeeting"
        :rtv-questions-object="meetingRTVQuestionsObject"
        :rtv-questions-array="meetingRTVQuestionsArray"
      />
      <!-- Renders Edit Question page (Advance & Live) -->
      <router-view
        :current-meeting="currentMeeting"
        :business-data="meetingBusinessData"
        :business-list="meetingBusinessList"
        :rtv-questions-object="meetingRTVQuestionsObject"
        :rtv-questions-array="meetingRTVQuestionsArray"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import checkScope from '@/lib/check-user-scopes';

export default {
  name: 'MeetingBusinessMain',
  props: {
    currentMeeting: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      loading: true,
      shortCode: this.$route.params.shortcode
    };
  },
  computed: {
    ...mapGetters('meetings/business', [
      'meetingBusinessData',
      'meetingBusinessList',
      'meetingBusinessById'
    ]),
    ...mapGetters('meetings/rtvQuestions', [
      'meetingRTVQuestionsObject',
      'meetingRTVQuestionsArray'
    ]),
    ...mapGetters(['isAdmin', 'scopes']),
    canSeeRtvQuestions() {
      return (
        this.isAdmin ||
        checkScope(this.scopes, this.shortCode, 'meeting.live-votes.read')
      );
    }
  },
  created() {
    this.init();
  },
  methods: {
    ...mapActions('meetings/business', ['getMeetingBusiness']),
    ...mapActions('meetings/rtvQuestions', ['getMeetingRTVQuestions']),
    async init() {
      try {
        await this.getMeetingBusiness({ shortCode: this.shortCode });
        if (this.canSeeRtvQuestions) {
          await this.getMeetingRTVQuestions({ shortCode: this.shortCode });
        }

        this.loading = false;
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      }
    }
  }
};
</script>

<style scoped></style>
