'use strict';

/**
 * @fileoverview Vuex module for meeting reports
 */

import _ from 'lodash';
import Vue from 'vue';
import * as mtgReportApi from '@/api/meetings-reports';
import {
  postExportProxies,
  postExportConsents,
  postExportPrintFiles,
  postSendVoteSummaryReport,
  postExportLiveBallots,
  postSendVotingResultsReport,
  postExportVoterRegistrationPage
} from '@/api/meetings-exports';

const state = {
  meetingReportList: {},
  dataLastFetchedAt: null,
  exportDialog: {
    show: false,
    processing: false,
    error: null
  },
  reportSubTypes: ['user-guide-associations']
};

const getters = {
  meetingReportList: state => state.meetingReportList,
  meetingReportListData: state => Object.values(state.meetingReportList),
  showExportDialog: state => state.exportDialog.show,
  isExportProcessing: state => state.exportDialog.processing,
  exportDialogError: state => state.exportDialog.error
};

const actions = {
  /**
   * Get the list of meeting reports available for this user
   * @param  {String} {shortCode}  meeting code
   */
  async getMeetingReportList({ commit }, { shortCode }) {
    try {
      const res = await mtgReportApi.getReports(shortCode);
      return commit('SET_MEETING_REPORT_LIST', res.data);
    } catch (err) {
      console.error('Error: getMeetingReportList action', err);
      throw err;
    }
  },

  /**
   * Get the URL to download the report CSV
   * @param  {String} {shortCode}  meeting code
   * @param  {String} {reportType} the name of the report
   * @param  {String} {region}     region
   * @param  {Object} {filters}    filters
   */
  async getReportUrl(
    { commit },
    { shortCode, reportType, region, folder, filters }
  ) {
    try {
      const res = await mtgReportApi.getReport(
        reportType,
        shortCode,
        region,
        folder,
        filters
      );

      // skip report subtypes that are not part of the main list
      if (!state.reportSubTypes.includes(reportType)) {
        commit('SET_MEETING_REPORT_URL', { reportType, url: res.data.url });
      }

      return res.data;
    } catch (err) {
      console.error('Error: getReportUrl action', err);
      throw err;
    }
  },

  /**
   * Download the report in a new window
   *
   * @param  {String}   {shortCode}  meeting code
   * @param  {String}   {reportType} the name of the report
   * @param  {Function} {$events}    event bus
   * @param  {String}   {region}     region
   * @param  {Object}   {filters}    filters
   */
  async downloadReport(
    { dispatch },
    { shortCode, reportType, $events, region = 'default', filters = {} }
  ) {
    try {
      $events.$emit('toastEvent', 'Downloading Report...');

      const data = await dispatch('getReportUrl', {
        shortCode,
        reportType,
        region,
        filters
      });

      if (['user-guide', 'user-guide-associations'].includes(reportType)) {
        // Open the pdf in a new tab
        window.open(data.url);
      } else {
        window.location.href = data.url;
      }

      $events.$emit(
        'toastEvent',
        'Download Completed, check your browser dialog'
      );

      return data.url;
    } catch (err) {
      console.error('Error: downloadReport action', err);

      if (err.response) {
        $events.$emit('showErrorDialog', err.response);
      }
      throw err;
    }
  },

  /**
   * Export a report and display the export dialog
   * @param  {string} {shortCode}  meeting code
   * @param  {string} {type} the name of the report
   * @param  {string} {mode} optional download mode
   * @param  {Object} {recipient} optional recipient name and email, with optional cc
   * @param  {boolean} {includeVoters} optional flag to include voter information of secret ballots in report
   * @param  {boolean} {includeVote} optional flag to include what vote was for of secret ballots in report
   * @param  {boolean} {exportAsBallot} optional flag to export the report as ballot
   * @param  {boolean} {includeVoteInDownload} optional flag to include vote in breakdown files
   * @param  {string}  {region} meeting region
   * @param  {number}  {version} report version to run
   * @param  {boolean} {isClient} use client report format
   * @param  {Object}  {filters} filters
   */
  async exportReport(
    { commit, dispatch },
    {
      shortCode,
      type,
      mode,
      recipient = null,
      includeVoters,
      includeVote,
      exportAsBallot,
      includeVoteInDownload,
      region = 'default',
      filters = {},
      version
    }
  ) {
    try {
      commit('SET_EXPORT_DIALOG_ERROR', null);

      let task;
      switch (type) {
        case 'proxies':
          task = postExportProxies(shortCode, mode);
          break;
        case 'consents':
          task = postExportConsents({ shortCode, mode, region });
          break;
        case 'consent-export-v2':
          task = postExportConsents({
            shortCode,
            mode,
            region,
            version,
            filters
          });
          break;
        case 'consent-export-v2-client':
          task = postExportConsents({
            shortCode,
            mode,
            region,
            version,
            isClient: true,
            filters
          });
          break;
        case 'print-files':
          task = postExportPrintFiles(shortCode, filters);
          break;
        case 'print-files-v2':
          task = postExportPrintFiles(shortCode, filters, version);
          break;
        case 'vote-summary':
          task = postSendVoteSummaryReport(
            shortCode,
            mode,
            recipient,
            includeVoters,
            includeVote,
            exportAsBallot,
            includeVoteInDownload
          );
          break;
        case 'live-ballots':
          task = postExportLiveBallots(shortCode);
          break;
        case 'voting-results':
          task = postSendVotingResultsReport(shortCode, recipient);
          break;
        case 'voter-registration':
          task = postExportVoterRegistrationPage(shortCode, mode);
          break;
        default:
          throw new Error(`Invalid report type ${type}`);
      }

      dispatch('openExportDialog');
      commit('SET_EXPORT_DIALOG_PROCESSING', true);

      return await task;
    } catch (err) {
      console.error('Error: exportReport action', err);
      commit('SET_EXPORT_DIALOG_ERROR', err.response);
      throw err;
    } finally {
      commit('SET_EXPORT_DIALOG_PROCESSING', false);
    }
  },

  openExportDialog({ commit }) {
    commit('SET_EXPORT_DIALOG_VISIBILITY', true);
  },
  closeExportDialog({ commit }) {
    commit('SET_EXPORT_DIALOG_VISIBILITY', false);
  }
};

const mutations = {
  SET_MEETING_REPORT_LIST(state, dataArray) {
    state.meetingReportList = _.keyBy(dataArray, 'reportType');
    state.dataLastFetchedAt = new Date();
  },
  SET_MEETING_REPORT_URL(state, { reportType, url }) {
    if (state?.meetingReportList[reportType]) {
      Vue.set(state.meetingReportList[reportType], 'url', url);
    }
  },
  SET_EXPORT_DIALOG_VISIBILITY(state, isVisible) {
    state.exportDialog.show = isVisible || false;
  },
  SET_EXPORT_DIALOG_PROCESSING(state, isProcessing) {
    state.exportDialog.processing = isProcessing;
  },
  SET_EXPORT_DIALOG_ERROR(state, error) {
    Vue.set(state.exportDialog, 'error', error);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
