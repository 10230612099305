import store from '@/vuex/store';

/**
 * Function to control access to the admin views
 *
 * @param {string} to - Route: the target Route Object being navigated to.
 * @param {string} from - Route: the current route being navigated away from.
 * @param {string} next - Function: this function must be called to resolve the hook.
 */
async function requiredAdminLevel(to, from, next) {
  const { fullPath = '', meta = {} } = to;
  const { adminOnly = false } = meta; // check if view is only for admins

  const user = await store.dispatch('getUserProfile');

  if (user) {
    const { isAdmin = false } = user; // check if user is admin

    // if view is only for admins and user is not admin redirect to overview
    if ((adminOnly && !isAdmin) || (fullPath.includes('/admin') && !isAdmin)) {
      const { shortcode } = to.params;

      // if shortcode exists, back to the meeting overview page
      if (shortcode) {
        return next({
          name: 'meetingOverview',
          params: { shortcode }
        });
      }

      // otherwise back to the meetings view
      return next({
        name: 'meetings'
      });
    }

    return next();
  } else {
    // refresh token
    await store.dispatch('doRefreshToken');
    // call the function again to check user scope
    await requiredAdminLevel(to, from, next);
  }
}

export { requiredAdminLevel };
