'use strict';

/**
 * @fileoverview Routes for all the /admin/ sub views
 */

// Admin Home
import AdminHomeView from '@/views/admin/home/AdminHomeView';

// Admin Accounts
import AccountsMain from '@/views/admin/accounts/AdminAccountsMain';
import AdminAccountsListView from '@/views/admin/accounts/AdminAccountsListView';
import AccountMain from '@/views/admin/accounts/account/AdminAccountMain';
import AdminAccountView from '@/views/admin/accounts/account/overview/AdminAccountView';
import AdminAccountConsentsView from '@/views/admin/accounts/account/consents/AdminAccountConsentsView';
import AdminAccountSubscriptionView from '@/views/admin/accounts/account/subscription/AdminAccountSubscriptionView';
import AdminAccountAgreementsView from '@/views/admin/accounts/account/agreements/AdminAccountAgreementsView';
import AdminAccountChecklistsView from '@/views/admin/accounts/account/checklists/AdminAccountChecklistsView';

// Admin Agents
import AgentsMain from '@/views/admin/agents/AdminAgentsMain';
import AdminAgentsListView from '@/views/admin/agents/AdminAgentsListView';
import AgentMain from '@/views/admin/agents/agent/AdminAgentMain';
import AdminAgentView from '@/views/admin/agents/agent/overview/AdminAgentView';

// Admin Billing
import AdminInvoicesMain from '@/views/admin/invoices/AdminInvoicesMain';
import AdminInvoicesListView from '@/views/admin/invoices/AdminInvoicesListView';
import AdminInvoiceView from '@/views/admin/invoices/AdminInvoiceView';

// Admin Campaigns
import AdminCampaignsView from '@/views/admin/campaigns/AdminCampaignsView';

// Checklist Templates
import AdminChecklistTemplatesMain from '@/views/admin/checklist-templates/AdminChecklistTemplatesMain';
import AdminChecklistTemplatesListView from '@/views/admin/checklist-templates/AdminChecklistTemplatesListView';
import AdminChecklistTemplateView from '@/views/admin/checklist-templates/AdminChecklistTemplateView';

// Checklist Template Groups
import AdminChecklistTemplateGroupsMain from '@/views/admin/checklist-template-groups/AdminChecklistTemplateGroupsMain';
import AdminChecklistTemplateGroupsListView from '@/views/admin/checklist-template-groups/AdminChecklistTemplateGroupsListView';
import AdminChecklistTemplateGroupsView from '@/views/admin/checklist-template-groups/AdminChecklistTemplateGroupsView';

// Notices list
import AdminNoticeScheduleView from '@/views/admin/notice-schedule/AdminNoticeScheduleView';

// Notice Templates
import AdminNoticeTemplatesMain from '@/views/admin/notice-templates/AdminNoticeTemplatesMain';
import AdminNoticeTemplatesListView from '@/views/admin/notice-templates/AdminNoticeTemplatesListView';
import AdminNoticeTemplateView from '@/views/admin/notice-templates/AdminNoticeTemplateView';

// Conversation Groups
import AdminConversationTemplateGroupsMain from '@/views/admin/conversation-template-groups/AdminConversationTemplateGroupsMain';
import AdminConversationTemplateGroupsListView from '@/views/admin/conversation-template-groups/AdminConversationTemplateGroupsListView';
import AdminConversationTemplateGroupsView from '@/views/admin/conversation-template-groups/AdminConversationTemplateGroupsView';

// Users
import AdminUsersMain from '@/views/admin/users/AdminUsersMain';
import AdminUsersListView from '@/views/admin/users/AdminUsersListView';
import AdminUserView from '@/views/admin/users/AdminUserView';

// Print Jobs
import AdminPrintJobsListView from '@/views/admin/print-jobs/AdminPrintJobsListView';

// Products
import AdminProductsMainView from '@/views/admin/products/AdminProductsMain';
import AdminProductsListView from '@/views/admin/products/AdminProductsListView';
import AdminProductsEditView from '@/views/admin/products/AdminProductsEditView';

// Zoom
import AdminZoomView from '@/views/admin/zoom/AdminZoomView';

export default [
  {
    name: 'adminHome',
    path: '',
    label: 'Admin Dashboard',
    icon: 'home',
    meta: {
      headerLabel: 'Admin Dashboard',
      title: 'Dashboard'
    },
    component: AdminHomeView
  },
  {
    path: 'agents',
    label: 'Agents',
    icon: 'corporate_fare',
    component: AgentsMain,
    children: [
      {
        name: 'adminAgent',
        path: '',
        component: AdminAgentsListView,
        meta: {
          headerLabel: 'Agents',
          title: 'Agents'
        }
      },
      {
        path: ':agentKey',
        component: AgentMain,
        children: [
          {
            name: 'editAgent',
            path: '',
            component: AdminAgentView,
            meta: {
              headerLabel: 'Agents',
              headerRouteName: 'adminAgent',
              nestedLabel: 'adminAgent',
              tabIndex: 0,
              title: 'Edit Agent'
            }
          }
        ]
      }
    ]
  },
  {
    path: 'accounts',
    label: 'Accounts',
    icon: 'account_box',
    component: AccountsMain,
    children: [
      {
        name: 'adminAccount',
        path: '',
        component: AdminAccountsListView,
        meta: {
          headerLabel: 'Accounts',
          title: 'Accounts'
        }
      },
      {
        path: ':accountKey',
        component: AccountMain,
        children: [
          {
            name: 'editAccount',
            path: '',
            component: AdminAccountView,
            meta: {
              headerLabel: 'Accounts',
              headerRouteName: 'adminAccount',
              nestedLabel: 'adminAccount',
              tabIndex: 0,
              title: 'Edit Account'
            },
            scrollBehavior() {
              return { x: 0, y: 0 };
            }
          },
          {
            name: 'accountConsents',
            path: 'consents',
            component: AdminAccountConsentsView,
            meta: {
              headerLabel: 'Accounts',
              headerRouteName: 'adminAccount',
              nestedLabel: 'Go Back',
              tabIndex: 1,
              title: 'Account Consents'
            },
            scrollBehavior() {
              return { x: 0, y: 0 };
            }
          },
          {
            name: 'accountAgreements',
            path: 'agreements',
            component: AdminAccountAgreementsView,
            meta: {
              headerLabel: 'Accounts',
              headerRouteName: 'adminAccount',
              nestedLabel: 'Go Back',
              tabIndex: 2,
              title: 'Account Agreements'
            },
            scrollBehavior() {
              return { x: 0, y: 0 };
            }
          },
          {
            name: 'accountChecklists',
            path: 'checklists',
            component: AdminAccountChecklistsView,
            meta: {
              headerLabel: 'Accounts',
              headerRouteName: 'adminAccount',
              nestedLabel: 'Go Back',
              tabIndex: 3,
              title: 'Account Checklists'
            },
            scrollBehavior() {
              return { x: 0, y: 0 };
            }
          },
          {
            name: 'accountSubscription',
            path: 'subscription',
            component: AdminAccountSubscriptionView,
            meta: {
              headerLabel: 'Accounts',
              headerRouteName: 'adminAccount',
              nestedLabel: 'Go Back',
              tabIndex: 4,
              title: 'Account Subscription'
            },
            scrollBehavior() {
              return { x: 0, y: 0 };
            }
          }
        ]
      }
    ]
  },
  {
    name: 'adminCampaigns',
    path: 'campaigns',
    component: AdminCampaignsView,
    meta: {
      headerLabel: 'Campaigns',
      title: 'Campaigns'
    },
    label: 'Campaigns',
    icon: 'event'
  },
  // Invoices
  {
    path: 'invoices',
    label: 'Invoices',
    icon: 'credit_card',
    component: AdminInvoicesMain,
    children: [
      {
        name: 'adminInvoices',
        meta: { headerLabel: 'Invoices', title: 'Invoices' },
        path: '',
        component: AdminInvoicesListView
      },
      {
        name: 'adminInvoice',
        path: ':invoiceKey',
        component: AdminInvoiceView,
        meta: {
          headerLabel: 'Invoices',
          headerRouteName: 'adminInvoices',
          nestedLabel: 'Go back',
          title: 'Edit Invoice'
        }
      }
    ]
  },

  // Checklist Templates
  {
    path: 'checklist-templates',
    label: 'Checklist Templates',
    icon: 'playlist_add_check',
    component: AdminChecklistTemplatesMain,
    children: [
      {
        name: 'adminChecklistTemplatesOverview',
        path: '',
        component: AdminChecklistTemplatesListView,
        meta: {
          headerLabel: 'Checklist Templates',
          title: 'Checklist Templates'
        }
      },
      {
        name: 'editChecklistTemplate',
        path: 'checklists/:checklistTemplateId',
        component: AdminChecklistTemplateView,
        meta: {
          headerLabel: 'Checklist Templates',
          headerRouteName: 'adminChecklistTemplatesOverview',
          nestedLabel: 'Go back',
          title: 'Edit Checklist Template'
        }
      }
    ]
  },

  // Checklist Template Groups
  {
    path: 'checklist-template-groups',
    label: 'Checklist Template Groups',
    icon: 'beenhere',
    component: AdminChecklistTemplateGroupsMain,
    children: [
      {
        name: 'adminChecklistTemplateGroups',
        path: '',
        meta: {
          headerLabel: 'Checklist Template Groups',
          title: 'Checklist Groups'
        },
        component: AdminChecklistTemplateGroupsListView
      },
      {
        name: 'adminChecklistTemplateGroup',
        path: ':checklistTemplateGroupKey',
        meta: {
          headerLabel: 'Checklist Template Groups',
          nestedLabel: 'Go back',
          headerRouteName: 'adminChecklistTemplateGroups',
          title: 'Edit Checklist Group'
        },
        component: AdminChecklistTemplateGroupsView
      }
    ]
  },

  // Notice Schedule
  {
    name: 'adminNoticeSchedule',
    path: 'notice-schedule',
    component: AdminNoticeScheduleView,
    meta: { headerLabel: 'Notice Schedule', title: 'Notices' },
    label: 'Notice Schedule',
    icon: 'date_range'
  },

  // Notice Templates
  {
    path: 'notice-templates',
    label: 'Templates',
    icon: 'library_books',
    component: AdminNoticeTemplatesMain,
    children: [
      {
        name: 'adminNoticeTemplates',
        path: '',
        meta: {
          headerLabel: 'Notice and Conversation Templates',
          title: 'Notice Templates'
        },
        component: AdminNoticeTemplatesListView
      },
      {
        name: 'adminNoticeTemplate',
        path: ':noticeTemplateKey',
        meta: {
          headerLabel: 'Notice and Conversation Templates',
          nestedLabel: 'Go back',
          headerRouteName: 'adminNoticeTemplates',
          title: 'Edit Notice Template'
        },
        component: AdminNoticeTemplateView
      }
    ]
  },
  // Products
  {
    path: 'products',
    label: 'Products',
    icon: 'local_offer',
    component: AdminProductsMainView,
    children: [
      {
        name: 'adminProducts',
        path: '',
        component: AdminProductsListView,
        meta: {
          headerLabel: 'Products',
          title: 'Products'
        }
      },
      {
        name: 'adminProductsEdit',
        path: ':productKey',
        component: AdminProductsEditView,
        meta: {
          headerRouteName: 'adminProducts',
          nestedLabel: 'adminProducts',
          headerLabel: 'Edit Product',
          title: 'Edit Product'
        }
      }
    ]
  },
  // Template Groups
  {
    path: 'template-groups',
    label: 'Convo Template Groups',
    icon: 'layers',
    component: AdminConversationTemplateGroupsMain,
    children: [
      {
        name: 'adminConversationTemplateGroups',
        path: '',
        meta: {
          headerLabel: 'Conversation Template Groups',
          title: 'Conversation Groups'
        },
        component: AdminConversationTemplateGroupsListView
      },
      {
        name: 'adminConversationTemplateGroup',
        path: ':conversationTemplateGroupKey',
        meta: {
          headerLabel: 'Conversation Template Groups',
          nestedLabel: 'Go back',
          headerRouteName: 'adminConversationTemplateGroups',
          title: 'Edit Conversation Group'
        },
        component: AdminConversationTemplateGroupsView
      }
    ]
  },

  // Users
  {
    path: 'users',
    label: 'Users',
    icon: 'group',
    component: AdminUsersMain,
    children: [
      {
        name: 'adminUsers',
        path: '',
        component: AdminUsersListView,
        meta: { headerLabel: 'Users', title: 'Users' }
      },
      {
        name: 'adminUserEdit',
        path: ':userKey',
        component: AdminUserView,
        meta: {
          headerLabel: 'Users',
          nestedLabel: 'Go back',
          headerRouteName: 'adminUsers',
          title: 'Edit User'
        }
      }
    ]
  },

  // Print Jobs
  {
    name: 'adminPrintJobs',
    path: 'print-jobs',
    label: 'Print Jobs',
    icon: 'print',
    component: AdminPrintJobsListView,
    meta: { headerLabel: 'Print Jobs', title: 'Print Jobs' }
  },

  // Zoom diagnostic view
  {
    name: 'adminZoom',
    path: 'zoom',
    label: 'Zoom',
    icon: 'videocam',
    component: AdminZoomView,
    meta: {
      headerLabel: 'Zoom Status',
      title: 'Zoom Status',
      scope: 'admin.zoom.read'
    }
  }
];
