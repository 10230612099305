<template>
  <div>
    <v-layout row>
      <v-flex xs12 md6>
        <v-select
          :items="isMultiConsent ? segmentTypesMulti : segmentTypes"
          :value="segment"
          :disabled="isDisabled"
          label="Recipient segment to target"
          item-text="label"
          item-value="value"
          @change="onChange('recipients.segment', $event)"
        />
      </v-flex>
    </v-layout>

    <v-layout v-if="segment === 'custom-email-range'" class="mt-2">
      <v-flex xs6 md3 class="pl-r">
        <v-text-field
          :value="range.start"
          label="Starting Range"
          placeholder="eg. 'albert'"
          hint="Includes value in range"
          :rules="[rules.required]"
          :readonly="isDisabled"
          persistent-hint
          @input="onCustomEmailRangeStartChange"
        />
      </v-flex>

      <v-flex xs6 md3 class="pl-1">
        <v-text-field
          :value="range.end"
          label="Ending Range"
          placeholder="eg. 'albert'"
          hint="Excludes value in range"
          :rules="[rules.required]"
          :readonly="isDisabled"
          persistent-hint
          @input="onCustomEmailRangeEndChange"
        />
      </v-flex>
    </v-layout>

    <v-layout v-if="segment === 'custom-data'" class="mt-2">
      <v-flex xs6 md3 class="pl-r">
        <v-select
          :value="customData.key"
          label="Key"
          :items="customDataKeys"
          placeholder="eg. 'owner_occupied'"
          no-data-text="No fields available"
          @input="onCustomDataKeyChange"
        />
      </v-flex>

      <v-flex xs6 md3 class="pl-1">
        <v-text-field
          :value="customData.value"
          label="Value"
          placeholder="eg. 'true'"
          :rules="[rules.required]"
          :readonly="isDisabled"
          @input="onCustomDataValueChange"
        />
      </v-flex>
    </v-layout>

    <v-layout row>
      <v-flex xs12 md8>
        <v-checkbox
          hint="Use this for important emails such as Notices of Meeting, link to Voting Portal, Budgets, etc."
          persistent-hint
          v-model="state.urgent"
          :disabled="isDisabled"
          @change="onChange('options.urgent', $event)"
        >
          <span slot="label">
            Set as Urgent: include recipients have been filtered out because
            they unsubscribed.
          </span>
        </v-checkbox>

        <v-alert
          class="mt-3"
          small
          outline
          color="blue"
          icon="info"
          :value="true"
          v-show="state.urgent"
        >
          Urgent emails are meant for important emails that must be delivered
          regardless of a user's communication preferences (eg. Notice of
          Service, Budget, or Voting emails). You should not set this for
          periodic voting or consent reminders.
        </v-alert>
      </v-flex>
    </v-layout>

    <v-layout v-if="segment === 'custom-emails'">
      <v-flex xs12>
        <span class="code-mirror-label"
          >Email Addresses (one address per line)</span
        >
        <div class="codemirror-container mb-3">
          <code-mirror
            :is-disabled="isDisabled"
            :is-delimited="true"
            :code-array="emails"
            @update="onCustomEmailChange"
          />
        </div>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import CodeMirror from '@/components/CodeMirror';
import segmentTypes from '@/lib/segment-types';
import segmentTypesMulti from '@/lib/segment-types-multi';

export default {
  name: 'InputSegment',
  components: {
    CodeMirror
  },
  props: {
    segment: { type: String, default: '' },
    isDisabled: { type: Boolean, default: false },
    isChanged: { type: Boolean, default: false },
    isUrgent: {
      type: Boolean,
      default() {
        return false;
      }
    },
    emails: {
      type: Array,
      default() {
        return [];
      }
    },
    range: {
      type: Object,
      default() {
        return {
          start: null,
          end: null
        };
      }
    },
    customData: {
      type: Object,
      default() {
        return {
          start: null,
          end: null
        };
      }
    },
    customDataKeys: {
      type: Array,
      default() {
        return [];
      }
    },
    isMultiConsent: {
      type: Boolean,
      default() {
        return false;
      }
    }
  },
  created() {
    this.state.urgent = this.isUrgent;
  },
  data() {
    return {
      state: {
        urgent: false
      },
      rules: {
        required: value =>
          value === undefined || !!value || 'This field is required'
      },
      segmentTypes: segmentTypes,
      segmentTypesMulti: segmentTypesMulti
    };
  },
  watch: {
    isUrgent(newValue) {
      this.state.urgent = newValue;
    }
  },
  methods: {
    onChange(key, value) {
      this.$emit('update', { key, value });
    },
    onCustomEmailChange(emails) {
      // Filter out falsey values
      let cleanedEmails = emails.filter(Boolean);
      this.onChange('recipients.emails', cleanedEmails);
    },
    onCustomEmailRangeStartChange(start) {
      this.onChange('recipients.range.start', start);
    },
    onCustomEmailRangeEndChange(end) {
      this.onChange('recipients.range.end', end);
    },
    onCustomDataKeyChange(key) {
      this.onChange('recipients.customData.key', key);
    },
    onCustomDataValueChange(value) {
      this.onChange('recipients.customData.value', value);
    }
  }
};
</script>

<style scoped>
.codemirror-container {
  height: 120px;
}
</style>
