<template>
  <v-container>
    <div class="title grey--text text--darken-4 mb-1">Virtual Meeting</div>
    <div class="body-1 grey--text text--darken-2 mb-4">
      Configuration settings
    </div>
    <v-layout>
      <v-flex xs12>
        <v-radio-group
          v-model="meetingType"
          @change="updateMeetingType('options.subtype', $event)"
        >
          <v-radio label="In-Person Meeting" value="In-Person"></v-radio>
          <v-radio label="Virtual Meeting" value="Virtual"></v-radio>
          <v-radio label="Hybrid Meeting" value="Hybrid"></v-radio>
        </v-radio-group>
      </v-flex>
    </v-layout>
    <v-divider class="gq-divider" />
    <div v-if="['Hybrid', 'Virtual'].includes(meetingType)">
      <v-layout>
        <v-flex xs12>
          <div class="title grey--text text--darken-4 mb-1">
            Webcast Settings Page
          </div>
          <div class="body-1 grey--text text--darken-2 mb-4">
            Configure the settings available on the Webcast Settings Page
          </div>
          <v-radio-group
            name="meeting-webcast-type"
            v-model="virtualMeetingType"
            @change="updateVirtualMeetingType($event)"
          >
            <v-radio value="GQ-Hosted">
              <template v-slot:label>
                <div>
                  <h5 class="subheading">
                    Use GetQuorum Zoom License (Details in Webcast Tab)
                  </h5>
                </div>
              </template>
            </v-radio>
            <v-radio value="Self-Hosted">
              <template v-slot:label>
                <div>
                  <h5 class="subheading">
                    Use Client's own Virtual Meeting Instructions
                  </h5>
                </div>
              </template>
            </v-radio>
            <v-radio value="Custom-Hosted">
              <template v-slot:label>
                <div>
                  <h5 class="subheading">Use Custom Zoom Meeting URL</h5>
                  <div class="markdown-label">
                    RTV Zoom would be enabled.
                  </div>
                </div>
              </template>
            </v-radio>
            <v-layout v-if="virtualMeetingType === 'Custom-Hosted'">
              <div>
                <v-checkbox
                  class="ml-4 mt-0"
                  label="Use @getquorum.com Zoom day-to-day account"
                  v-model="zoomDayToDay"
                  @change="onChange('options.zoomDayToDay', $event)"
                />
              </div>
            </v-layout>
          </v-radio-group>
        </v-flex>
      </v-layout>

      <v-divider class="gq-divider" />
      <v-layout v-if="showEnableRTVZoom">
        <v-flex xs12>
          <v-layout>
            <div>
              <v-checkbox
                :true-value="true"
                :false-value="false"
                v-model="enableRtvZoom"
                @change="updateRtvAccess($event)"
              />
            </div>
            <div>
              <h5 class="subheading">Enable RTV Zoom integration</h5>
              <p class="grey--text text--darken-2">
                This option will enable access to the RTV Zoom integrated
                version from the base RTV application.
              </p>

              <v-radio-group
                v-if="enableRtvZoom"
                name="meeting-rtv-type"
                v-model="rtvAccessType"
                @change="onChange('options.rtvAccessType', $event)"
              >
                <v-radio value="gq-web">
                  <template v-slot:label>
                    <div>
                      <h5 class="subheading">
                        Access via GQ Web Portal. Users will be redirected to
                        the integrated portal automatically.
                      </h5>
                    </div>
                  </template>
                </v-radio>
                <v-layout>
                  <div>
                    <v-checkbox
                      style="padding-left:25px;"
                      :true-value="true"
                      :false-value="false"
                      v-model="options.enableMobileApp"
                      @change="onChange('options.enableMobileApp', $event)"
                    />
                  </div>
                  <div>
                    <div class="body-2">
                      (Experimental) Enable Mobile App Download
                    </div>
                    <div class="grey--text text--darken-2 body-2">
                      If enabled, mobile users will have to download and install
                      the GetQuorum Mobile App. During voting they will receive
                      push notifications.
                    </div>
                  </div>
                </v-layout>
              </v-radio-group>

              <v-layout v-if="virtualMeetingType === 'GQ-Hosted'">
                <v-flex xs8>
                  <v-text-field
                    label="Enter Youtube livestream URL here:"
                    :value="options.livestreamUrl"
                    hint="E.g. https://www.youtube.com/embed/xxxxxx"
                    persistent-hint
                    @input="onChange('options.livestreamUrl', $event)"
                  />
                </v-flex>
              </v-layout>
            </div>
          </v-layout>
        </v-flex>
      </v-layout>
      <v-layout v-if="virtualMeetingType === 'Custom-Hosted'">
        <v-flex xs8>
          <v-text-field
            label="Enter URL here:"
            :value="options.customZoomUrl"
            hint="E.g.  https://zoom.us/j/9054984734?pwd=aTcxZ3VqRDhCWjVQTmhjeU8yN2UrZz09"
            persistent-hint
            @input="onChange('options.customZoomUrl', $event)"
          />
        </v-flex>
      </v-layout>
    </div>
  </v-container>
</template>

<script>
import _ from 'lodash';
import RichTextEditor from '@/components/RichTextEditor/RichTextEditor';

export default {
  name: 'MeetingSettingsVirtualMeetingInput',
  components: {
    RichTextEditor
  },
  props: {
    options: {
      type: Object,
      required: true
    }
  },
  mounted() {
    this.initState();
  },
  watch: {},
  data() {
    return {
      virtualMeetingType: null,
      meetingType: '',
      isGQHosted: false,
      zoomDayToDay: true,
      rtvAccessType: null,
      enableRtvZoom: false
    };
  },
  computed: {
    showEnableRTVZoom() {
      return this.virtualMeetingType === 'GQ-Hosted';
    }
  },
  methods: {
    initState() {
      const meetingType = _.get(this.options, `subtype`, '');
      const virtualMode = _.get(this.options, `virtualMode`, null);
      if (virtualMode === 'Hybrid') {
        this.meetingType = virtualMode;
      } else {
        this.meetingType = meetingType;
      }

      this.enableRtvZoom = _.get(this.options, `enableRtvZoom`, false);
      this.zoomDayToDay = _.get(this.options, `zoomDayToDay`, true);
      this.virtualMeetingType = _.get(this.options, `virtualMeetingType`, '');
      this.rtvAccessType = _.get(this.options, `rtvAccessType`, '');
    },
    updateVirtualMeetingType(value) {
      this.onChange('options.virtualMeetingType', value);
      if (value === 'Self-Hosted') {
        this.onChange('options.enableRtvZoom', false);
        this.onChange('options.enableMobileApp', false);
      } else if (value == 'Custom-Hosted') {
        this.onChange('options.enableRtvZoom', true);
        this.onChange('options.rtvAccessType', 'gq-web');
      }
    },
    updateRtvAccess(value) {
      if (value) {
        this.rtvAccessType = 'gq-web';
        this.onChange('options.rtvAccessType', this.rtvAccessType);
      } else {
        this.onChange('options.rtvAccessType', undefined);
        this.onChange('options.enableMobileApp', undefined);
      }
      this.onChange('options.enableRtvZoom', value);
    },
    updateMeetingType(key, meetingTypeValue) {
      this.onChange(key, meetingTypeValue);

      if (meetingTypeValue === 'In-Person') {
        this.virtualMeetingType = null;
        this.enableRtvZoom = false;
        this.onChange('options.enableRtvZoom', false);
        this.onChange('options.enableMobileApp', false);
      } else if (
        !this.virtualMeetingType ||
        this.virtualMeetingType.length === 0
      ) {
        // If there is no webcast type defined, by default would be GQ-Hosted.
        this.virtualMeetingType = 'GQ-Hosted';
        this.onChange('options.virtualMeetingType', this.virtualMeetingType);
      }

      if (['Virtual', 'Hybrid'].includes(meetingTypeValue)) {
        this.enableRtvZoom = true;
        this.onChange('options.enableRtvZoom', true);
      }
    },
    onChange(key, value) {
      if (key === 'options.subtype') {
        if (value === 'Hybrid') {
          this.$emit('update', { key, value: 'Virtual' });
          this.$emit('update', { key: 'options.virtualMode', value });
        } else {
          this.$emit('update', { key, value });
          this.$emit('update', { key: 'options.virtualMode', value: null });
        }
      } else {
        this.$emit('update', { key, value });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.v-label p {
  font-size: 14px;
}
.markdown-label {
  color: rgba(0, 0, 0, 0.54);
  font-size: 12px;
  visibility: visible;
  margin-bottom: 5px;
}
</style>
