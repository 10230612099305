<template>
  <v-dialog persistent max-width="700px" :value="value" @input="emitInput">
    <v-card>
      <v-card-title>
        <div class="headline">Edit Voter</div>
        <v-spacer />

        <div v-if="canSeeVoterLinks">
          <v-menu v-model="menu" :close-on-content-click="false" bottom left>
            <template v-slot:activator="{ on }">
              <v-btn light icon v-on="on">
                <v-icon>more_vert</v-icon>
              </v-btn>
            </template>

            <v-list>
              <!-- Proxy link (visible for non-admins) -->
              <v-list-tile>
                <v-list-tile-content>
                  <v-list-tile-title>Proxy URL</v-list-tile-title>
                  <v-list-tile-sub-title>
                    <input
                      ref="proxyurlRef"
                      class="url-input"
                      :value="proxyUrlPreview"
                      readonly
                    />
                  </v-list-tile-sub-title>
                </v-list-tile-content>
                <v-list-tile-action>
                  <v-btn
                    class="pa-0 ma-0"
                    icon
                    slot="activator"
                    @click="handleCopy('proxyurlRef')"
                  >
                    <v-icon color="blue lighten-2">file_copy</v-icon>
                  </v-btn>
                </v-list-tile-action>
              </v-list-tile>
              <!-- Consent link (hidden from non-admins) -->
              <v-list-tile
                v-if="
                  consentUrlPreview && hasRealEmail && displayConsentDetails
                "
              >
                <v-list-tile-content>
                  <v-list-tile-title>Consent URL</v-list-tile-title>
                  <v-list-tile-sub-title>
                    <input
                      ref="consenturlRef"
                      class="url-input"
                      :value="consentUrlPreview"
                      readonly
                    />
                  </v-list-tile-sub-title>
                </v-list-tile-content>
                <v-list-tile-action>
                  <v-btn
                    class="pa-0 ma-0"
                    icon
                    slot="activator"
                    @click="handleCopy('consenturlRef')"
                  >
                    <v-icon color="blue lighten-2">file_copy</v-icon>
                  </v-btn>
                </v-list-tile-action>
              </v-list-tile>
              <!-- Nomination link (hidden from non-admins) -->
              <v-list-tile v-if="nominationUrlPreview">
                <v-list-tile-content>
                  <v-list-tile-title>Nomination URL</v-list-tile-title>
                  <v-list-tile-sub-title>
                    <input
                      ref="nominationurlRef"
                      class="url-input"
                      :value="nominationUrlPreview"
                      readonly
                    />
                  </v-list-tile-sub-title>
                </v-list-tile-content>
                <v-list-tile-action>
                  <v-btn
                    class="pa-0 ma-0"
                    icon
                    slot="activator"
                    @click="handleCopy('nominationurlRef')"
                  >
                    <v-icon color="blue lighten-2">file_copy</v-icon>
                  </v-btn>
                </v-list-tile-action>
              </v-list-tile>
              <!-- Live Vote link (visible for non-admins) -->
              <v-list-tile>
                <v-list-tile-content>
                  <v-list-tile-title>Live Vote URL</v-list-tile-title>
                  <v-list-tile-sub-title>
                    <input
                      ref="liveVoteUrlRef"
                      class="url-input"
                      :value="liveVoteUrlPreview"
                      readonly
                    />
                  </v-list-tile-sub-title>
                </v-list-tile-content>
                <v-list-tile-action>
                  <v-btn
                    class="pa-0 ma-0"
                    icon
                    slot="activator"
                    @click="handleCopy('liveVoteUrlRef')"
                  >
                    <v-icon color="blue lighten-2">file_copy</v-icon>
                  </v-btn>
                </v-list-tile-action>
              </v-list-tile>
            </v-list>
          </v-menu>
        </div>
      </v-card-title>

      <!-- Name + Email (visible for non-admins) -->
      <v-card-text>
        <v-text-field
          label="Name"
          v-model="state.name"
          :rules="rules.name"
          placeholder="eg. Joe Quimby"
          required
        />
        <v-text-field
          label="Email"
          v-model="state.email"
          :rules="rules.email"
          placeholder="eg. JoeQuimby@email.com"
          required
        />

        <!-- Consent status (hidden from non-admins) -->
        <consent-status
          v-if="isAdmin && hasRealEmail && displayConsentDetails"
          :consent="state.consent"
          :meeting-timezone="meetingTimezone"
        />

        <!-- RSVP and In Attendance checkboxes (visible for non-admins) -->
        <v-checkbox
          hide-details
          light
          label="(RSVP) Voter is attending the event"
          v-model="state.attending"
        />
        <v-checkbox
          hide-details
          light
          label="(IN ATTENDANCE) Voter is in attendance at the event"
          v-model="state.inAttendance"
        />
        <!-- Unsubscribe and Set as test voter (hidden from non-admins) -->
        <v-checkbox
          v-if="isAdmin"
          hide-details
          light
          label="Unsubscribe from consent reminders"
          v-model="state.communicationPreferences.disableConsentReminders"
        />
        <v-checkbox
          v-if="isAdmin"
          hide-details
          light
          label="Unsubscribe from ALL emails (eg. already submitted paper proxy, complaints, etc.)"
          v-model="state.communicationPreferences.disableAllEmails"
        />
        <v-checkbox
          v-if="isAdmin && hasWebcast"
          hide-details
          light
          label="Set as panelist"
          v-model="state.isPanelist"
        />
        <v-checkbox
          v-if="isAdmin"
          hide-details
          light
          label="Set as test voter"
          v-model="state.communicationPreferences.isTestVoter"
        />
        <div
          class="grey--text text-padding"
          v-if="isAdmin && state.communicationPreferences.isTestVoter"
        >
          This voters merge data is now available for use in email previews
        </div>
        <v-checkbox
          v-if="isAdmin && isCustomZoomMeeting"
          hide-details
          light
          label="Set as Custom Zoom Meeting Host (ADMIN ONLY)"
          v-model="state.isMeetingHost"
        />
        <div
          class="grey--text text-padding"
          v-if="isAdmin && state.isMeetingHost"
        >
          This voter can start the meeting via voting portal.
        </div>
      </v-card-text>

      <v-card-actions>
        <!-- Delete button (hidden from moderators) -->
        <v-btn
          v-if="canDeleteVoters"
          color="error"
          flat
          @click.native="dialog.delete = true"
        >
          <v-icon left>delete_forever</v-icon>Delete Voter
        </v-btn>
        <v-spacer />
        <v-btn flat @click.stop="emitClose">Close</v-btn>
        <v-btn
          color="primary"
          :disabled="!isChanged && !isPanelistChanged"
          @click.native="updateVoter"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>

    <!-- Delete Dialog -->
    <delete-voter-confirmation-dialog
      v-model="dialog.delete"
      @close="dialog.delete = false"
      @delete="deleteVoter"
    />
  </v-dialog>
</template>

<script>
import _ from 'lodash';
import validator from 'email-validator';
import { mapGetters, mapActions } from 'vuex';
import ConsentStatus from '@/components/ConsentStatusAlert';
import checkScope from '@/lib/check-user-scopes';

import DeleteVoterConfirmationDialog from './MeetingUnitsDeleteVoterConfirmationDialog.vue';

export default {
  name: 'MeetingUnitsEditVoterDialog',
  components: {
    ConsentStatus,
    DeleteVoterConfirmationDialog
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    hasWebcast: {
      type: Boolean,
      default: false
    },
    voterSelected: {
      type: Object,
      default() {
        return {};
      }
    },
    options: {
      type: Object,
      default() {
        return {};
      }
    },
    meetingTimezone: {
      type: String,
      default: null
    },
    virtualMeetingType: {
      type: String,
      default: null
    },
    displayConsentDetails: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      menu: false,
      state: {
        name: '',
        email: '',
        attending: null,
        inAttendance: false,
        isMeetingHost: false,
        isPanelist: false,
        consent: {
          timestamp: null
        },
        communicationPreferences: {
          disableAllEmails: false,
          disableConsentReminders: false,
          isTestVoter: false
        }
      },
      dialog: {
        delete: false
      },
      tooltip: {
        proxyurl: false,
        consenturl: false,
        nominationurl: false
      },
      proxyUrlPreview: '',
      consentUrlPreview: '',
      nominationUrlPreview: '',
      liveVoteUrlPreview: '',
      rules: {
        email: [
          v => !!v || 'E-mail is required',
          v => validator.validate(v) || 'E-mail must be valid'
        ],
        name: [v => !!v || 'Name is required']
      }
    };
  },
  watch: {
    voterSelected: function(value) {
      this.state = _.cloneDeep(this.voterSelected);
      if (this.canSeeVoterLinks) {
        this.setLinks(value);
      }
    }
  },
  computed: {
    ...mapGetters(['isAdmin', 'scopes']),
    isChanged() {
      let state = {
        name: this.state.name,
        email: this.state.email,
        attending: this.state.attending,
        inAttendance: this.state.inAttendance,
        isMeetingHost: this.state.isMeetingHost,
        communicationPreferences: {
          disableAllEmails: _.get(
            this.state,
            'communicationPreferences.disableAllEmails',
            false
          ),
          disableConsentReminders: _.get(
            this.state,
            'communicationPreferences.disableConsentReminders',
            false
          ),
          isTestVoter: _.get(
            this.state,
            'communicationPreferences.isTestVoter',
            false
          )
        }
      };
      let current = {
        name: this.voterSelected.name,
        email: this.voterSelected.email,
        attending: this.voterSelected.attending,
        inAttendance: this.voterSelected.inAttendance,
        isMeetingHost: this.voterSelected.isMeetingHost,
        communicationPreferences: {
          disableAllEmails: _.get(
            this.voterSelected,
            'communicationPreferences.disableAllEmails',
            false
          ),
          disableConsentReminders: _.get(
            this.voterSelected,
            'communicationPreferences.disableConsentReminders',
            false
          ),
          isTestVoter: _.get(
            this.voterSelected,
            'communicationPreferences.isTestVoter',
            false
          )
        }
      };
      return !_.isEqual(state, current);
    },
    isPanelistChanged() {
      return this.voterSelected.isPanelist !== this.state.isPanelist;
    },
    canSeeVoterLinks() {
      return (
        this.isAdmin ||
        checkScope(
          this.scopes,
          this.$route.params.shortcode,
          'meeting.units.voters.links'
        )
      );
    },
    canDeleteVoters() {
      return (
        this.isAdmin ||
        checkScope(
          this.scopes,
          this.$route.params.shortcode,
          'meeting.units.voters.delete'
        )
      );
    },
    isCustomZoomMeeting() {
      return this.virtualMeetingType === 'Custom-Hosted';
    },
    hasRealEmail() {
      return this.voterSelected.email !== 'noemail@getquorum.com';
    }
  },
  methods: {
    ...mapActions('meetings/units', [
      'getMeetingUnits',
      'updateMeetingUnitVoter',
      'deleteMeetingUnitVoter',
      'getVoterLinks'
    ]),
    ...mapActions('meetings/webcast', [
      'addWebcastPanelists',
      'removeWebcastPanelist'
    ]),
    ...mapActions('meetings/voters', ['getMeetingVoters']),
    emitInput() {
      this.$emit('input');
    },
    emitClose() {
      this.$emit('close');
    },
    emitDelete() {
      this.$emit('delete');
    },
    async setLinks(voterSelected) {
      const gpUrl = _.get(this.options, 'useGeneralProxyApp', false);
      try {
        const links = await this.getVoterLinks({
          shortCode: this.$route.params.shortcode,
          unitId: voterSelected.unit_id,
          email: voterSelected.email
        });

        this.proxyUrlPreview = gpUrl ? links.proxyUrl : links.votingUrl;
        this.consentUrlPreview = links.consentUrl;
        this.nominationUrlPreview = links.nominationsUrl;
        this.liveVoteUrlPreview = links.liveVoteUrl;
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      }
    },
    async updateVoter() {
      try {
        if (this.isChanged) {
          await this.updateMeetingUnitVoter({
            shortCode: this.$route.params.shortcode,
            unitId: this.voterSelected.unit_id,
            email: this.voterSelected.email,
            voter: {
              name: this.state.name,
              email: this.state.email,
              attending: this.state.attending,
              inAttendance: this.state.inAttendance,
              isMeetingHost: this.state.isMeetingHost,
              communicationPreferences: {
                disableAllEmails: this.state.communicationPreferences
                  .disableAllEmails,
                disableConsentReminders: this.state.communicationPreferences
                  .disableConsentReminders,
                isTestVoter: this.state.communicationPreferences.isTestVoter,
                fromRegistration: this.state.communicationPreferences
                  .fromRegistration
              }
            }
          });
        }

        // Update panelist settings for the owner
        if (this.isPanelistChanged) {
          if (this.state.isPanelist) {
            await this.addWebcastPanelists({
              shortCode: this.$route.params.shortcode,
              name: this.state.name,
              email: this.state.email
            });
          } else {
            await this.removeWebcastPanelist({
              shortCode: this.$route.params.shortcode,
              email: this.state.email
            });
          }
          await this.getMeetingUnits({
            shortCode: this.$route.params.shortcode
          });
        }

        if (this.isPanelistChanged || this.isChanged) {
          this.$events.$emit('toastEvent', 'Voter Updated');
        }

        // Get updated list of voters, for re-sending Notices
        await this.getMeetingVoters({
          shortCode: this.$route.params.shortcode
        });
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      } finally {
        this.$emit('close');
      }
    },
    async deleteVoter() {
      try {
        await this.deleteMeetingUnitVoter({
          shortCode: this.$route.params.shortcode,
          unitId: this.voterSelected.unit_id,
          email: this.voterSelected.email
        });
        await this.getMeetingVoters({
          shortCode: this.$route.params.shortcode
        });
        this.$events.$emit('toastEvent', 'Voter Deleted');
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      } finally {
        this.dialog.delete = false;
        this.$emit('close');
      }
    },
    handleCopy(ref) {
      this.$refs[ref].select();
      document.execCommand('copy');
    }
  }
};
</script>

<style scoped>
.text-padding {
  margin-left: 32px;
}
.url-input {
  min-width: 250px;
}
</style>
