'use strict';

/**
 * @fileoverview Meeting Units Vuex submodule.
 * Used to store state for the current meeting's unit data
 */

import _ from 'lodash';
import Vue from 'vue';

import * as noticeApi from '@/api/notices';
import * as mtgApi from '@/api/meetings';
import * as ntgApi from '@/api/notice-template-groups';
import * as ntApi from '@/api/notice-templates';

import {
  makeNewNoticeObj,
  makeNoticeUpdateObj
} from '@/lib/make-notice-object';

const state = {
  meetingNoticeList: {},
  recipientsListData: [],
  rawRecipientsListData: [],
  testVotersListData: [],
  preview: {
    html: '',
    subject: '',
    attachments: null
  },
  dataLastFetchedAt: null,
  groupsForScheduler: [],
  templatesForScheduler: [],
  noticeGroupByTemplate: [],
  noticeListGenerated: [],
  noticeListScheduled: [],
  noticeFields: {}
};

const getters = {
  meetingNoticeList: state => state.meetingNoticeList,
  // Gets the notices in an array sorted by sent and scheduled
  meetingNoticeListDataSorted: state => {
    const notices = Object.values(state.meetingNoticeList);
    return _.sortBy(notices, ['sentAt', 'sendOn']);
  },
  recipientsListData: state => state.recipientsListData,
  rawRecipientsListData: state => state.rawRecipientsListData,
  groupsForScheduler: state => state.groupsForScheduler,
  templatesForScheduler: state => state.templatesForScheduler,
  noticeGroupByTemplate: state => state.noticeGroupByTemplate,
  noticeListGenerated: state => state.noticeListGenerated,
  noticeListScheduled: state => state.noticeListScheduled,
  testVotersListData: state => state.testVotersListData,
  meetingNoticePreview: state => state.preview,
  noticeMergeFields: state => state.noticeFields
};

const actions = {
  /**
   * Gets the list of meeting's notices
   * @param {String}  {shortCode}     the meeting shortCode
   * @param {Boolean} {includeStats}  if stats should be called too (default false)
   */
  async getMeetingNotices(
    { commit, dispatch },
    { shortCode, includeStats = false }
  ) {
    try {
      const res = await mtgApi.getMeetingNotices(shortCode);
      commit('SET_MEETING_NOTICE_LIST_DATA', res.data);

      if (includeStats) {
        console.log('Including Notice Stats');

        // Go through each notice and find the ones that are sent.
        // Only get stats for those notices
        res.data.forEach(notice => {
          const { status, key } = notice;
          if (status === 'sent') {
            dispatch('getMeetingNoticeStats', { shortCode, noticeKey: key });
          }
        });
      }
    } catch (err) {
      console.error('ERROR: getMeetingNotices action', err);
      throw err;
    }
  },

  /**
   * Gets one of the meeting's notices
   *
   * @param {String} {shortCode}  the meeting shortCode
   * @param {String} noticeKey    - notice key
   */
  async getMeetingNotice({ commit, dispatch }, { shortCode, noticeKey }) {
    try {
      // Get notice details
      const res = await noticeApi.getNoticeDetails(noticeKey);
      commit('SET_MEETING_NOTICE_IN_LIST', { noticeKey, data: res.data });

      // Get recipient details
      const recipients = res.data.recipients;
      dispatch('getRecipientList', { shortCode, recipients });

      // Get status if notice is sent
      const status = res.data.status;
      if (status === 'sent') {
        dispatch('getMeetingNoticeStats', { shortCode, noticeKey });
      }
    } catch (err) {
      console.error('ERROR: getMeetingNotices action', err);
      throw err;
    }
  },

  /**
   * Get the stats for a Meeting Notice
   * @param {String} {shortCode}  the meeting shortCode
   * @param {String} noticeKey    - notice key
   */
  async getMeetingNoticeStats({ commit }, { shortCode, noticeKey }) {
    try {
      // Clear errors
      commit('SET_MEETING_NOTICE_PROP_IN_LIST', {
        noticeKey,
        prop: 'statsLoadError',
        data: false
      });
      // Start loading
      commit('SET_MEETING_NOTICE_PROP_IN_LIST', {
        noticeKey,
        prop: 'statsLoading',
        data: true
      });

      const res = await mtgApi.getMeetingNoticeStats({ shortCode, noticeKey });

      // Set the stats prop
      commit('SET_MEETING_NOTICE_PROP_IN_LIST', {
        noticeKey,
        prop: 'stats',
        data: res.data
      });
    } catch (err) {
      console.error('ERROR: getMeetingNoticeStats action', noticeKey, err);

      // Show that there's an error loading stats to that notice
      commit('SET_MEETING_NOTICE_PROP_IN_LIST', {
        noticeKey,
        prop: 'statsLoadError',
        data: true
      });
      throw err;
    } finally {
      // Stop loading
      commit('SET_MEETING_NOTICE_PROP_IN_LIST', {
        noticeKey,
        prop: 'statsLoading',
        data: false
      });
    }
  },

  /**
   * Gets the list of notice template groups for the scheduler
   */
  async getMeetingNoticeGroups({ commit }) {
    try {
      const res = await ntgApi.getGroupsForScheduler();
      commit('SET_MEETING_NOTICE_TEMPLATE_GROUPS_IN_LIST', res.data);
    } catch (err) {
      console.error('ERROR: getMeetingNoticeGroups action', err);
      throw err;
    }
  },

  /**
   * Gets the list of notice templates for the scheduler
   */
  async getMeetingNoticeTemplates({ commit }) {
    try {
      const res = await ntApi.getTemplatesForScheduler();
      commit('SET_MEETING_NOTICE_TEMPLATES_IN_LIST', res.data);
    } catch (err) {
      console.error('ERROR: getMeetingNoticeTemplates action', err);
      throw err;
    }
  },

  /**
   * Find the notice template group(s) for the scheduler by noticeTemplateKey
   */
  async getNoticeGroupByTemplate({ commit }, { noticeTemplateKey }) {
    try {
      const res = await ntApi.getNoticeGroupByTemplate(noticeTemplateKey);
      commit('SET_MEETING_NOTICE_GROUP_MEMBERSHIP', res.data);
    } catch (err) {
      console.error('ERROR: getNoticeGroupByTemplate action', err);
      throw err;
    }
  },

  /**
   * Generate the suggested schedule dates for a list of notices.
   *
   * @param {Object} {generateParams}  object containing various parameters required for generating group notices
   */
  async generateMeetingNoticeListSchedule({ commit }, generateParams) {
    try {
      const res = await noticeApi.generateNoticeListSchedule(generateParams);
      commit('SET_MEETING_NOTICES_GENERATED', res.data);
    } catch (err) {
      console.error('ERROR: generateMeetingNoticeListSchedule action', err);
      throw err;
    }
  },

  /**
   * Create and schedule the list of notices provided with the assigned send dates
   *
   * @param {Object} {scheduleParams}  object containing various parameters required for scheduling group notices

   */
  async scheduleMeetingNoticeList({ commit }, scheduleParams) {
    try {
      let notices = scheduleParams.notices;
      const meeting = scheduleParams.meeting;
      const contactEmail =
        meeting.options.replyToEmail ||
        `notices+${meeting.shortCode}@getquorum.com` ||
        meeting.contact.email;

      const noticesCreated = _.map(notices, function(notice) {
        notice.recipients = { segment: notice.segment };
        return makeNewNoticeObj(meeting.shortCode, notice, contactEmail);
      });

      const res = await noticeApi.scheduleNoticeList({
        notices: noticesCreated,
        meeting: meeting,
        startDate: scheduleParams.startDate,
        endDate: scheduleParams.endDate
      });

      commit('SET_MEETING_NOTICES_SCHEDULED', res.data);
    } catch (err) {
      console.error('ERROR: scheduleMeetingNoticeList action', err);
      throw err;
    }
  },

  /**
   * Add a new notice to this meeting
   * @param {String} {shortCode}  the meeting shortCode
   * @param {Object} {newNotice}  new notice to add
   * @param {Object} {contact}  email for replyTo
   */
  async createMeetingNotice({ dispatch }, { shortCode, newNotice, contact }) {
    const notice = makeNewNoticeObj(shortCode, newNotice, contact);
    try {
      const res = await noticeApi.postNotice(notice);
      dispatch('getMeetingNotices', { shortCode });
      return res.data;
    } catch (err) {
      console.error('ERROR: createMeetingNotice action', err);
      throw err;
    }
  },

  /**
   * Updates the notice
   * @param {String}  {shortCode}  the meeting shortCode
   * @param {String}  {noticeKey}  the notice key to update
   * @param {Object}  {notice}     update notice payload
   * @param {Boolean} {force}      force update, even if notice is no longer 'draft'
   */
  async updateMeetingNotice(
    { commit },
    { shortCode, noticeKey, notice, force = false }
  ) {
    const payload = makeNoticeUpdateObj(shortCode, notice);
    try {
      const res = await noticeApi.putNotice(noticeKey, force, payload);

      const noticeUpdate = { ...notice, ...res.data };
      commit('SET_MEETING_NOTICE_IN_LIST', { noticeKey, data: noticeUpdate });
    } catch (err) {
      console.error('ERROR: updateMeetingNotice action', err);
      throw err;
    }
  },

  /**
   * Deletes the notice
   * @param {String} {shortCode}  the meeting shortCode
   * @param {String} {noticeKey}  the notice key to update
   */
  async deleteMeetingsNotice({ dispatch }, { shortCode, noticeKey }) {
    try {
      await noticeApi.deleteNotice(noticeKey);
      dispatch('getMeetingNotices', { shortCode });
    } catch (err) {
      console.error('ERROR: deleteMeetingsNotice action', err);
      throw err;
    }
  },

  /**
   * Gets the list of recipients based on a segment
   *
   * @param {String}  shortCode      - the meeting shortCode
   * @param {Object}  recipients     - recipient information
   * @param {Boolean} urgent         - whether the email is urgent
   * @param
   */
  async getRecipientList({ commit }, { shortCode, recipients, urgent }) {
    try {
      const { segment, emails, range, customData } = recipients;
      const res = await mtgApi.getRecipientsBySegment({
        shortCode,
        segment,
        emails,
        range,
        urgent,
        customData
      });
      // Raw list data which includes emails not defined in membership tab
      commit('SET_MEETING_NOTICE_RAW_RECIPIENTS_LIST_DATA', emails);
      // Filtered list data which only contains member emails
      commit('SET_MEETING_NOTICE_RECIPIENTS_LIST_DATA', res.data.recipients);
    } catch (err) {
      console.error('ERROR: getRecipientList action', err);
      throw err;
    }
  },

  /**
   * Schedule this notice.
   *
   * @param {String} {shortCode}  the meeting shortCode
   * @param {String} {noticeKey}  the notice key to update
   */
  async scheduleMeetingNotice({ dispatch }, { shortCode, noticeKey }) {
    try {
      await noticeApi.postScheduleNotice(noticeKey);
      dispatch('getMeetingNotice', { shortCode, noticeKey });
    } catch (err) {
      console.error('ERROR: scheduleMeetingNotice action', err);
      throw err;
    }
  },

  /**
   * Gets the list of test voters
   *
   * @param {String} {shortCode}  the meeting shortCode
   * @param {Object} {notice}     update notice payload
   */
  async getTestVotersList({ commit }, { shortCode, recipients }) {
    try {
      const { emails } = recipients;
      const res = await mtgApi.getRecipientsBySegment({
        shortCode,
        segment: 'test-voters',
        emails
      });
      commit('SET_MEETING_NOTICE_TEST_VOTERS_LIST_DATA', res.data.recipients);
    } catch (err) {
      console.error('ERROR: getRecipientList action', err);
      throw err;
    }
  },

  /**
   * Gets the html preview on the notice
   *
   * @param {String} {shortCode}  the meeting shortCode
   * @param {String} {noticeKey}  the notice key to update
   * @param {String} {voterKey}  the voter key to preview (if provided)
   */
  async getMeetingsNoticePreview(
    { commit },
    { shortCode, noticeKey, voterKey }
  ) {
    try {
      const res = await mtgApi.getNoticePreview(shortCode, noticeKey, voterKey);
      commit('SET_MEETING_NOTICE_PREVIEW', res.data);
    } catch (err) {
      console.error('ERROR: getMeetingsNoticePreview action', err, shortCode);
      throw err;
    }
  },

  /**
   * Send a test of this notice. No mutations.
   *
   * @param {String} {shortCode}  the meeting shortCode
   * @param {String} {noticeKey}  the notice key to update
   * @param {Array}  {payload} emails and preview object
   */
  async sendMeetingNoticeTest({ commit }, { shortCode, noticeKey, payload }) {
    try {
      const res = await noticeApi.postSendTestNotice(noticeKey, payload);
      return res.data;
    } catch (err) {
      console.error('ERROR: sendMeetingNoticeTest action', err, shortCode);
      throw err;
    }
  },

  /**
   * Unschedule this notice.
   *
   * @param {String} {shortCode}  the meeting shortCode
   * @param {String} {noticeKey}  the notice key to update
   */
  async unscheduleMeetingNotice({ dispatch }, { shortCode, noticeKey }) {
    try {
      await noticeApi.postUnscheduleNotice(noticeKey);
      dispatch('getMeetingNotice', { shortCode, noticeKey });
    } catch (err) {
      console.error('ERROR: sendTestNotice action', err);
      throw err;
    }
  },

  /**
   * Send this notice.
   *
   * @param {String} {shortCode}  the meeting shortCode
   * @param {String} {noticeKey}  the notice key to update
   */
  async sendMeetingNotice({ dispatch }, { shortCode, noticeKey }) {
    try {
      await noticeApi.postSendNotice(noticeKey);
      dispatch('getMeetingNotice', { shortCode, noticeKey });
    } catch (err) {
      console.error('ERROR: sendMeetingNotice action', err);
      throw err;
    }
  },

  /**
   * Resend a notice.
   *
   * @param {String}   shortCode  - the meeting shortCode
   * @param {String}   noticeKey  - key of notice to resend
   * @param {String[]} recipients - list of keys to resend notice to
   */
  async resendMeetingNotice({ commit }, { shortCode, noticeKey, recipients }) {
    try {
      await mtgApi.postResendMeetingNotice({
        shortCode,
        noticeKey,
        recipients
      });
    } catch (err) {
      console.error('ERROR: resendMeetingNotice action', err);
      throw err;
    }
  },

  /**
   * Get all the notice fields that are available.
   *
   */
  async getNoticeFields({ commit }) {
    try {
      const res = await noticeApi.getNoticeFields();
      commit('SET_NOTICE_MERGE_FIELDS', res.data);
    } catch (err) {
      throw err;
    }
  }
};

const mutations = {
  SET_MEETING_NOTICE_LIST_DATA(state, data) {
    state.meetingNoticeList = _.keyBy(data, 'key');
    state.dataLastFetchedAt = new Date();
  },

  SET_MEETING_NOTICE_IN_LIST(state, { noticeKey, data }) {
    Vue.set(state.meetingNoticeList, noticeKey, data);
  },

  // Set prop for a notice
  SET_MEETING_NOTICE_PROP_IN_LIST(state, { noticeKey, prop, data }) {
    Vue.set(state.meetingNoticeList[noticeKey], prop, data);
  },

  SET_MEETING_NOTICE_TEMPLATES_IN_LIST(state, data) {
    state.templatesForScheduler = data;
  },

  SET_MEETING_NOTICE_TEMPLATE_GROUPS_IN_LIST(state, data) {
    state.groupsForScheduler = data;
  },

  SET_MEETING_NOTICE_RECIPIENTS_LIST_DATA(state, data) {
    state.recipientsListData = data;
  },

  SET_MEETING_NOTICE_TEST_VOTERS_LIST_DATA(state, data) {
    state.testVotersListData = data;
  },

  SET_MEETING_NOTICE_PREVIEW(state, preview) {
    state.preview = preview;
  },

  SET_MEETING_NOTICE_GROUP_MEMBERSHIP(state, data) {
    state.noticeGroupByTemplate = data;
  },

  SET_MEETING_NOTICES_GENERATED(state, data) {
    state.noticeListGenerated = data;
  },

  SET_MEETING_NOTICES_SCHEDULED(state, data) {
    state.noticeListScheduled = data;
  },

  SET_NOTICE_MERGE_FIELDS(state, data) {
    state.noticeFields = data;
  },
  SET_MEETING_NOTICE_RAW_RECIPIENTS_LIST_DATA(state, data) {
    state.rawRecipientsListData = data;
  },
  // Reset the state
  CLEAR_STATE(state) {
    state.meetingNoticeList = {};
    state.recipientsListData = [];
    state.preview = {
      html: '',
      subject: '',
      attachments: null
    };
    state.dataLastFetchedAt = null;
    state.groupsForScheduler = [];
    state.templatesForScheduler = [];
    state.noticeGroupByTemplate = [];
    state.noticeListGenerated = [];
    state.noticeListScheduled = [];
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
