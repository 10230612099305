'use strict';

/**
 * @fileoverview Routes for all the /Meetings/ views
 */

import MeetingsView from '@/views/meetings/MeetingsView';
import MeetingCreateView from '@/views/meetings/MeetingCreateView';
import MeetingMain from '@/views/meetings/meeting/MeetingMain';

// Subroutes
import MeetingOverview from '@/views/meetings/meeting/MeetingOverview';
import MeetingOverviewV2 from '@/views/meetings/meeting/overview/MeetingOverviewV2';
import MeetingSettings from '@/views/meetings/meeting/settings/MeetingSettingsView';
import MeetingVbmOverview from '@/views/meetings/meeting/vbm/MeetingVbmOverview';

import MeetingBusiness from '@/views/meetings/meeting/business/MeetingBusinessMain';
import MeetingBusinessListView from '@/views/meetings/meeting/business/MeetingBusinessListView';
import MeetingEditBusiness from '@/views/meetings/meeting/business/MeetingBusinessEditView';
import MeetingData from '@/views/meetings/meeting/business/MeetingBusinessDataView';
import MeetingRTVLiveAnswers from '@/views/meetings/meeting/rtv-live-answers/MeetingRTVLiveAnswersView';
import MeetingRTVQuestionsListView from '@/views/meetings/meeting/business/MeetingRTVQuestionsListView';
import MeetingRTVEditQuestion from '@/views/meetings/meeting/business/MeetingRTVQuestionEditView';
import MeetingBusinessAdditionalServices from '@/views/meetings/meeting/business/MeetingBusinessAdditionalServicesView';

import MeetingUnits from '@/views/meetings/meeting/units/MeetingUnitsMain';
import MeetingUnitsListView from '@/views/meetings/meeting/units/MeetingUnitsListView';
import MeetingUnitsImportView from '@/views/meetings/meeting/units/MeetingUnitsImportView';

import MeetingReports from '@/views/meetings/meeting/MeetingReportsView';
import MeetingFiles from '@/views/meetings/meeting/files/MeetingFilesView';

import MeetingNotices from '@/views/meetings/meeting/notices/MeetingNoticesMain';
import MeetingNoticeListView from '@/views/meetings/meeting/notices/MeetingNoticeListView';
import MeetingEditNotices from '@/views/meetings/meeting/notices/MeetingNoticeView';

import MeetingProxies from '@/views/meetings/meeting/proxies/MeetingProxiesMain';
import MeetingProxiesView from '@/views/meetings/meeting/proxies/MeetingProxiesView';
import MeetingProxiesCreate from '@/views/meetings/meeting/proxies/MeetingProxiesCreate';
import MeetingBillingView from '@/views/meetings/meeting/billing/MeetingBillingView';
import MeetingCertificates from '@/views/meetings/meeting/certificates/MeetingCertificatesView';

import MeetingChecklists from '@/views/meetings/meeting/checklists/MeetingChecklistView';

import MeetingNominationsView from '@/views/meetings/meeting/nominations/MeetingNominationsView';
import MeetingPrintJobs from '@/views/meetings/meeting/print-jobs/MeetingPrintJobsMain';
import MeetingPrintJobsListView from '@/views/meetings/meeting/print-jobs/MeetingPrintJobsListView';
import MeetingPrintJobEdit from '@/views/meetings/meeting/print-jobs/MeetingPrintJobView';

import MeetingRtvMain from '@/views/meetings/meeting/rtv/MeetingRtvMain';
import MeetingRtvBallotsListView from '@/views/meetings/meeting/rtv/MeetingRtvBallotsListView';

import MeetingWebcast from '@/views/meetings/meeting/webcast/MeetingWebcastView';

import MeetingUsers from '@/views/meetings/meeting/users/MeetingUsersView';

import MeetingConversations from '@/views/meetings/meeting/conversations/MeetingConversationsMain';
import MeetingConversationListView from '@/views/meetings/meeting/conversations/MeetingConversationListView';

import MeetingTallyView from '@/views/meetings/meeting/tally/MeetingTallyView';
import MeetingTallyDataView from '@/views/meetings/meeting/tally/MeetingTallyDataView';

import MeetingApprovals from '@/views/meetings/meeting/approvals/MeetingApprovalsMain';
import MeetingApprovalsView from '@/views/meetings/meeting/approvals/MeetingApprovalsView';
import MeetingApprovalEditView from '@/views/meetings/meeting/approvals/MeetingApprovalEditView';

import MeetingRequestDetailView from '@/views/meetings/meeting/requests/MeetingRequestDetailView';
import MeetingRequest from '@/views/meetings/meeting/requests/MeetingRequestMain';

// The Vuex Store
import store from '@/vuex/store';
import { requiredAdminLevel } from './utils';
import _ from 'lodash';

export default [
  {
    name: 'meetings',
    path: '',
    component: MeetingsView,
    meta: {}
  },
  {
    name: 'addNewMeeting',
    path: 'create',
    component: MeetingCreateView,
    meta: {
      nestedPage: {
        label: 'Back',
        routeName: 'meetings'
      }
    }
  },
  {
    name: 'meetingRequest',
    path: 'request',
    component: MeetingRequest,
    meta: {
      navLabel: '',
      adminOnly: false
    },
    children: [
      {
        name: 'meetingRequestDetails',
        path: ':requestKey',
        component: MeetingRequestDetailView,
        meta: {
          navLabel: '',
          nestedPage: {
            label: 'Back to meetings',
            routeName: 'meetings'
          }
        }
      }
    ]
  },
  {
    name: 'meetingMain',
    path: ':shortcode',
    component: MeetingMain,
    meta: {},
    children: [
      {
        name: 'meetingOverview',
        path: 'overview',
        beforeEnter: requireV2,
        component: MeetingOverview,
        meta: {
          navLabel: 'Overview',
          icon: 'view_quilt',
          adminOnly: false
        }
      },
      {
        name: 'meetingOverviewV2',
        path: 'overviewV2',
        beforeEnter: requireV1,
        component: MeetingOverviewV2,
        meta: {
          navLabel: 'Overview',
          icon: 'view_quilt',
          adminOnly: true
        }
      },
      {
        name: 'meetingSettings',
        path: 'settings',
        component: MeetingSettings,
        meta: {
          navLabel: 'Settings',
          isAdminNavCategory: true,
          icon: 'settings',
          adminOnly: true
        },
        beforeEnter: requiredAdminLevel
      },
      {
        name: 'meetingWebcast',
        path: 'webcast',
        component: MeetingWebcast,
        meta: {
          navLabel: 'Webcast',
          icon: 'videocam',
          adminOnly: false,
          scope: 'meeting.webcast.read'
        }
      },
      {
        path: 'business',
        component: MeetingBusiness,
        meta: {
          navLabel: 'Questions',
          icon: 'question_answer',
          adminOnly: false,
          scope: 'meeting.ballot-questions.read'
        },
        children: [
          {
            name: 'meetingBusiness',
            path: '',
            components: {
              advance: MeetingBusinessListView,
              live: MeetingRTVQuestionsListView
            },
            meta: {
              navLabel: 'Questions'
            }
          },
          {
            name: 'meetingData',
            path: ':questionId/data',
            beforeEnter: requireV2,
            component: MeetingData,
            meta: {
              navLabel: '',
              nestedPage: {
                label: 'Back to overview',
                routeName: 'meetingOverview'
              }
            }
          },
          {
            name: 'meetingEditBusiness',
            path: 'advance/:questionId/edit',
            component: MeetingEditBusiness,
            meta: {
              navLabel: 'Update Question (Advance Vote)',
              nestedPage: {
                label: 'Back to questions list',
                routeName: 'meetingBusiness'
              }
            }
          },
          {
            name: 'meetingRTVEditQuestion',
            path: 'live/:questionId/edit',
            component: MeetingRTVEditQuestion,
            props: true,
            meta: {
              navLabel: 'Update Question (Live Vote)',
              nestedPage: {
                label: 'Back to questions list',
                routeName: 'meetingBusiness'
              }
            }
          },
          {
            name: 'meetingRTVLiveAnswers',
            path: 'rtv-live-answers',
            component: MeetingRTVLiveAnswers,
            meta: {
              navLabel: 'Live Votes',
              nestedPage: {
                label: 'Back to overview',
                routeName: 'meetingOverview'
              },
              adminOnly: true
            }
          },
          {
            name: 'meetingRTVLiveAnswersQuestions',
            path: 'rtv-live-answers',
            component: MeetingRTVLiveAnswers,
            meta: {
              navLabel: 'Live Votes',
              nestedPage: {
                label: 'Back to questions',
                routeName: 'meetingBusiness'
              },
              adminOnly: true
            }
          },
          {
            name: 'meetingVbmLiveAnswers',
            path: 'rtv-live-answers',
            component: MeetingRTVLiveAnswers,
            meta: {
              navLabel: 'Live Votes',
              nestedPage: {
                label: 'Back to overview',
                routeName: 'meetingVbmOverview'
              }
            }
          }
        ]
      },
      {
        path: 'units',
        component: MeetingUnits,
        meta: {
          navLabel: 'Membership',
          icon: 'account_box',
          adminOnly: false,
          scope: 'meeting.units.read'
        },
        children: [
          {
            name: 'meetingUnits',
            path: '',
            component: MeetingUnitsListView,
            meta: {
              navLabel: 'Membership'
            }
          }
        ]
      },
      {
        name: 'meetingImportUnits',
        path: 'import',
        component: MeetingUnitsImportView,
        meta: {
          navLabel: 'Import Data',
          icon: 'upload_file',
          isAdminNavCategory: true,
          adminOnly: true,
          nestedPage: {
            label: 'Back to membership page',
            routeName: 'meetingUnits'
          }
        },
        beforeEnter: requiredAdminLevel
      },
      {
        name: 'meetingReports',
        path: 'reports',
        component: MeetingReports,
        meta: {
          navLabel: 'Reports',
          isNestedNavLabel: false,
          icon: 'insert_chart_outlined',
          adminOnly: false,
          scope: 'meeting.reports.read'
        }
      },
      {
        path: 'notices',
        component: MeetingNotices,
        meta: {
          navLabel: 'Notices',
          icon: 'email',
          adminOnly: false,
          scope: 'meeting.notices.read'
        },
        children: [
          {
            name: 'meetingNotices',
            path: '',
            component: MeetingNoticeListView,
            meta: {
              navLabel: 'Notices'
            }
          },
          {
            name: 'meetingEditNotices',
            path: ':noticeKey/edit',
            component: MeetingEditNotices,
            meta: {
              navLabel: '',
              nestedPage: {
                label: 'Back to notices',
                routeName: 'meetingNotices'
              }
            }
          }
        ]
      },
      {
        path: 'proxies',
        component: MeetingProxies,
        meta: {
          navLabel: 'Proxies',
          icon: 'description',
          adminOnly: false,
          scope: 'meeting.proxies.read'
        },
        children: [
          {
            name: 'meetingProxies',
            path: '',
            component: MeetingProxiesView,
            meta: {
              navLabel: 'Proxies'
            }
          },
          {
            name: 'meetingProxiesCreate',
            path: 'create',
            component: MeetingProxiesCreate,
            meta: {
              navLabel: 'Add Proxy',
              nestedPage: {
                label: 'Back to Proxies page',
                routeName: 'meetingProxies'
              },
              adminOnly: false
            }
          }
        ]
      },
      {
        name: 'meetingBilling',
        path: 'invoices',
        component: MeetingBillingView,
        meta: {
          navLabel: 'Billing',
          icon: 'attach_money',
          isAdminNavCategory: true,
          adminOnly: true
        },
        beforeEnter: requiredAdminLevel
      },
      {
        name: 'meetingCertificates',
        path: 'certificates',
        component: MeetingCertificates,
        meta: {
          navLabel: 'Certificates',
          icon: 'receipt',
          isAdminNavCategory: true,
          adminOnly: true
        },
        beforeEnter: requiredAdminLevel
      },
      {
        name: 'meetingChecklists',
        path: 'checklists',
        component: MeetingChecklists,
        meta: {
          navLabel: 'Checklists',
          icon: 'list',
          isAdminNavCategory: true,
          adminOnly: true
        },
        beforeEnter: requiredAdminLevel,
        props: true
      },
      {
        name: 'meetingNominations',
        path: 'nominations',
        component: MeetingNominationsView,
        meta: {
          navLabel: 'Nominations',
          isNestedNavLabel: false,
          icon: 'how_to_reg',
          adminOnly: false
        }
      },
      {
        path: 'print-jobs',
        component: MeetingPrintJobs,
        meta: {
          navLabel: 'Print Jobs',
          icon: 'print',
          isAdminNavCategory: true,
          adminOnly: true
        },
        children: [
          {
            name: 'meetingPrintJobs',
            path: '',
            component: MeetingPrintJobsListView,
            meta: {
              navLabel: 'Print Jobs',
              adminOnly: true
            },
            beforeEnter: requiredAdminLevel
          },
          {
            name: 'meetingPrintJobEdit',
            path: ':key',
            component: MeetingPrintJobEdit,
            meta: {
              navLabel: '',
              nestedPage: {
                label: 'Back to print jobs',
                routeName: 'meetingPrintJobs'
              }
            }
          }
        ]
      },
      {
        name: 'meetingUsers',
        path: 'users',
        component: MeetingUsers,
        meta: {
          navLabel: 'Access',
          icon: 'badge',
          isAdminNavCategory: true,
          adminOnly: true
        },
        beforeEnter: requiredAdminLevel
      },
      {
        path: 'vote',
        component: MeetingRtvMain,
        meta: {
          navLabel: 'Ballots',
          isNestedNavLabel: true,
          adminOnly: true,
          hidden: true
        },
        children: [
          {
            name: 'meetingBallots',
            path: '',
            component: MeetingRtvBallotsListView,
            meta: {}
          }
        ]
      },
      {
        name: 'meetingFiles',
        path: 'files',
        component: MeetingFiles,
        meta: {
          navLabel: 'Shared Documents',
          icon: 'folder',
          scope: 'meeting.files.read'
        }
      },
      {
        path: 'conversations',
        component: MeetingConversations,
        meta: {
          navLabel: 'Conversations',
          icon: 'sms',
          isAdminNavCategory: true,
          adminOnly: true
        },
        children: [
          {
            name: 'meetingConversations',
            path: '',
            component: MeetingConversationListView,
            meta: {
              navLabel: 'Conversations',
              adminOnly: true
            },
            beforeEnter: requiredAdminLevel
          }
        ]
      },
      {
        name: 'meetingServices',
        path: 'additional-services',
        component: MeetingBusinessAdditionalServices,
        meta: {
          navLabel: 'Additional Services',
          isAdminNavCategory: true,
          isExperimental: true,
          icon: 'shopping_cart',
          adminOnly: true
        },
        beforeEnter: requiredAdminLevel
      },
      {
        name: 'meetingVbmOverview',
        path: 'vbm',
        component: MeetingVbmOverview,
        meta: {
          navLabel: 'Overview',
          icon: 'view_quilt',
          adminOnly: false
        }
      },
      {
        name: 'meetingTally',
        path: 'tally',
        component: MeetingTallyView,
        meta: {
          navLabel: 'Results',
          isNestedNavLabel: false,
          icon: 'how_to_vote',
          adminOnly: true,
          workInProgress: true,
          hidden: true
        }
      },
      {
        name: 'meetingTallyData',
        path: 'tally-data',
        component: MeetingTallyDataView,
        meta: {
          navLabel: 'Vote Data',
          adminOnly: true,
          nestedPage: {
            label: 'Back to tally',
            routeName: 'meetingTally'
          }
        }
      },
      {
        path: 'reviews',
        component: MeetingApprovals,
        meta: {
          navLabel: 'Approval Requests',
          icon: 'thumb_up',
          adminOnly: false,
          scope: 'meeting.approvals.read'
        },
        children: [
          {
            name: 'meetingApprovals',
            path: '',
            component: MeetingApprovalsView,
            meta: {
              navLabel: 'Approval Requests'
            }
          },
          {
            name: 'meetingApprovalEdit',
            path: ':arKey',
            component: MeetingApprovalEditView,
            meta: {
              navLabel: '',
              nestedPage: {
                label: 'Back to approvals',
                routeName: 'meetingApprovals'
              }
            }
          }
        ]
      }
    ]
  }
];

async function requireV1(to, from, next) {
  try {
    // Check if meeting type is VBM
    // if true then reroute to vbm path
    if (await isVbmRequired(to)) {
      return next({ name: 'meetingVbmOverview', params: { ...to.params } });
    }

    // Check if V2 overview is enabled
    // if false then instead of V2 overview
    // reroute to V1 overview
    if (!(await isV2Enabled(to))) {
      return next({
        name: to.name.replace('V2', ''),
        params: { ...to.params }
      });
    }

    // by default route to V2 overview
    return next();
  } catch (err) {
    // in case of error send back to home dash
    next({ name: 'meetings' });
  }
}

async function requireV2(to, from, next) {
  try {
    // Check if meeting type is VBM
    // if true then reroute to vbm path
    if (await isVbmRequired(to)) {
      return next({ name: 'meetingVbmOverview', params: { ...to.params } });
    }

    // Check if V2 overview is enabled
    // if true then instead of V1 overview
    // reroute to V2 overview

    // We'll revisiting our plans for how to execute a self managed campaign.
    // if (await isV2Enabled(to)) {
    //   return next({ name: `${to.name}V2`, params: { ...to.params } });
    // }

    // by default route to V1 overview
    return next();
  } catch (err) {
    // in case of error send back to home dash
    next({ name: 'meetings' });
  }
}

// Checks is V2 experimental feature is enabled
async function isV2Enabled(to) {
  const shortCode = _.get(to.params, 'shortcode', null);

  if (shortCode) {
    await store.dispatch('features/loadFeatureFlags', { shortCode });
    const isExperimental = store.getters['features/experimentalMode'];
    const meeting = await getMeetingData(shortCode);
    const isV2Overview = _.get(meeting, 'options.displayOverviewV2', false);
    return isExperimental && isV2Overview;
  }
  return false;
}

// Checks if meeting is VBM or not
async function isVbmRequired(to) {
  const shortCode = _.get(to.params, 'shortcode', null);
  if (shortCode) {
    const meeting = await getMeetingData(shortCode);
    const type = _.get(meeting, 'options.type', 'meeting');
    if (type === 'vbm') {
      return true;
    }
  }
  return false;
}

// Get meeting data if there is non to check options
async function getMeetingData(shortCode) {
  let meeting = store.getters['meetings/meetingByShortCode'](shortCode);

  if (!meeting) {
    // If there is no data for meeting need to get data for the meeting
    // On a reload there is no data and route guard can't know
    // if re-routing is needed
    await store.dispatch('meetings/getMeeting', { shortCode });
    meeting = store.getters['meetings/meetingByShortCode'](shortCode);
  }

  return meeting;
}
