<template>
  <v-dialog :value="value" fullscreen transition="dialog-bottom-transition">
    <v-card>
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="emitClose"> <v-icon>close</v-icon> </v-btn>

        <v-toolbar-title>Edit Account Details</v-toolbar-title>
        <v-spacer />
        <v-btn dark class="red" @click.native="emitSubmit">
          Save
          <v-icon right>save</v-icon>
        </v-btn>
      </v-toolbar>
      <v-container grid-list-md fluid>
        <h2 class="title">General Customer Info</h2>
        <v-layout row>
          <v-flex xs12 md8>
            <v-text-field
              label="Customer Name"
              :value="name"
              @change="onChange('name', $event)"
            />
          </v-flex>
        </v-layout>
        <v-layout>
          <v-flex xs12 md8>
            <v-text-field
              label="Customer Name Short"
              :value="nameShort"
              @change="onChange('nameShort', $event)"
            />
          </v-flex>
        </v-layout>
        <v-layout>
          <v-flex xs12 md8>
            <v-select
              label="Customer Type"
              :value="customerType"
              :items="customerTypes"
              item-text="label"
              item-value="value"
              @change="onChange('customerType', $event)"
            />
          </v-flex>
        </v-layout>
        <v-layout>
          <v-flex xs12 md8>
            <v-select
              label="Region"
              :value="region"
              :items="regions"
              item-text="label"
              item-value="value"
              @change="onChange('region', $event)"
            />
          </v-flex>
        </v-layout>

        <h2 class="title mt-4">Contact</h2>
        <v-layout row>
          <v-flex xs12 md8>
            <v-text-field
              label="Contact Name"
              :value="contact.name"
              append-icon="face"
              @change="onChange('contact.name', $event)"
            />
          </v-flex>
        </v-layout>
        <v-layout row>
          <v-flex xs12 md8>
            <v-text-field
              label="Contact Preferred Name"
              :value="contact.preferredName"
              append-icon="perm_contact_cal"
              @change="onChange('contact.preferredName', $event)"
            />
          </v-flex>
        </v-layout>
        <v-layout row>
          <v-flex xs12 md8>
            <v-text-field
              label="Contact Email"
              :value="contact.email"
              append-icon="email"
              @change="onChange('contact.email', $event)"
            />
          </v-flex>
        </v-layout>
        <v-layout row>
          <v-flex xs12 md8>
            <v-text-field
              label="Contact Phone"
              :value="contact.phone"
              append-icon="phone"
              @change="onChange('contact.phone', $event)"
            />
          </v-flex>
        </v-layout>

        <h2 class="title mt-4">Billing Currency</h2>
        <v-layout row>
          <v-flex xs12 md8>
            <v-select
              label="Currency"
              :value="currency"
              :items="currencyOptions"
              item-text="label"
              item-value="value"
              @change="onChange('billingCurrency', $event)"
            />
          </v-flex>
        </v-layout>

        <h2 class="title mt-4">Billing Contact</h2>
        <v-layout row>
          <v-flex xs12 md8>
            <v-text-field
              label="Billing Contact Name"
              :value="data.billingContact.name"
              append-icon="face"
              @change="onChange('data.billingContact.name', $event)"
            />
          </v-flex>
        </v-layout>
        <v-layout row>
          <v-flex xs12 md8>
            <v-text-field
              label="Billing Contact Preferred Name"
              :value="data.billingContact.preferredName"
              append-icon="perm_contact_cal"
              @change="onChange('data.billingContact.preferredName', $event)"
            />
          </v-flex>
        </v-layout>
        <v-layout row>
          <v-flex xs12 md8>
            <v-text-field
              label="Billing Contact Email"
              :value="data.billingContact.email"
              append-icon="email"
              @change="onChange('data.billingContact.email', $event)"
            />
          </v-flex>
        </v-layout>
        <v-layout row>
          <v-flex xs12 md8>
            <p class="title">Preferred billing method:</p>
            <v-radio-group v-model="data.billingContact.preferredMethod">
              <v-radio label="Email" value="email"></v-radio>
              <v-radio label="Letter mail" value="letter_mail"></v-radio>
              <v-radio label="Other" value="other"></v-radio>
            </v-radio-group>
            <v-text-field
              v-if="data.billingContact.preferredMethod === 'other'"
              label="Preferred method"
              :value="data.billingContact.otherMethod"
              @change="onChange('data.billingContact.otherMethod', $event)"
            />
          </v-flex>
        </v-layout>

        <v-layout row>
          <v-flex xs12 md8>
            <v-text-field
              label="Billing Contact Phone"
              :value="data.billingContact.phone"
              append-icon="phone"
              @change="onChange('data.billingContact.phone', $event)"
            />
          </v-flex>
        </v-layout>

        <h2 class="title mt-4">Billing Instructions</h2>
        <v-layout row>
          <v-flex xs12 md8>
            <v-textarea
              label="Instructions"
              :value="data.billingContact.instructions"
              @change="onChange('data.billingContact.instructions', $event)"
            />
          </v-flex>
        </v-layout>

        <h2 class="title mt-4">Billing Address</h2>
        <v-layout row>
          <v-flex xs12 md8>
            <v-text-field
              label="Address"
              :value="billingAddress.address"
              :rules="rules.required"
              @change="onChange('billingAddress.address', $event)"
            />
          </v-flex>
        </v-layout>
        <v-layout row>
          <v-flex xs12 md8>
            <v-text-field
              label="City"
              :value="billingAddress.city"
              :rules="rules.required"
              @change="onChange('billingAddress.city', $event)"
            />
          </v-flex>
        </v-layout>
        <v-layout row>
          <v-flex xs12 md8>
            <v-autocomplete
              label="Province"
              :value="billingAddress.prov"
              :items="billingProvinceItems"
              item-text="regionName"
              item-value="regionCode"
              :rules="rules.required"
              @change="onChange('billingAddress.prov', $event)"
            />
          </v-flex>
        </v-layout>
        <v-layout row>
          <v-flex xs12 md8>
            <v-text-field
              label="Postal Code"
              :value="billingAddress.postal"
              :rules="rules.required"
              @change="onChange('billingAddress.postal', $event)"
            />
          </v-flex>
        </v-layout>
        <v-layout row>
          <v-flex xs12 md8>
            <v-autocomplete
              label="Country"
              :value="billingAddress.country"
              :items="countryItems"
              :rules="rules.required"
              @change="onChange('billingAddress.country', $event)"
            />
          </v-flex>
        </v-layout>

        <h2 class="title mt-4">Address</h2>
        <v-layout row>
          <v-flex xs12 md8>
            <v-text-field
              label="Address"
              :value="address.address"
              @change="onChange('address.address', $event)"
            />
          </v-flex>
        </v-layout>
        <v-layout row>
          <v-flex xs12 md8>
            <v-text-field
              label="City"
              :value="address.city"
              @change="onChange('address.city', $event)"
            />
          </v-flex>
        </v-layout>
        <v-layout row>
          <v-flex xs12 md8>
            <v-autocomplete
              label="Province"
              :value="address.prov"
              :items="provinceItems"
              item-text="regionName"
              item-value="regionCode"
              @change="onChange('address.prov', $event)"
            />
          </v-flex>
        </v-layout>
        <v-layout row>
          <v-flex xs12 md8>
            <v-text-field
              label="Postal Code"
              :value="address.postal"
              @change="onChange('address.postal', $event)"
            />
          </v-flex>
        </v-layout>
        <v-layout row>
          <v-flex xs12 md8>
            <v-autocomplete
              label="Country"
              :value="address.country"
              :items="countryItems"
              @change="onChange('address.country', $event)"
            />
          </v-flex>
        </v-layout>

        <v-layout
          ><h2 class="title mt-4">Return Address</h2>
          <v-btn small @click="resetReturnAddress">Reset all fields</v-btn>
        </v-layout>
        <div class="body-1">(Leave these fields blank if same as address)</div>

        <v-layout
          row
          v-for="(value, key, index) in returnAddress"
          :key="`rkey-${index}`"
        >
          <v-flex xs12 md8>
            <v-text-field
              :label="key"
              :value="value"
              @change="onChange(`returnAddress.${key}`, $event)"
            />
          </v-flex>
        </v-layout>

        <h2 class="title mt-4">Details</h2>
        <v-layout row>
          <v-flex xs12 md8>
            <v-text-field
              label="Nickname"
              :value="data.nickname"
              hint="eg. World on Yonge, The Shangri-la"
              @change="onChange('data.nickname', $event)"
            />
          </v-flex>
        </v-layout>
        <v-layout row>
          <v-flex xs12 md8>
            <v-text-field
              label="Neighbourhood"
              :value="data.neighbourhood"
              hint="eg. CityPlace, The Waterfront"
              @change="onChange('data.neighbourhood', $event)"
            />
          </v-flex>
        </v-layout>
        <v-layout row>
          <v-flex xs12 md8>
            <v-text-field
              label="Size"
              :value="data.numberOfUnits"
              hint="# of units"
              @change="onChange('data.numberOfUnits', $event)"
            />
          </v-flex>
        </v-layout>
        <v-layout row>
          <v-flex xs12 md8>
            <v-text-field
              append-icon="link"
              label="URL"
              :value="data.url"
              hint="Optional url"
              @change="onChange('data.url', $event)"
            />
          </v-flex>
        </v-layout>
        <v-layout row>
          <v-flex xs12 md8>
            <fiscal-year-end-selector
              :month="fiscalYearEndMonth"
              @update="onChange('data.fiscalYearEndMonth', $event)"
            />
          </v-flex>
        </v-layout>
        <v-layout row>
          <v-flex xs12 md8>
            <v-select
              :items="timezones"
              item-text="text"
              item-value="value"
              label="Timezone"
              :value="data.timezone"
              append-icon="access_time"
              @change="onChange('data.timezone', $event)"
            />
          </v-flex>
        </v-layout>

        <v-layout row class="mb-4">
          <v-flex xs12 md8>
            <div class="body-1 grey--text text--darken-2">
              <v-checkbox
                v-model="isVbmTrial"
                label="Virtual Board Meeting Trial"
                @change="onChange('data.vbmTrial.value', $event)"
              />
            </div>
            <date-picker
              label="VBM trial start date"
              :hint="'When VBM trial starts'"
              :iso-date="vbmTrial.startDate"
              :required="isVbmTrial"
              hide-icon
              class="mb-3"
              @update="onChange('data.vbmTrial.startDate', $event)"
              @clear="onChange('data.vbmTrial.startDate', '')"
            />
            <date-picker
              label="VBM trial end date"
              :hint="'When VBM trial ends'"
              :iso-date="vbmTrial.endDate"
              :required="isVbmTrial"
              hide-icon
              @update="onChange('data.vbmTrial.endDate', $event)"
              @clear="onChange('data.vbmTrial.endDate', '')"
            />
          </v-flex>
        </v-layout>

        <v-layout row>
          <v-flex xs12>
            <div class="title grey--text text--darken-4 mb-1">Client Logo</div>
            <input-link-add-images @uploaded="emitUpload" />
            <img
              v-if="uploadedImage"
              :src="uploadedImage"
              width="100"
              class="mb-4"
              alt="Client Logo"
            />
            <v-tooltip right>
              <span slot="activator">
                <v-icon
                  v-if="uploadedImage"
                  color="red"
                  small
                  style="position:absolute"
                  @click="removeImage"
                  >close</v-icon
                >
              </span>
              <span>Remove Image</span>
            </v-tooltip>
          </v-flex>
        </v-layout>
        <v-layout row>
          <v-flex xs12 md8>
            <div class="body-1 grey--text text--darken-2">
              Email Signature used for
              <code>signature</code> merge tag in email notices
            </div>
            <rich-text-editor
              :hide-merge-menu="true"
              :body="data.signature"
              @update="onChange('data.signature', $event)"
            />
          </v-flex>
        </v-layout>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import _ from 'lodash';
import FiscalYearEndSelector from '@/components/FiscalYearEndSelector';
import RichTextEditor from '@/components/RichTextEditor/RichTextEditor';
import timezones from '@/lib/timezones';
import InputLinkAddImages from '@/components/input/InputLinkAddImages';
import DatePicker from '@/components/DatePicker';
import {
  alpha2CountryCode,
  countryNames,
  provinceNames
} from '@/lib/address-validators';

export default {
  name: 'AdminAccountViewEditInfo',
  components: {
    FiscalYearEndSelector,
    RichTextEditor,
    InputLinkAddImages,
    DatePicker
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    name: { type: String, default: '' },
    nameShort: { type: String, default: '' },
    customerType: { type: String, default: '' },
    region: { type: String, default: '' },
    logoUrl: { type: String, default: '' },
    contact: {
      type: Object,
      default() {
        return {};
      }
    },
    address: {
      type: Object,
      default() {
        return {};
      }
    },
    returnAddress: {
      type: Object,
      default() {
        return '';
      }
    },
    billingAddress: {
      type: Object,
      default() {
        return '';
      }
    },
    currency: {
      type: String,
      default() {
        return {};
      }
    },
    data: {
      type: Object,
      default() {
        return {};
      }
    },
    customerTypes: {
      type: Array,
      default() {
        return [];
      }
    },
    regions: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      timezones,
      menu: false,
      isVbmTrial: false,
      uploadedImage: this.logoUrl,
      currencyOptions: [
        { label: 'CAD', value: 'CAD' },
        { label: 'USD', value: 'USD' }
      ],
      rules: {
        required: [v => !!v || 'Required field']
      }
    };
  },
  computed: {
    fiscalYearEndMonth() {
      return _.get(this.data, 'fiscalYearEndMonth', null);
    },
    countryCode() {
      const code = alpha2CountryCode(this.address.country);
      return code;
    },
    billingCountryCode() {
      const code = alpha2CountryCode(this.billingAddress.country);
      return code;
    },
    countryItems() {
      const countryArray = countryNames();
      return countryArray;
    },
    provinceItems() {
      const provArray = provinceNames(this.countryCode);
      return provArray;
    },
    billingProvinceItems() {
      const provArray = provinceNames(this.billingCountryCode);
      return provArray;
    },
    vbmTrial() {
      return _.get(this.data, 'vbmTrial', {
        value: false,
        startDate: null,
        endDate: null
      });
    }
  },
  mounted() {
    this.isVbmTrial = _.get(this.vbmTrial, 'value', false);
  },
  methods: {
    onChange(key, value) {
      this.$emit('update', { key, value });
    },
    // emitting an @input is needed for dialog components to use v-model for opening and closing
    emitInput() {
      this.$emit('input');
    },
    emitClose() {
      this.$emit('close');
    },
    emitSubmit() {
      this.$emit('submit');
    },
    resetReturnAddress() {
      let emptyReturnAddress = {
        address: null,
        city: null,
        postal: null,
        prov: null,
        country: null
      };
      this.$emit('update', { key: 'returnAddress', value: emptyReturnAddress });
    },
    emitUpload(res) {
      this.uploadedImage = res[0];
      this.onChange('logoUrl', this.uploadedImage);
    },
    removeImage() {
      this.uploadedImage = undefined;
      this.onChange('logoUrl', this.uploadedImage);
    }
  }
};
</script>

<style scoped></style>
