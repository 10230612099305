<template>
  <v-container>
    <div class="title grey--text text--darken-4 mb-1">
      Consent Options
    </div>
    <div class="body-1 grey--text text--darken-2 mb-4">
      Choose the `Consent Format` for this campaign:<br />
      Default - Consent form submission required<br />
      Email on file - Valid email required<br />
      (Upcoming) Multi-Consent - Consent form to opt-in for e-notice and
      e-voting
    </div>

    <v-layout row justify-space-between>
      <v-flex xs4>
        <!-- Consent Format Selector -->
        <v-select
          v-model="consentFormatSelect"
          :items="consentFormats"
          item-value="value"
          item-text="label"
          chips
          :value="options.consentFormat || 'default'"
          label="Consent Format"
          @change="onFormatChange($event)"
        />
      </v-flex>
      <v-flex xs4>
        <!-- Consent Template Selector -->
        <v-select
          v-if="consentFormatSelect === 'default'"
          v-model="consentTemplateSelect"
          :items="consentTemplates"
          item-value="value"
          item-text="label"
          item-disabled="disabled"
          chips
          label="Consent Template"
          persistent-hint
          min="6"
          max="15"
          @change="onTemplateChange($event)"
        />
      </v-flex>
    </v-layout>

    <div v-if="consentTemplateSelect === 'custom'">
      <rich-text-editor
        :key="editorKey"
        :show-custom-fields="true"
        custom-label="Custom
            fields defined in settings"
        :custom-fields="customFields"
        :body="consentBody"
        @update="onChange('consentBody', $event)"
      />

      <div class="preview-container">
        <v-tooltip top :disabled="!isPreviewDisabled">
          <template slot="activator">
            <v-btn
              flat
              @click.native="getCustomPreview()"
              :disabled="isPreviewDisabled"
              ><v-icon left>remove_red_eye</v-icon>Preview
            </v-btn>
          </template>
          <span>Save changes to preview</span>
        </v-tooltip>
      </div>
    </div>
    <div v-else>
      <v-alert outline color="info" icon="info" :value="true">
        The Consent Template currently selected is
        <strong class="capitalize">{{ consentTemplateName }}</strong
        >.
        {{
          consentTemplateName !== 'custom'
            ? 'This template is not editable.'
            : ''
        }}
      </v-alert>

      <iframe class="preview-html" :srcdoc="htmlPreview" />
    </div>

    <preview-dialog
      :key="previewDialogKey"
      v-model="dialog.preview"
      :html="htmlPreview"
      :recipientCount="0"
      :recipientTotal="0"
      @close="dialog.preview = false"
      :recipientInfoHidden="true"
      containerStyles="px-4"
    />

    <v-layout class="mt-3">
      <div class="pr-3">
        <v-checkbox
          :true-value="true"
          :false-value="false"
          v-model="displayConfirmSignature"
          @change="onChange('options.displayConfirmSignature', $event)"
        />
      </div>

      <div>
        <h5 class="subheading">
          (BETA) Enable signature requirement for Consent app
        </h5>
        <p class="grey--text text--darken-2">
          Check this to enable signature requirement for Consent app
        </p>
      </div>
    </v-layout>
  </v-container>
</template>

<script>
import _ from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import RichTextEditor from '@/components/RichTextEditor/RichTextEditor';
import PreviewDialog from '@/components/dialogs/HtmlPreviewDialog';
import { getRecipe } from '@/api/recipes';

export default {
  name: 'MeetingSettingsConsentOptionsInput',
  components: {
    RichTextEditor,
    PreviewDialog
  },
  props: {
    template: {
      type: String,
      default: null
    },
    consentBody: {
      type: String,
      default: null
    },
    options: {
      type: Object,
      default() {
        return {};
      }
    },
    shortCode: {
      type: String,
      default: ''
    }
  },
  watch: {
    consentTemplateSelect: function(template) {
      if (template !== 'custom') {
        // If not custom, always retrieve template preview
        this.getPreview(template);
      } else if (template === 'custom' && !this.consentBody) {
        // If custom is selected and no consent body exists, pre-populate from recipe
        this.getCustomConsentTemplate();
      }
    }
  },
  data() {
    return {
      // Track selector state locally
      consentFormatSelect: this.options?.consentFormat
        ? _.clone(this.options.consentFormat)
        : 'default',
      consentTemplateSelect: this.options?.consentTemplate
        ? _.clone(this.options.consentTemplate)
        : 'default',

      // Selector options
      consentFormats: [
        { label: 'Default', value: 'default', disabled: false },
        { label: 'Email on file', value: 'email-on-file', disabled: false },
        {
          label: 'Multi-Consent (beta)',
          value: 'multi-consent',
          disabled: false
        }
      ],
      consentTemplates: [
        {
          label: 'Ontario',
          value: 'default'
        },
        {
          label: 'Florida',
          value: 'florida'
        },
        {
          label: 'Alberta',
          value: 'alberta'
        },
        {
          label: 'British Columbia',
          value: 'british columbia'
        },
        {
          label: 'Custom',
          value: 'custom'
        }
      ],

      // Used for manual refresh of components
      editorKey: 0,
      previewDialogKey: 0,

      // Dialogs
      dialog: {
        preview: false
      },
      displayConfirmSignature: false
    };
  },
  computed: {
    ...mapGetters('meetings/consentTemplates', ['htmlPreview']),
    consentTemplateName() {
      const consentTemplate = _.get(this.options, 'consentTemplate', 'default');
      return consentTemplate === 'default' ? 'ontario' : consentTemplate;
    },
    customFields() {
      const customUnitFields = this.options.customUnitFields || [];
      return customUnitFields.map(field => `customUnitFields.${field}`);
    },
    isPreviewDisabled() {
      // Conditions for disabling custom preview button

      // 1. Check if the custom template text has been edited and not saved
      const isTemplateEdited = !_.isEqual(
        _.trim(this.template),
        _.trim(this.consentBody)
      );

      if (isTemplateEdited) {
        return true;
      }

      // 2. Check if the 'custom' consent template is selected but not yet saved
      const isCustomTemplateUnsaved =
        this.consentTemplateSelect === 'custom' &&
        this.consentTemplateSelect !== this.consentTemplateName;

      if (isCustomTemplateUnsaved) {
        return true;
      }

      // Otherwise, enable preview button
      return false;
    }
  },
  methods: {
    ...mapActions('meetings/consentTemplates', ['getConsentTemplatePreview']),
    onChange(key, value) {
      this.$emit('update', { key, value });
    },
    onTemplateChange(value) {
      this.onChange('options.consentTemplate', value);
    },
    onFormatChange(value) {
      this.onChange('options.consentFormat', value);
    },
    async init() {
      // Retrieve template preview on initial create
      await this.getPreview();
    },
    async getPreview(template = null) {
      try {
        const previewParams = {
          shortCode: this.shortCode
        };

        // Include 'template' in params only if it's provided
        // Otherwise previews what's saved in database
        if (template) {
          previewParams.template = template;
        }

        await this.getConsentTemplatePreview(previewParams);
      } catch (err) {
        this.$events.$emit(
          'showErrorDialog',
          err.response ? err.response : 'An unexpected error occurred'
        );
      }
    },
    // Retrieve custom preview and open dialog
    async getCustomPreview() {
      try {
        await this.getConsentTemplatePreview({
          shortCode: this.shortCode
        });
        // Open dialog
        this.dialog.preview = true;
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      } finally {
        this.previewDialogKey += 1;
      }
    },
    async getCustomConsentTemplate() {
      try {
        const recipe = await getRecipe(
          'meetings.settings.custom_consent_template'
        );
        // Set custom body template
        const template = recipe.data.data.template;
        this.$emit('update', { key: 'consentBody', value: template });
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      } finally {
        // Refresh the editor
        this.editorKey += 1;
      }
    }
  },
  created() {
    this.init();
  },
  mounted() {
    this.displayConfirmSignature = _.get(
      this.options,
      'displayConfirmSignature',
      false
    );
  }
};
</script>

<style scoped>
.capitalize {
  text-transform: capitalize;
}
.preview-container {
  text-align: right;
}
.preview-html {
  width: 100%;
  height: 70vh;
  max-height: 700px;
}
@media only screen and (max-width: 599px) {
  .preview-html {
    height: 40vh;
  }
}
</style>
