<template>
  <v-container grid-list-lg fluid>
    <v-layout row class="ml-3 mr-3">
      <v-flex xs12>
        <p class="title">Accounts (aka. "clients")</p>
      </v-flex>
    </v-layout>

    <v-layout v-if="filterDisplay" row align-center>
      <v-spacer />
      <span>Preferred CSM: </span>
      <v-chip v-for="(mgr, index) in filterDisplay" :key="index"
        >{{ mgr }}
      </v-chip>
    </v-layout>

    <v-layout row align-center>
      <v-spacer />
      <div class="mr-2">
        <span>Subscriptions: </span>
        <v-chip small>{{ subFilter }}</v-chip>
      </div>
      <div class="ml-2">
        <span>Agents: </span>
        <v-chip small>{{ agentsFilter }}</v-chip>
      </div>
    </v-layout>

    <v-layout row>
      <v-flex xs12 class="ma-3">
        <v-toolbar dark dense color="grey lighten-4 elevation-1">
          <v-text-field
            light
            prepend-icon="search"
            label="Search customer name..."
            single-line
            hide-details
            clearable
            :value="search"
            @input="updateSearch"
          />
          <v-spacer />
          <v-btn
            flat
            color="blue"
            class="white--text"
            @click.stop="dialog.filters = true"
          >
            <v-icon left dark>filter_list</v-icon>Filters
          </v-btn>
          <v-btn
            color="blue"
            class="white--text"
            @click.stop="dialog.addAccount = true"
          >
            <v-icon left dark>add</v-icon>Account
          </v-btn>
        </v-toolbar>

        <v-data-table
          :headers="headers"
          :items="accountListData"
          :rows-per-page-items="pagination.rowsPerPageItems"
          :total-items="pagination.totalItems"
          :pagination.sync="pagination"
          :loading="loading"
          class="elevation-1"
        >
          <template slot="items" slot-scope="props">
            <tr>
              <td class="nowrap">
                <v-tooltip bottom>
                  <span slot="activator">
                    <router-link
                      :to="{
                        name: 'editAccount',
                        params: { accountKey: props.item.key }
                      }"
                    >
                      {{ props.item.nameShort }}
                    </router-link>
                  </span>
                  <span>{{ props.item.name }}</span>
                </v-tooltip>
              </td>
              <td class="nowrap">{{ props.item.region }}</td>
              <td class="nowrap">{{ props.item.customerType }}</td>
              <td class="text-xs-right">{{ getNumberOfUnits(props.item) }}</td>

              <td class="nowrap">
                <v-tooltip bottom>
                  <div slot="activator">
                    <v-icon small class="mr-1 fade" color="grey">
                      contact_mail
                    </v-icon>
                    {{ props.item.contact.name | truncateText(15) }}
                  </div>
                  <div>
                    <b>{{ props.item.contact.name }}</b>
                    <ul>
                      <li>E: {{ props.item.contact.email }}</li>
                      <li>T: {{ props.item.contact.phone }}</li>
                    </ul>
                  </div>
                </v-tooltip>
              </td>

              <td class="nowrap">
                <v-tooltip bottom>
                  <span slot="activator">
                    {{ (props.item.agent.name || '') | truncateText(20) }}
                  </span>
                  <span> {{ props.item.agent.name }}</span>
                </v-tooltip>
              </td>

              <td class="nowrap">
                <v-tooltip bottom v-if="hasSubscription(props.item)">
                  <span slot="activator">
                    <v-icon
                      :color="getSubscriptionColor(props.item.subscription)"
                    >
                      monetization_on
                    </v-icon>
                  </span>
                  <span>
                    Subscription: {{ props.item.subscription.productName }} ({{
                      props.item.subscription.productQuantity
                    }}
                    Units)<br />
                    Status: {{ props.item.subscription.status }}<br />
                    Start:
                    {{ props.item.subscription.startDate | dateFormatReadable
                    }}<br />
                    End:
                    {{ props.item.subscription.endDate | dateFormatReadable
                    }}<br />
                    Invoice:
                    {{ props.item.subscription.invoiceStatus || 'No Invoice' }}
                  </span>
                </v-tooltip>

                <v-tooltip bottom v-if="props.item.agreementSignedAt">
                  <span slot="activator">
                    <v-icon color="primary lighten-2">
                      receipt_long
                    </v-icon>
                  </span>
                  <span>
                    MSA uploaded on
                    {{ props.item.agreementSignedAt | dateFormatReadable }}
                  </span>
                </v-tooltip>
              </td>
              <td>
                <v-tooltip bottom>
                  <span slot="activator">
                    <b>C:</b>
                    {{
                      props.item.preferredCsm
                        ? props.item.preferredCsm.name
                        : '' | truncateText(7)
                    }}
                  </span>
                  <span>
                    <b>CSM:</b>
                    {{
                      props.item.preferredCsm
                        ? props.item.preferredCsm.name
                        : ''
                    }}
                  </span>
                </v-tooltip>
              </td>
              <td class="nowrap text-xs-right">
                {{ props.item.createdAt | dateFormatReadable }}
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>

    <v-dialog v-model="dialog.addAccount" max-width="950px">
      <add-account-dialog
        :customer-types="customerTypes"
        :regions="regions"
        :loading="loading"
        @closeDialog="dialog.addAccount = false"
        @addAccount="addAccount"
      />
    </v-dialog>

    <v-dialog v-model="dialog.nextPage" max-width="500px">
      <next-page-dialog @closeDialog="dialog = false" @nextPage="goNextPage" />
    </v-dialog>

    <filter-accounts-dialog
      :value="dialog.filters"
      :options="checklistAssignees"
      :current-filters="filters"
      :current-sub-filter="subFilter"
      :current-agents-filter="agentsFilter"
      @update="updateFilters"
      @close="dialog.filters = false"
    />
  </v-container>
</template>

<script>
import _ from 'lodash';
import { mapActions, mapGetters } from 'vuex';

import AddAccountDialog from './AddAccountDialog';
import NextPageDialog from './AddAccountDoneDialog';
import FilterAccountsDialog from './FilterAccountsDialog';

import * as fyeHelpers from '@/lib/fiscal-year-end';
import * as filters from '@/filters';
import { handleError } from '@/helpers';

export default {
  name: 'AdminAccountsListView',
  components: {
    AddAccountDialog,
    NextPageDialog,
    FilterAccountsDialog
  },
  filters: {
    ...filters
  },
  computed: {
    ...mapGetters('accounts', [
      'accountListData',
      'customerTypes',
      'regions',
      'pagination',
      'filters',
      'subFilter',
      'agentsFilter'
    ]),
    ...mapGetters('checklists', ['checklistAssignees']),
    pagination: {
      get: function() {
        return this.$store.getters['accounts/pagination'];
      },
      set: function(value) {
        this.setPagination(value);
      }
    },
    filterDisplay() {
      if (this.filters.length > 0) {
        const filterDisplay = this.filters.map(mgr => {
          if (mgr === 'missingCsm') {
            return 'Accounts with no Preferred CSM';
          } else if (mgr === 'missingVmm') {
            return 'Accounts with no Preferred VMM';
          } else {
            return mgr;
          }
        });
        return filterDisplay.sort();
      }
      return null;
    }
  },
  watch: {
    async pagination() {
      this.loading = true;
      await this.getAccountListData();
      this.loading = false;
    }
  },
  data() {
    return {
      search: '',
      loading: false,
      headers: [
        { text: 'Customer', value: 'nameShort' },
        { text: 'Region', value: 'region', sortable: false },
        { text: 'Type', value: 'type', sortable: false },
        {
          text: 'Units',
          value: 'data.numberOfUnits',
          align: 'right',
          sortable: false
        },
        { text: 'Contact', value: 'contact.name', sortable: false },
        { text: 'Agents', value: 'agent.name', sortable: false },
        { text: '', sortable: false },
        { text: 'Preferred CSM', sortable: false },
        { text: 'Created', value: 'createdAt', align: 'right' }
      ],
      dialog: {
        addAccount: false,
        nextPage: false,
        filters: false
      },
      next: {
        accountKey: ''
      }
    };
  },
  created() {
    this.init();
  },
  methods: {
    ...mapActions('accounts', [
      'createAccount',
      'getAccountListData',
      'getCustomerTypes',
      'getRegions',
      'setPagination',
      'setFilters',
      'createAgentAccount'
    ]),
    ...mapActions('checklists', ['getChecklistAssignees']),
    async init() {
      try {
        this.loading = true;
        await this.setPagination({
          descending: true,
          sortBy: 'createdAt',
          search: '',
          searchByShortcodeOnly: false,
          page: 1,
          rowsPerPage: 25,
          totalItems: 0,
          rowsPerPageItems: [25, 50, 100, 200]
        });
        await this.getCustomerTypes();
        await this.getRegions();
        await this.getChecklistAssignees();
        this.loading = false;
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
        handleError(err);
      }
    },
    getNumberOfUnits(account) {
      return _.has(account, 'data.numberOfUnits')
        ? account.data.numberOfUnits
        : 0;
    },
    // Get colouor for subscription icon
    getSubscriptionColor({ status, invoiceStatus }) {
      if (status === 'cancelled' || status === 'paused') {
        return 'red lighten-2';
      }

      // Status must be active otherwise
      if (invoiceStatus === 'paid') {
        return 'primary lighten-2';
      } else if (invoiceStatus === 'posted') {
        return 'teal lighten-2';
      } else if (invoiceStatus === 'payment_due') {
        return 'black';
      } else {
        return 'grey';
      }
    },
    hasSubscription(account) {
      return (
        account.subscription &&
        account.subscription.status !== 'no_subscription'
      );
    },
    async addAccount(newAccount) {
      try {
        this.loading = true;
        const accountKey = await this.createAccount(newAccount);

        if (newAccount.agents?.length > 0) {
          const agentAccountList = newAccount.agents.map(agent => agent.key);
          await this.createAgentAccount({ accountKey, agentAccountList });
        }

        this.$events.$emit('toastEvent', 'New Account Added');
        this.loading = false;
        this.dialog.addAccount = false;

        // Open dialog
        this.dialog.nextPage = true;

        // Set accountKey in case user decides to navigate there
        this.next.accountKey = accountKey;
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      } finally {
        this.loading = false;
      }
    },
    goNextPage(value) {
      if (value === 'addMeeting') {
        this.$router.push({
          name: 'addNewMeeting'
        });
      } else if (value === 'editAccount') {
        this.$router.push({
          name: 'editAccount',
          params: {
            accountKey: this.next.accountKey
          }
        });
      }
    },
    handleClick(row) {
      this.$router.push({
        name: 'editAccount',
        params: {
          accountKey: row.key
        }
      });
    },
    async updateFilters({ filters, subFilter, agentsFilter }) {
      try {
        this.loading = true;
        await this.setFilters({ filters, subFilter, agentsFilter });
        await this.getAccountListData();
        this.dialog.filters = false;
      } catch (err) {
        this.$events.$emit('toastEvent', err.message);
      } finally {
        this.loading = false;
      }
    },
    async updatePagination(pagination) {
      await this.setPagination(pagination);
    },
    updateSearch: _.debounce(function(value) {
      const newPagination = _.cloneDeep(this.pagination);
      newPagination.search = value;
      this.updatePagination(newPagination);
    }, 350)
  }
};
</script>

<style scoped>
.fade {
  opacity: 0.74;
}
</style>
