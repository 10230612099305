'use strict';

import _ from 'lodash';
import filterServices from './filter-services';

/**
 * Takes a minimal Meeting object and turns it into a full Meeting object for
 * use in POST'ing a new Meeting to API. Uses sensible defaults for the full
 * object.
 *
 * @param  {Object} meeting the minimal meeting object
 * @return {Object}        the full meeting object
 */
export default function makeMeetingObj(meeting = {}) {
  const {
    typeService,
    customType,
    selectedCorp,
    virtualMeetingType,
    meetingMeta: meetingMetaData = {},
    quorumMinimum,
    meetingProof = null
  } = meeting;

  let { type, subtype, virtualMode } = meeting;

  /* Case 1: Meeting card from a Meeting Request
     When the meeting type is hybrid:
        * subtype = 'Virtual' and virtualMode = 'Hybrid'

     Case 2: Meeting card manual creation
     When the meeting type is hybrid:
        * subtype = 'Hybrid' and virtualMode = null
  */
  virtualMode =
    subtype === 'Hybrid' || virtualMode === 'Hybrid' ? 'Hybrid' : null;
  subtype = subtype === 'Hybrid' ? 'Virtual' : subtype;

  // When the campaign type starts with 'meeting_', use meeting presets
  // For example meeting_townhall or campaign_advance
  type = type.startsWith('meeting_') ? 'meeting' : type;

  // If GQ Zoom license is selected, enable IP by default.
  const enableRtvZoom =
    (subtype === 'Virtual' || virtualMode === 'Hybrid') &&
    (virtualMeetingType === 'GQ-Hosted-Meeting' ||
      virtualMeetingType === 'GQ-Hosted');

  let votingOption;

  if (type === 'budget' || type === 'certs') {
    votingOption = 'No-voting';
  }

  let options = {
    customUnitFields: [],
    type,
    // if virtual mode is hybrid we considering it still as a virtual for subtype
    subtype,
    virtualMode,
    virtualMeetingType:
      virtualMeetingType === 'GQ-Hosted-Meeting'
        ? 'GQ-Hosted'
        : virtualMeetingType,
    signature: selectedCorp.data.signature
      ? selectedCorp.data.signature
      : 'n/a',
    enableRtvZoom,
    rtvAccessType: enableRtvZoom ? 'gq-web' : undefined,
    notificationFrequency: 'noEmail',
    displayVotingResultReport:
      type !== 'vbm' && (subtype === 'Virtual' || virtualMode === 'Hybrid'),
    votingOption
    // @Jason
    // TODO: un-comment the line below after testing out auto-reminders
    // meetingStartingReminder: null
  };

  if (subtype === 'In-Person' || virtualMode === 'Hybrid') {
    options.enableCheckinPdf = true;
  }

  // Create meetingMeta
  // List of valid additional services for filtering
  const validServices = filterServices({
    ...meeting,
    additionalServices: { ...meeting.meetingMeta } // meeting meta includes the additionalServices
  });

  // Merge additional & minute services, pick from valid services
  // Note: Currently only support minute services
  const mergedServices = _.pick(
    {
      ...meetingMetaData
    },
    validServices
  );

  // Create meetingMeta object
  let meetingMeta = {
    ...mergedServices,
    schedulingWizard: meetingMetaData.schedulingWizard
  };

  // if meeting request proof exists add it to the meeting meta
  if (meetingProof) {
    meetingMeta = {
      ...meetingMeta,
      meetingRequestProof: meetingProof
    };
  }

  // Translate meeting types to categories supported by API
  // Relevant for options.type determining card visibility
  // Can be updated on settings page
  if (['meeting', 'campaign_advance', 'campaign_amendment'].includes(type)) {
    // campaign category
    options.type = type;
    // campaign type of service
    options.typeService = typeService;
    // If meeting is agm/has voting, we disable proxy deferrals by default
    options.disableProxyDeferrals = true;
    // If meeting is agm/has voting, always add 'ineligible_reason', 'owner_occupied', and 'shares' fields
    options.customUnitFields.push('ineligible_reason');
    options.customUnitFields.push('owner_occupied');
    options.customUnitFields.push('shares');
    // If meeting is agm/has voting, we enable proxy revocations in Meeting Portal by default
    options.allowPortalRevocations = true;

    options.notificationFrequency = 'dailySummary';
    // @Jason
    // TODO: un-comment the lines below after testing out auto-reminders
    /*
    // If meeting is agm/has voting, enable auto-reminders
    // Default: send auto-reminders every 5 days
    options.meetingReminders = true;
    options.meetingReminderFrequency = 5;
    // If meeting is virtual/hybrid, we can auto-schedule the "Join Portal" notice
    // Default: 30 mins before meeting start time
    if (options.subtype !== 'In-Person') {
      options.meetingStartingReminder = 30;
    }
    */

    // With the booking wizard separating Advance Voting campaigns, conform to past 'type' with latest identifiers
    if (['campaign_advance', 'campaign_amendment'].includes(type)) {
      options.type = 'meeting';
      options.subtype = 'In-Person';
      options.typeService = type;
    }
  } else if (['budget', 'campaign_budget'].includes(type)) {
    options.type = 'budget';
    options.subtype = 'In-Person';
    options.typeService = 'campaign_budget';
    // If budget type, always add a monthly fee field
    options.customUnitFields.push('monthly_fee');
  } else if (['certs', 'campaign_certs'].includes(type)) {
    options.type = 'certs';
    options.subtype = 'In-Person';
    options.certType = 'campaign_certs';
    options.typeService = typeService;
  } else if (type === 'campaign_email_collection') {
    options.type = 'custom';
    options.subtype = 'In-Person';
    options.typeService = type;
  } else {
    // campaign category
    options.type = type;
    // campaign type of service
    options.typeService = typeService;
    options.customType = customType || 'other';
  }

  const obj = {
    name: meeting.name,
    accountKey: selectedCorp.key,
    corporation: {
      legalName: selectedCorp.name,
      legalNameShort: selectedCorp.nameShort
    },
    quorumMinimum:
      options.typeService === 'campaign_amendment' && quorumMinimum
        ? parseInt(quorumMinimum)
        : 0,
    meetingDate: meeting.meetingDate,
    meetingTimezone: meeting.meetingTimezone
      ? meeting.meetingTimezone
      : 'America/Toronto',
    expiryDate: meeting.expiryDate,
    nominationExpiryDate: meeting.nominationExpiryDate,
    revocationDeadlineDate: meeting.revocationDeadlineDate,
    consentDeadlineDate: meeting.consentDeadlineDate || null,
    duration: meeting.duration,
    contact: selectedCorp.contact,
    options,
    meetingStatus: virtualMode === 'Hybrid' ? 'hybrid' : null, // <- tag meeting hybrid if virtual mode
    meetingMeta,
    addresses: [selectedCorp.nameShort],
    proxyTemplate: {
      header:
        '<h4>Form 9</h4>\n<h4>{{ formName }} Proxy Form</h4>\n<h4>{{ formDescription }} (under subsection 52 (6) of the Condominium Act, 1998)</h4>\n<h5><em>Condominium Act, 1998</em></h5>',
      to:
        "<p>TO: <strong><u>{{ corporationName }}</u></strong> (known as the 'Corporation')</p>",
      proxyBody:
        '<li>I am (We are) the registered owner(s) or mortgagee(s) of <strong><u>Suite {{ unit }}</u></strong> at <strong><u>{{ address }}</u></strong>.</li>\n<li>I (We) appoint <strong><u>{{ holderName }}</u></strong>, if present, or whom failing him or her, <strong><u>{{{ defaultHolder }}}</u></strong>, if present, to be proxy (known as the \'Proxy\') and to attend and vote on my (our) behalf at the meeting of owners to be held on the <strong>{{{ eventDate }}}</strong>, and at any adjournment of the meeting (known as the "Meeting").</li><li>The Proxy may vote on my (our) behalf in respect of all matters that may come before the Meeting, subject to the instructions set out below, as I (we) could do if personally present at the Meeting.</li>{{{ answersHtml }}}<li>I (We) revoke all proxies previously given.</li>',
      terms:
        '<p>[ &check; ] I (We) agree to the terms and conditions (located at www.getquorum.com/terms-of-use) and acknowledge that by using the Online Proxy Form Tool, I am (we are) submitting this Proxy as though it were signed and delivered by hand.</p>',
      termsEmail:
        '<p>[ &check; ] I consent to receiving all Corporation communications electronically (eg. notices, meeting packages, and newsletters).</p>',
      signature:
        '<p>Dated this <strong>{{{ submitDate }}}</strong></p>\n<p>Name(s) of Registered Owner(s) or Mortgagee(s): <strong><u>&nbsp;&nbsp;&nbsp;&nbsp;{{ name }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</u></strong></p>',
      footer:
        '<p><em>(In the case of a corporation, affix corporate seal or add a statement that the persons signing have authoritiy to bind the corporation.)</em></p>\n<p><em>(If you are authorized to act on behalf of the registered owner(s) or mortgagee(s), attach a copy of the document that gives you this authorization.)</em></p>\n<p>A copy of this proxy has been emailed to the Corporation and to the Registered Owner as indicated at the email address above.</p>\n<p>For questions about this proxy, please contact us at support@getquorum.com</p>'
    }
  };
  return obj;
}
