<template>
  <v-dialog persistent :value="value" max-width="550px">
    <v-card>
      <v-card-title class="headline grey lighten-2" primary-title>
        <div class="title">Update Question Voting Period</div>
      </v-card-title>

      <v-card-text>
        <div class="my-3">
          <strong>"{{ question.label }}"</strong>
        </div>

        <v-divider />

        <div class="mt-3">
          <p>Type of voting period:</p>
          <v-radio-group v-model="deadlineType">
            <v-radio label="Number of minutes" value="minutes"></v-radio>
            <v-radio label="Submission deadline date" value="date"></v-radio>
          </v-radio-group>
        </div>

        <v-divider />

        <!-- Option 1: voting period is set by submission deadline date -->
        <div v-if="deadlineType === 'date'">
          <!-- Set Ballot submission deadline -->
          <p v-if="action === 'start'" class="mt-3">
            To open voting on this ballot question, enter a submission deadline
            and click <strong>START</strong>:
          </p>
          <!-- Update Ballot submission deadline -->
          <p v-else class="mt-3">
            To update the voting period for this ballot question, enter a new
            submission deadline and click
            <strong>UPDATE</strong>:
          </p>
          <v-layout justify-center class="mt-3">
            <v-flex>
              <date-time-pickers
                label="Ballot Submission Deadline"
                :timezone="currentMeeting.meetingTimezone"
                :iso-date="ballotSubmissionDeadline"
                @update="setBallotSubmissionDeadline"
              />
            </v-flex>
          </v-layout>
        </div>

        <!-- Option 2: voting period is set for # of minutes -->
        <div v-else>
          <!-- Start Question -->
          <p v-if="action === 'start'" class="mt-3">
            To open this question for voting, select a length of time below and
            click <strong>START</strong>
          </p>
          <!-- Extend Question -->
          <p v-else class="mt-3">
            To add more time to this question, select a length of time below and
            click <strong>UPDATE</strong>
          </p>

          <!-- Select Voting Period -->
          <v-layout justify-center>
            <v-flex xs4>
              <v-select
                :items="timeOptions"
                label="Time (mins)"
                v-model="lengthOfTime"
                prepend-icon="alarm"
                outline
                dense
              >
              </v-select>
            </v-flex>
          </v-layout>

          <v-layout v-if="showCheckbox">
            <v-flex xs12>
              <v-checkbox
                v-model="notifyVoters"
                label="Send email notification to voters in attendance"
              ></v-checkbox>
            </v-flex>
          </v-layout>
        </div>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn flat @click="emitClose">cancel</v-btn>
        <!-- Start Question -->
        <v-btn
          v-if="action === 'start'"
          :disabled="isDisabled"
          class="primary"
          @click="handleStart"
          >start</v-btn
        >
        <!-- Update / Extend Question -->
        <v-btn
          v-else
          :disabled="isDisabled"
          class="primary"
          @click="handleExtend"
          >update</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import DateTimePickers from '@/components/DateTimePicker';
export default {
  name: 'RTVStartQuestionDialog',
  components: {
    DateTimePickers
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    action: {
      type: String,
      default: 'start'
    },
    question: {
      type: Object,
      required: true
    },
    currentMeeting: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  watch: {
    question() {
      this.collectRtvBallots
        ? (this.deadlineType = 'date')
        : (this.deadlineType = 'minutes');

      if (this.action === 'extend') {
        this.ballotSubmissionDeadline = this.question.endAt;
      }
    }
  },
  computed: {
    showCheckbox() {
      // Only show the "notify voters" checkbox if we're starting
      // voting, and the notification engine is enabled
      return (
        this.action === 'start' &&
        this.currentMeeting.options?.enableNotificationEngine
      );
    },
    collectRtvBallots() {
      return this.currentMeeting.options?.collectRtvBallots;
    },
    isDisabled() {
      if (this.deadlineType === 'date') {
        return !this.ballotSubmissionDeadline;
      }
      return !this.lengthOfTime;
    }
  },
  data() {
    return {
      deadlineType: null,
      timeOptions: [1, 2, 5, 10, 15, 20],
      lengthOfTime: null,
      ballotSubmissionDeadline: null,
      notifyVoters: true
    };
  },
  methods: {
    emitClose() {
      this.$emit('close');
    },
    setBallotSubmissionDeadline(value) {
      this.ballotSubmissionDeadline = value;
    },
    handleStart() {
      if (this.deadlineType === 'date') {
        this.$emit('setDeadline', this.question, this.ballotSubmissionDeadline);
        this.ballotSubmissionDeadline = null;
      } else {
        this.$emit(
          'start',
          this.question,
          this.lengthOfTime,
          this.notifyVoters
        );
        this.lengthOfTime = null;
        this.notifyVoters = true;
      }
    },
    handleExtend() {
      if (this.deadlineType === 'date') {
        this.$emit(
          'updateDeadline',
          this.question,
          this.ballotSubmissionDeadline
        );
        this.ballotSubmissionDeadline = null;
      } else {
        this.$emit('extend', this.question, this.lengthOfTime);
        this.lengthOfTime = null;
      }
    }
  }
};
</script>
