<template>
  <v-container>
    <div class="title grey--text text--darken-4 mb-1">
      Advanced Settings
    </div>
    <div class="body-1 grey--text text--darken-2 mb-4">
      Advanced and experimental settings.
    </div>

    <v-layout>
      <div class="pr-3">
        <v-checkbox
          v-model="displayVotingResultReport"
          @change="onChange('options.displayVotingResultReport', $event)"
        />
      </div>
      <div>
        <div class="subheading">
          Display Voting Result Report / Attendance Snapshot
        </div>
        <div class="grey--text text--darken-2">
          Click this to show the option to download the voting result metrics.
        </div>
      </div>
    </v-layout>

    <v-layout>
      <div class="pr-3">
        <v-checkbox
          v-model="displayTallyTablesV3"
          @change="onChange('options.displayTallyTablesV3', $event)"
        />
      </div>
      <div>
        <div class="subheading">
          Display V3 Tally Tables
        </div>
        <div class="grey--text text--darken-2">
          Click this to display the V3 tally tables. This will allow the
          additional votes column to be itemized.
        </div>
      </div>
    </v-layout>

    <v-layout>
      <div class="pr-3">
        <v-checkbox
          v-model="displayVoteResultsToggle"
          @change="onChange('options.displayVoteResultsToggle', $event)"
        />
      </div>
      <div>
        <div class="subheading">
          Display Voting Results Options - V3 Tally Table
        </div>
        <div class="grey--text text--darken-2">
          Click this to display the "View Voting Results" option on top of the
          V3 tally table. This will allow switching between viewing voting
          results by either Unit Factors or a 1:1 vote breakdown (i.e., results
          tallied by shares or units).
        </div>
      </div>
    </v-layout>

    <v-layout class="mt-3">
      <div class="pr-3">
        <v-checkbox
          v-model="displayOverviewV2"
          @change="onChange('options.displayOverviewV2', $event)"
        />
      </div>
      <div>
        <div class="subheading">
          Display V2 Overview Page
        </div>
        <div class="grey--text text--darken-2">
          Click this to toggle on the V2 Overview page (admins only). This page
          is intended for self-serve clients.
        </div>
      </div>
    </v-layout>

    <v-layout>
      <div class="pr-3">
        <v-checkbox
          v-model="enableChatWidget"
          @change="onChange('options.enableChatWidget', $event)"
        />
      </div>
      <div>
        <div class="subheading">
          Enable Live Support Chat Beacon
        </div>
        <div class="grey--text text--darken-2">
          Click this to enable the Help Scout live support widget for all users
          in this meeting.
        </div>
      </div>
    </v-layout>

    <v-layout>
      <div class="pr-3">
        <v-checkbox
          v-model="enableNotificationEngine"
          @change="onChange('options.enableNotificationEngine', $event)"
        />
      </div>
      <div>
        <div class="subheading">
          Enable Live Voting Notification Engine
        </div>
        <div class="grey--text text--darken-2">
          Send voters a notification (via email) with meeting portal link
          whenever a live voting period is opened.
        </div>
      </div>
    </v-layout>

    <v-layout>
      <div class="pr-3">
        <v-checkbox
          v-model="enableRtvCustomMaxDelay"
          @change="onChange('options.enableRtvCustomMaxDelay', $event)"
        />
      </div>
      <div>
        <div class="subheading">
          Enable custom maximum delay for RTV pusher events
        </div>
        <div class="grey--text text--darken-2">
          Sets a custom maximum delay range for pusher event triggered requests
          (ex: add-question, update-question). If not set, default delay range
          is 2-5 seconds.
        </div>
        <v-text-field
          v-if="enableRtvCustomMaxDelay"
          v-model="rtvCustomMaxDelay"
          @change="onChange('options.rtvCustomMaxDelay', $event)"
          suffix="seconds"
          type="number"
          clearable
        />
      </div>
    </v-layout>

    <v-layout class="mt-2">
      <div class="pr-3">
        <v-checkbox
          v-model="enableRtvRetryMode"
          @change="onChange('options.enableRtvRetryMode', $event)"
        />
      </div>
      <div>
        <div class="subheading">
          Enable retry mode for RTV error handling
        </div>
        <div class="grey--text text--darken-2">
          For unresponsive server errors (500, 503), RTV will display an alert
          on the vote tab indicating their connection has been lost, and will
          retry requests at a set interval until restored.
        </div>
      </div>
    </v-layout>

    <v-layout class="mt-2">
      <div class="pr-3">
        <v-checkbox
          v-model="enableGetStatsV2"
          @change="onChange('options.enableGetStatsV2', $event)"
        />
      </div>
      <div>
        <div class="subheading">
          Enable Get Meeting Stats v2
        </div>
        <div class="grey--text text--darken-2">
          Click this option to optimize meeting stats process for campaigns with
          large data.
        </div>
      </div>
    </v-layout>

    <v-layout>
      <div class="pr-3">
        <v-checkbox
          v-model="enableRtvPanelists"
          @change="onChange('options.enableRtvPanelists', $event)"
        />
      </div>
      <div>
        <div class="subheading">
          Enable RTV for Panelists
        </div>
        <div class="grey--text text--darken-2">
          Click this to enable the RTV app to the panelists
        </div>
      </div>
    </v-layout>

    <v-layout>
      <div class="pr-3">
        <v-checkbox
          v-model="enableFloridaNominationTool"
          @change="onChange('options.enableFloridaNominationTool', $event)"
        />
      </div>
      <div>
        <div class="subheading">
          Enable Florida Candidate Nomination Tool
        </div>
        <div class="grey--text text--darken-2">
          Toggles the Florida Candidate Nomination Tool for this meeting.
          Differences from the default tool:<br />
          <ul>
            <li>Generates "Notice of Intent" form</li>
            <li>No disclosure questions or form</li>
          </ul>
        </div>
      </div>
    </v-layout>

    <v-layout>
      <div class="pr-3">
        <v-checkbox
          v-model="enablePaperProxiesAlert"
          @change="onChange('options.enablePaperProxiesAlert', $event)"
        />
      </div>
      <div>
        <div class="subheading">
          Enable Paper Proxies Upload Alert
        </div>
        <div class="grey--text text--darken-2">
          Click this option to enable email alert
        </div>
      </div>
    </v-layout>

    <v-layout v-if="enablePaperProxiesAlert">
      <div class="pr-3">
        <v-checkbox
          v-model="includeAttachmentsInPaperProxiesAlert"
          @change="
            onChange('options.includeAttachmentsInPaperProxiesAlert', $event)
          "
        />
      </div>

      <div>
        <div class="subheading">
          Include attachments
        </div>
        <div class="grey--text text--darken-2">
          Toggle this to include/exclude attachments for Paper proxy alert
        </div>
      </div>
    </v-layout>

    <v-layout>
      <div class="pr-3">
        <v-checkbox
          v-model="enableCheckinPdf"
          @change="onChange('options.enableCheckinPdf', $event)"
        />
      </div>
      <div>
        <div class="subheading">
          Enable Check-In PDF
        </div>
        <div class="grey--text text--darken-2">
          This will enable check-in pdf for this campaign.
        </div>
      </div>
    </v-layout>
  </v-container>
</template>

<script>
import _ from 'lodash';
export default {
  name: 'MeetingSettingsAdvancedInput',
  components: {},
  props: {
    options: {
      type: Object,
      required: true
    }
  },
  mounted() {
    this.initState();
  },
  data() {
    return {
      enableChatWidget: false,
      displayOverviewV2: false,
      displayVoteResultsToggle: false,
      displayTallyTablesV3: false,
      displayVotingResultReport: false,
      enableNotificationEngine: false,
      enableRtvRandomDelay: false,
      enableRtvCustomMaxDelay: false,
      rtvCustomMaxDelay: null,
      enableRtvRetryMode: false,
      enableGetStatsV2: false,
      enableFloridaNominationTool: false,
      enableCheckinPdf: false,
      enablePaperProxiesAlert: false,
      includeAttachmentsInPaperProxiesAlert: false,
      enableRtvPanelists: false
    };
  },
  computed: {
    rtvBallotsNotionDoc() {
      return process.env.VUE_APP_NOTION_DOC_RTV_BALLOTS || '';
    }
  },
  methods: {
    initState() {
      this.displayTallyTablesV3 = this.options.displayTallyTablesV3;
      this.enableChatWidget = this.options.enableChatWidget;
      this.displayVoteResultsToggle = this.options.displayVoteResultsToggle;
      this.displayVotingResultReport = this.options.displayVotingResultReport;
      this.enableNotificationEngine = this.options.enableNotificationEngine;
      this.enableRtvRandomDelay = this.options.enableRtvRandomDelay;
      this.enableRtvCustomMaxDelay = this.options.enableRtvCustomMaxDelay;
      this.rtvCustomMaxDelay = this.options.rtvCustomMaxDelay;
      this.enableRtvRetryMode = this.options.enableRtvRetryMode;
      this.enableGetStatsV2 = this.options.enableGetStatsV2;
      this.enablePaperProxiesAlert = this.options.enablePaperProxiesAlert;
      this.includeAttachmentsInPaperProxiesAlert = this.options.includeAttachmentsInPaperProxiesAlert;
      this.enableFloridaNominationTool = this.options.enableFloridaNominationTool;
      this.enableCheckinPdf = this.options.enableCheckinPdf;
      this.enableRtvPanelists = this.options.enableRtvPanelists;
    },
    onChange(key, value) {
      this.$emit('update', { key, value });
    }
  }
};
</script>

<style></style>
