'use strict';

/**
 * @fileoverview Meeting consent templates Vuex submodule.
 * Used to store state for the current meeting's consent template
 */

import _ from 'lodash';
import * as mtgApi from '@/api/meetings';

const state = {
  htmlPreview: ''
};

const getters = {
  htmlPreview: state => state.htmlPreview
};

const actions = {
  /**
   * Gets the html preview on the consent template
   *
   * @param {String}   {shortCode}  the meeting shortCode
   * @param  {string}  {template}   (optional) provide specific template region (ie. 'florida', 'alberta') to preview
   */
  async getConsentTemplatePreview({ commit }, { shortCode, template }) {
    try {
      const res = await mtgApi.getConsentTemplatePreview(shortCode, template);
      commit('SET_CONSENT_TEMPLATE_PREVIEW', res.data);
    } catch (err) {
      console.error('ERROR: getConsentTemplatePreview action', err, shortCode);
      throw err;
    }
  }
};

const mutations = {
  SET_CONSENT_TEMPLATE_PREVIEW(state, htmlPreview) {
    state.htmlPreview = htmlPreview;
  },

  // Reset the state
  CLEAR_STATE(state) {
    state.htmlPreview = '';
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
