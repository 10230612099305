<template>
  <v-dialog
    :value="isOpen"
    persistent
    @input="$emit('close')"
    max-width="500px"
  >
    <v-card>
      <v-card-title>
        <span class="headline">Distribution options</span>
        <v-spacer />
        <v-icon @click="$emit('close')">close</v-icon>
      </v-card-title>
      <v-card-text>Choose an option</v-card-text>
      <v-container class="pt-0">
        <v-layout row wrap>
          <v-flex xs12>
            <v-radio-group
              name="distribution"
              :value="optionSelected"
              :mandatory="true"
              @change="handleUpdate({ value: $event })"
            >
              <v-radio
                v-for="option in options"
                :label="option.label"
                :value="option.value"
                :key="option.value"
              />
            </v-radio-group>
          </v-flex>
        </v-layout>
      </v-container>
      <v-card-actions>
        <v-layout justify-center>
          <v-btn flat @click.native="$emit('close')">Cancel</v-btn>
          <v-spacer />
          <v-btn
            v-if="this.reportType === 'print-distribution'"
            :disabled="!this.optionSelected"
            @click.native="handleAction({ action: 'download' })"
            color="primary"
            class="white--text"
            >download</v-btn
          >
          <v-btn
            v-else-if="this.reportType === 'print-files'"
            :disabled="!this.optionSelected"
            @click.native="handleAction({ action: 'export' })"
            color="primary"
            class="white--text"
            >send</v-btn
          >
        </v-layout>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'PrintDistributionOptionDialog',
  props: {
    isOpen: {
      type: Boolean,
      default: false
    },
    reportType: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      optionSelected: 'all',
      options: [
        { label: 'All Distributions', value: 'all' },
        {
          label: 'Mail and Email',
          value: `mailAndEmail`
        },
        {
          label: 'Mail Only',
          value: 'mailOnly'
        }
      ]
    };
  },
  computed: {},
  methods: {
    handleUpdate({ value }) {
      this.optionSelected = value;
    },
    handleAction({ action }) {
      this.$emit('action', {
        option: this.optionSelected,
        reportType: this.reportType,
        action
      });
    }
  }
};
</script>

<style scoped></style>
