<template>
  <div class="mt-2">
    <div v-for="question in questions" :key="question.question_key">
      <v-layout>
        <div class="gq-cell--section-title ml-2 mb-1">
          {{ question.label }}

          <live-vote-timer :question="question" @closed="emitVotingStopped" />

          <div v-if="hasPositions(question)" style="font-size: 12px;">
            [{{ question.positions }}
            {{ question.positions === 1 ? 'position' : 'positions' }}]
          </div>
        </div>
        <v-spacer />
        <div class="body-2 mr-2 mt-4">
          <span class="caption"
            >{{ showStartTime(question.start_at)
            }}<timezone-helper
              v-if="question.start_at"
              :date="question.start_at"
            />
          </span>
        </div>
      </v-layout>
      <v-layout row class="mb-4">
        <v-flex xs12>
          <vote-tally-combined-table-v2
            :question="question"
            :display-rounded-results="displayRoundedResults"
            :rounded-results-precision="roundedResultsPrecision"
            :collect-rtv-ballots="collectRtvBallots"
            @add-paper-option="emitAddOption"
            @cancel-paper-option="emitCancelOption"
            @delete-paper-option="emitDeleteOption"
            @save-paper-changes="emitSaveAllChanges"
            @cancel-paper-changes="emitCancelAllChanges"
            @enable-save-changes="emitEnableSaveChanges"
            @view-write-in-vote="emitViewWriteInVote"
            @toggle-exclude-vote="emitToggleExcludeVote"
          />
        </v-flex>
      </v-layout>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import moment from 'moment-timezone';
import { mapActions } from 'vuex';
import VoteTallyCombinedTableV2 from '@/components/VoteTallyCombinedTableV2';
import TimezoneHelper from '@/components/TimezoneHelper';
import LiveVoteTimer from '@/components/LiveVoteTimer';

export default {
  name: 'VoteTallyCombinedV2',
  components: { VoteTallyCombinedTableV2, TimezoneHelper, LiveVoteTimer },
  props: {
    questions: {
      type: Array,
      default() {
        return [];
      }
    },
    meetingTimezone: {
      type: String,
      default: 'America/Toronto'
    },
    displayRoundedResults: {
      type: Boolean,
      default() {
        return false;
      }
    },
    roundedResultsPrecision: {
      type: String,
      default: '0'
    },
    collectRtvBallots: {
      type: Boolean,
      default: false
    }
  },
  async created() {
    await this.getMeetingRTVQuestions({
      shortCode: this.$route.params.shortcode
    });
  },
  methods: {
    ...mapActions('meetings/rtvQuestions', ['getMeetingRTVQuestions']),

    showStartTime(date) {
      return !date
        ? ''
        : `Voting started at ${moment(date)
            .tz(this.meetingTimezone ? this.meetingTimezone : 'America/Toronto')
            .format('h:mm:ss a zz')}`;
    },
    hasPositions(question) {
      if (
        (question.type === 'owner-occupied' ||
          question.type === 'general-elections') &&
        question.positions > 0
      ) {
        return true;
      }
      return false;
    },
    emitAddOption(questionId) {
      this.$emit('add-paper-option', questionId);
    },
    emitCancelOption(params) {
      this.$emit('cancel-paper-option', params);
    },
    emitDeleteOption(params) {
      this.$emit('delete-paper-option', params);
    },
    emitSaveAllChanges(questionId) {
      this.$emit('save-paper-changes', questionId);
    },
    emitCancelAllChanges(questionId) {
      this.$emit('cancel-paper-changes', questionId);
    },
    emitEnableSaveChanges(questionId) {
      this.$emit('enable-save-paper-changes', questionId);
    },
    emitViewWriteInVote(params) {
      this.$emit('view-write-in-vote', params);
    },
    emitToggleExcludeVote(params) {
      this.$emit('toggle-exclude-vote', params);
    },
    emitVotingStopped(params) {
      this.$emit('voting-stopped', params);
    }
  }
};
</script>
<style scoped>
.v-text-field {
  width: 40px;
}
.line-vote {
  width: 50%;
}
.new-option {
  background-color: rgba(217, 250, 255, 0.747);
}
</style>
