import http from './requestType';

/** Returns a list of the voters that belong to a unit
 * @param  {String} shortCode - the meeting shortcode
 * @param  {String} unitId - the unit ID
 * @return {Promise} - Promise
 */
export function getMeetingsUnitsVoters(shortCode, unitId) {
  return http._get(`Meetings/${shortCode}/units/${unitId}/voters`);
}

/** Add a voter to a unit. Units cannot have voters with duplicate email addresses.
 * @param  {String} shortCode - the meeting shortcode
 * @param  {String} unitId - the unit ID
 * @param  {Object} voter - Object representing new voter
 * @return {Promise} - Promise
 */
export function postMeetingsUnitsVoters(shortCode, unitId, voter) {
  return http._post(`Meetings/${shortCode}/units/${unitId}/voters`, voter);
}

/** Delete a unit voter
 * @param  {String} shortCode - the meeting shortcode
 * @param  {String} unitId - the unit ID
 * @param  {String} voterHash - MD5 hash of voter's lowercase email address
 * @return {Promise} - Promise
 */
export function deleteMeetingsUnitsVoters(shortCode, unitId, voterHash) {
  return http._delete(
    `Meetings/${shortCode}/units/${unitId}/voters/${voterHash}`
  );
}

/** Update a unit voter. Units cannot have multiple voters with the same email address.
 * @param  {String} shortCode - the meeting shortcode
 * @param  {String} unitId - the unit ID
 * @param  {String} voterHash - MD5 hash of voter's lowercase email address
 * @param  {String} voter - Object representing voter data to update
 * @return {Promise} - Promise
 */
export function putMeetingsUnitsVoters(shortCode, unitId, voterHash, voter) {
  return http._put(
    `Meetings/${shortCode}/units/${unitId}/voters/${voterHash}`,
    voter
  );
}

/**
 * Get the signed URLs for a voter.
 * @param  {String} shortCode  - the meeting shortCode
 * @param  {Integer} unitId    - the unit id
 * @param  {String} voterHash  - the md5 has of voter's lowercase email address
 * @return {Promise}           - the results
 */
export function getVoterLinks(shortCode, unitId, voterHash) {
  return http._get(
    `Meetings/${shortCode}/units/${unitId}/voters/${voterHash}/links`
  );
}

/**
 * Send a voter their Meeting Portal invite email.
 * @param  {String} shortCode  - the meeting shortCode
 * @param  {Integer} unitId    - the unit id
 * @param  {String} voterHash  - the md5 has of voter's lowercase email address
 * @param  {message} message   - (optional) message to include in vbm invite
 * @param  {boolean} troubleshooting   - troubleshooting email
 * @return {Promise}           - the results
 */
export function postSendMeetingPortalInvite(
  shortCode,
  unitId,
  voterHash,
  message,
  troubleshooting = false
) {
  return http._post(
    `Meetings/${shortCode}/units/${unitId}/voters/${voterHash}/send-meeting-invite`,
    { message, troubleshooting }
  );
}

/**
 * Send dial-in information email
 * @param  {String} shortCode  - the meeting shortCode
 * @param  {Integer} unitId    - the unit id
 * @param  {String} voterHash  - the md5 has of voter's lowercase email address
 * @return {Promise}           - the results
 */
export function postSendDialInInformation(shortCode, unitId, voterHash) {
  return http._post(
    `Meetings/${shortCode}/units/${unitId}/voters/${voterHash}/send-dial-in-information`
  );
}
