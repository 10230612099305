'use strict';

/**
 * @fileoverview Meeting Units Vuex submodule.
 * Used to store state for the current meeting's unit data
 */

import _ from 'lodash';
import md5 from 'md5';
import Vue from 'vue';

import * as unitApi from '@/api/meetings-units';
import * as voterApi from '@/api/meetings-units-voters';
import * as fileApi from '@/api/files';

import { removeEmptyProperty } from '@/lib/property-helpers';

const state = {
  meetingUnitsList: {},
  units: [],
  dataLastFetchedAt: null,
  multipleOwners: null,
  testVoters: null,
  filters: [],
  filterType: 'and',
  customData: [],
  customDataKeys: [],
  customFilters: [],
  pagination: {
    descending: false,
    sortBy: 'unit',
    searchQuery: '',
    page: 1,
    rowsPerPage: 25,
    totalItems: 0,
    rowsPerPageItems: [25, 50, 100, 200]
  },
  filterList: [
    {
      value: 'inAttendanceOnly',
      label: 'In Attendance',
      color: 'purple',
      icon: 'person_pin_circle'
    },
    {
      value: 'proxyOnly',
      label: 'Advance Votes',
      color: 'teal',
      icon: 'how_to_vote'
    },
    {
      value: 'ballotOnly',
      label: 'Advance Ballots (RTV)',
      color: 'primary',
      icon: 'how_to_vote'
    },
    {
      value: 'rsvpOnly',
      label: 'RSVPs',
      color: 'blue',
      icon: 'how_to_reg'
    },
    {
      value: 'inArrearsOnly',
      label: 'In Arrears',
      color: 'red',
      icon: 'money_off'
    },
    {
      value: 'paperProxyOnly',
      label: 'Paper Proxies',
      color: 'grey',
      icon: 'assignment'
    },
    {
      value: 'panelistsOnly',
      label: 'Panelists',
      color: 'blue',
      icon: 'voice_chat'
    }
  ],
  additionalFilters: [
    {
      value: 'testVotersOnly',
      label: 'Test Voters',
      color: 'blue-grey lighten-1',
      icon: 'account_box'
    },
    {
      value: 'multiUnitOwners',
      label: 'Multi-unit owners',
      color: 'cyan',
      icon: 'group'
    },
    {
      value: 'customData',
      label: 'Custom data',
      color: 'amber',
      icon: 'question_mark'
    }
  ],
  consentFilters: [
    {
      value: 'consented',
      label: 'Consented',
      color: 'green',
      icon: 'how_to_vote'
    },
    {
      value: 'consentRejected',
      label: 'Rejected',
      color: 'red',
      icon: 'how_to_vote'
    },
    {
      value: 'consentNoAction',
      label: 'No Action',
      color: 'grey',
      icon: 'how_to_vote'
    }
  ],
  multiConsentFilters: [
    {
      value: 'noticeConsent',
      label: 'Notice Consent',
      color: 'green',
      icon: 'email'
    },
    {
      value: 'votingConsent',
      label: 'Voting Consent',
      color: 'green',
      icon: 'ballot'
    },
    {
      value: 'noticeConsentRejected',
      label: 'Notice Consent Rejected',
      color: 'red',
      icon: 'email'
    },
    {
      value: 'votingConsentRejected',
      label: 'Voting Consent Rejected',
      color: 'red',
      icon: 'ballot'
    },
    {
      value: 'multiConsentNoAction',
      label: 'No Action',
      color: 'grey',
      icon: 'block'
    }
  ]
};

const getters = {
  meetingUnitsList: state => state.meetingUnitsList,
  meetingUnitMultiOwners: state => state.multipleOwners,
  meetingUnitTestVoters: state => state.testVoters,
  // Return the list data with filter
  meetingUnitsListData: state => state.units,
  meetingUnitById: state => id => state.meetingUnitsList[id],
  searchQuery: state => state.pagination.searchQuery,
  filters: state => state.filters,
  filterType: state => state.filterType,
  pagination: state => state.pagination,
  customData: state => state.customData,
  customDataKeys: state => state.customDataKeys,
  customFilters: state => state.customFilters,
  filterList: state => state.filterList,
  consentFilters: state => state.consentFilters,
  multiConsentFilters: state => state.multiConsentFilters,
  activeFilters: state => {
    return [
      ...state.filterList,
      ...state.additionalFilters,
      ...state.consentFilters
    ].filter(item => {
      return state.filters.find(filter => filter === item.value);
    });
  }
};

const actions = {
  /**
   * Get the meeting's latest set of units
   * @param {String} {shortCode} the meeting who's units we're looking at
   */
  async getMeetingUnits({ commit, dispatch }, { shortCode }) {
    try {
      // First, get units
      const res = await unitApi.getMeetingsUnitsList({
        shortCode,
        page: state.pagination.page,
        size: state.pagination.rowsPerPage,
        descending: state.pagination.descending,
        searchText: state.pagination.searchQuery,
        filters: state.filters,
        filterType: state.filterType,
        customFields: state.customFilters
      });

      const {
        units = [],
        total = 0,
        multipleOwners = 0,
        testVoters = 0
      } = res.data;

      const unitsArray = units.map(u => {
        if (u.proxy) {
          u.proxy = u.proxy.proxy;
        }

        return u;
      });

      dispatch('getCustomData', { shortCode });

      return commit('SET_CURRENT_MEETING_UNITS_INFORMATION', {
        unitsArray,
        total,
        multipleOwners,
        testVoters
      });
    } catch (err) {
      console.error('ERROR: getMeetingUnits action', err);
      throw err;
    }
  },

  /**
   * Get the unit for this meeting
   * @param {String} {shortCode} the meeting who's units we're looking at
   */
  async getMeetingUnit({ commit }, { shortCode, unitId }) {
    try {
      const res = await unitApi.getMeetingsUnitsId(shortCode, unitId);
      commit('SET_CURRENT_MEETING_UNIT_IN_LIST', res.data);
      return res;
    } catch (err) {
      console.error('ERROR: getMeetingUnit action', err);
      throw err;
    }
  },

  /**
   * Delete a meeting's units
   * @param {String} {shortCode} the meeting who's units to delete
   * @param {Array}  {unitIds}   the array of unitIds to delete
   */
  deleteMeetingUnits({ dispatch }, { shortCode, unitIds }) {
    return unitApi
      .deleteMeetingsMultipleUnitsIds(shortCode, { unitIds })
      .then(() => dispatch('getMeetingUnits', { shortCode }));
  },

  /**
   * Delete all units
   * @param {String} {shortCode} the meeting who's units to delete
   */
  deleteAllMeetingUnits({ dispatch }, { shortCode }) {
    return unitApi
      .deleteAllMeetingUnits(shortCode)
      .then(() => dispatch('getMeetingUnits', { shortCode }));
  },

  /**
   * Reset attendance units
   * @param {String} {shortCode} the meeting who's units to reset
   * @param {Array}  {unitIds}   the array of unitIds to reset
   */
  async resetAttendanceUnits({ dispatch }, { shortCode, unitIds }) {
    try {
      const res = await unitApi.resetAttendanceUnitsIds(shortCode, unitIds);
      dispatch('getMeetingUnits', { shortCode });
      return res.data;
    } catch (err) {
      console.error('ERROR: resetAttendanceUnits action', err);
      throw err;
    }
  },

  /**
   * Add a new unit to the current meeting
   * @param {String} {shortCode} the meeting who's units to delete
   * @param {Object} {newUnit}   new unit to add
   */
  async createMeetingUnit({ dispatch }, { shortCode, newUnit }) {
    try {
      const res = await unitApi.postMeetingsUnitsList(shortCode, newUnit);
      dispatch('getMeetingUnits', { shortCode });
      return res.data;
    } catch (err) {
      console.error('ERROR: createMeetingUnit action', err);
      throw err;
    }
  },

  /**
   * Generate control numbers for no email units in the current meeting
   * @param {String} {shortCode} short code of current meeting
   * @param {Number} {controlNumberDigits}   the number of digits
   * @returns {Object} return message
   */
  async generateControlNumbersForUnits(
    { dispatch },
    { shortCode, controlNumberDigits }
  ) {
    try {
      const res = await unitApi.getUnitsWithNoEmails(shortCode);

      // res.data: array of object {unit id}
      const unitIdList = res.data.map(unit => unit.id);

      if (unitIdList.length === 0) {
        return {
          message: 'No units without emails found'
        };
      }

      await unitApi.putGenerateControlNumbers(
        shortCode,
        unitIdList,
        controlNumberDigits
      );

      dispatch('getMeetingUnits', { shortCode });
      return {
        message: 'Units Updated'
      };
    } catch (err) {
      console.error('ERROR: generateControlNumbers action', err);
      throw err;
    }
  },

  /**
   * Update a unit in the current meeting
   * @param {String} {shortCode} the meeting who's units to update
   * @param {Integer} {unitId}   the unit's id
   * @param {Object} {unitData}   new unit data to update with
   */
  updateMeetingUnit({ dispatch }, { shortCode, unitId, unitData }) {
    // Empty properties must be removed, otherwise will cause validation issues on backend
    let formattedUnitData = removeEmptyProperty(unitData);
    if (unitData.controlNumber === undefined) {
      formattedUnitData.controlNumber = null;
    }
    return unitApi
      .putMeetingsUnitsId(shortCode, unitId, formattedUnitData)
      .then(() => dispatch('getMeetingUnits', { shortCode }));
  },

  /**
   * Update multiple units in the current meeting
   * @param {String} {shortCode} the meeting who's units to update
   * @param {Array} {unitIdList}  List of units Ids
   * @param {Array} {unitsData}   List of new units data
   */
  updateMeetingUnits({ dispatch }, { shortCode, unitIdList, unitsData }) {
    let formattedUnitData = {};

    // Empty properties must be removed, otherwise will cause validation issues on backend
    // removeEmptyProperty also used in updateMeetingUnit
    Object.values(unitsData).forEach(unit => {
      formattedUnitData[unit.id] = removeEmptyProperty(unit);
    });

    return unitApi
      .putMeetingsMultipleUnitsId(shortCode, unitIdList, unitsData)
      .then(() => dispatch('getMeetingUnits', { shortCode }));
  },

  /**
   * Deletes one unit in the current meeting by Id
   * @param {String} {shortCode} the meeting who's units to delete
   * @param {Integer} {unitId}   the unit's id
   */
  deleteMeetingUnit({ dispatch }, { shortCode, unitId }) {
    return unitApi
      .deleteMeetingsUnitsId(shortCode, unitId)
      .then(() => dispatch('getMeetingUnits', { shortCode }));
  },

  /**
   * Add a new owner/voter to a meeting's unit
   *
   * @param  {String} {shortCode} the meeting code
   * @param  {Integer} {unitId}   the unit's id
   * @param  {Object} {newVoter}  the new voter record
   */
  addMeetingUnitVoter({ dispatch }, { shortCode, unitId, newVoter }) {
    return voterApi
      .postMeetingsUnitsVoters(shortCode, unitId, newVoter)
      .then(() => dispatch('getMeetingUnits', { shortCode }));
  },

  /**
   * Add a new owner/voter to a meeting's unit
   *
   * @param  {String} {shortCode} the meeting code
   * @param  {Integer} {unitId}   the unit's id
   * @param  {Object} {newVoter}  the new voter record
   */
  updateMeetingUnitVoter({ dispatch }, { shortCode, unitId, email, voter }) {
    const voterHash = md5(email.toLowerCase());
    return voterApi
      .putMeetingsUnitsVoters(shortCode, unitId, voterHash, voter)
      .then(() => dispatch('getMeetingUnits', { shortCode }));
  },

  /**
   * Delete an owner/voter from a unit in this meeting
   *
   * @param  {String}  {shortCode} the meeting code
   * @param  {Integer} {unitId}    the unit's id
   * @param  {String}  {email}     the owner's email
   */
  deleteMeetingUnitVoter({ dispatch }, { shortCode, unitId, email }) {
    const voterHash = md5(email.toLowerCase());
    return voterApi
      .deleteMeetingsUnitsVoters(shortCode, unitId, voterHash)
      .then(() => dispatch('getMeetingUnits', { shortCode }));
  },

  /**
   * Attach a file for multiple units
   *
   * @param  {string} shortCode - the meeting shortcode
   * @param  {string} requestBody - Payload containing target units and file to add (s3 bucket and key)
   * @param  {string} mode - Payload containing target units and file to add (s3 bucket and key)*
   * @return {Promise} - Promise
   */
  attachMeetingUnitFiles({ dispatch }, { shortCode, requestBody, mode }) {
    return unitApi
      .postMeetingUnitsFiles(shortCode, requestBody, mode)
      .then(() => dispatch('getMeetingUnits', { shortCode }));
  },

  /**
   * Removes all attachments from the specified units.
   *
   * @param  {string} shortCode - the meeting shortcode
   * @param  {object} requestBody - contains object with unitKeys array to remove
   * @return {Promise} - Promise
   */
  removeMeetingUnitsFiles({ dispatch }, { shortCode, requestBody }) {
    return unitApi
      .removeMeetingUnitsFiles(shortCode, requestBody)
      .then(() => dispatch('getMeetingUnits', { shortCode }));
  },

  /**
   * Undo the assignment of shares for units
   *
   * @param  {string} shortCode - the meeting shortcode
   * @param  {object} requestBody - contains property with array of selected and assigned unit numbers
   * @return {Promise} - Promise
   */
  undoAssignShares({ dispatch }, { shortCode, requestBody }) {
    return unitApi
      .undoAssignShares(shortCode, requestBody)
      .then(() => dispatch('getMeetingUnits', { shortCode }));
  },

  /**
   * Delete a specific file from a unit
   *
   * @param  {String}  shortCode   the meeting code
   * @param  {String}  fileKey     The file key
   */
  async removeMeetingUnitFile({ commit, dispatch }, { shortCode, fileKey }) {
    try {
      await fileApi.deleteFile(fileKey);
      dispatch('getMeetingUnits', { shortCode });
    } catch (err) {
      console.error('ERROR: removeUnitFile action', err);
      throw err;
    }
  },

  /**
   * Get the voter url for proxy, consent, nomination and live vote
   *
   * @param  {String}  {shortCode} the meeting code
   * @param  {Integer} {unitId}    the unit's id
   * @param  {String}  {email}     the owner's email
   * @return {Object}              the voter's signed links
   */
  getVoterLinks({ dispatch }, { shortCode, unitId, email }) {
    const voterHash = md5(email.toLowerCase());
    return voterApi
      .getVoterLinks(shortCode, unitId, voterHash)
      .then(res => res.data);
  },

  /**
   * Get the units custom data for this meeting
   * @param {String} {shortCode} the meeting who's units we're looking at
   */
  async getCustomData({ commit }, { shortCode }) {
    try {
      const res = await unitApi.getCustomData(shortCode);
      return commit('SET_CURRENT_MEETING_UNIT_CUSTOM_DATA', res.data);
    } catch (err) {
      console.error('ERROR: getCustomData action', err);
      throw err;
    }
  },

  /**
   * Get the units custom data keys for this meeting
   * @param {String} {shortCode} the meeting who's units we're looking at
   */
  async getCustomDataKeys({ commit }, { shortCode }) {
    try {
      const res = await unitApi.getCustomDataKeys(shortCode);
      return commit('SET_CURRENT_MEETING_UNIT_CUSTOM_DATA_KEYS', res.data);
    } catch (err) {
      console.error('ERROR: getCustomDataKeys', err);
      throw err;
    }
  },

  /**
   * Get total units for this meeting (included deleted ones)
   * Use for VBM meeting unit naming convention
   * @param {String} {shortCode} the meeting who's units we're looking at
   */
  async getTotalUnits({ commit }, { shortCode }) {
    try {
      const res = await unitApi.getTotalUnits(shortCode);
      return res.data;
    } catch (err) {
      console.error('ERROR: getTotalUnits', err);
      throw err;
    }
  },

  /**
   * Send a voter their Meeting Portal invite email.
   *
   * @param  {String}  shortCode   the meeting code
   * @param  {Integer} unitId      the unit's id
   * @param  {String}  email       the owner's email
   * @param  {message} message     optional message
   * @param  {boolean} troubleshooting     troubleshooting value
   * @return {Promise}             Promise
   */
  sendMeetingPortalInvite(
    { dispatch },
    { shortCode, unitId, email, message = null, troubleshooting = false }
  ) {
    let formatEmail = email.toLowerCase();
    // Filter catchall placeholder email
    if (formatEmail === 'noemail@getquorum.com') {
      return;
    }
    const voterHash = md5(formatEmail);
    return voterApi
      .postSendMeetingPortalInvite(
        shortCode,
        unitId,
        voterHash,
        message,
        troubleshooting
      )
      .then(res => res.data);
  },

  /**
   * Send dial-in information
   *
   * @param  {String}  shortCode   the meeting code
   * @param  {Integer} unitId      the unit's id
   * @param  {String}  email       the owner's email
   * @return {Promise}             Promise
   */
  sendDialInInformation({ dispatch }, { shortCode, unitId, email }) {
    let formatEmail = email.toLowerCase();
    // Filter catchall placeholder email
    if (formatEmail === 'noemail@getquorum.com') {
      return;
    }
    const voterHash = md5(formatEmail);
    return voterApi
      .postSendDialInInformation(shortCode, unitId, voterHash)
      .then(res => res.data);
  },

  /**
   * Send voter registration cover pages by email
   *
   * @param  {String}  shortCode   the meeting code
   * @param  {Array}   unitsList   the list of units to be processed
   * @return {Promise}             Promise
   */
  sendVoterRegistrationPages({ dispatch }, { shortCode, unitsList }) {
    const unitIdList = unitsList.map(unit => unit.unitId);
    return unitApi
      .postSendVoterRegistrationPages(shortCode, unitIdList)
      .then(res => res.data);
  },

  /**
   * Imports units into a meeting from a previous meeting
   * @param  {string} shortCode                - meeting shortcode
   * @param  {string} importShortCode          - (optional) source meeting shortcode to import from (priority)
   * @param  {boolean} importRecent            - (optional) copy from most recent meeting
   * @param  {string} importMeetingType        - (optional) specific meeting type to import
   * @param  {string} importCustomData         - (optional) specific unit customData to import
   * @return {Promise} - Promise
   */
  async postImportUnitsFromMeeting(
    { dispatch },
    {
      shortCode,
      importShortCode,
      importRecent,
      importMeetingType,
      importCustomData
    }
  ) {
    const res = await unitApi.postImportUnitsFromMeeting({
      shortCode,
      importShortCode,
      importRecent,
      importMeetingType,
      importCustomData
    });

    dispatch('getMeetingUnits', { shortCode });
    return res.data;
  },

  setSearchQuery({ commit }, searchQuery) {
    return commit('SET_UNIT_SEARCH_QUERY', searchQuery);
  },

  setFilters({ commit }, filters) {
    commit('SET_USER_FILTERS', filters);
  },

  setFilterType({ commit }, type) {
    commit('SET_FILTER_TYPE', type);
  },

  setPagination({ commit }, payload) {
    commit('SET_PAGINATION', payload);
  },

  setCustomFilters({ commit }, filters) {
    commit('SET_CUSTOM_FILTERS', filters);
  }
};

const mutations = {
  SET_CURRENT_MEETING_UNITS_INFORMATION(
    state,
    { unitsArray, total, multipleOwners, testVoters }
  ) {
    state.meetingUnitsList = _.keyBy(unitsArray, 'id');
    state.units = unitsArray;
    state.pagination.totalItems = Number(total);
    state.dataLastFetchedAt = new Date();
    state.multipleOwners = multipleOwners;
    state.testVoters = testVoters;
  },

  SET_PAGINATION(state, payload) {
    state.pagination = payload;
  },

  SET_CURRENT_MEETING_UNIT_IN_LIST(state, unit) {
    Vue.set(state.meetingUnitsList, unit.id, unit);
  },
  SET_CURRENT_MEETING_UNIT_CUSTOM_DATA(state, data) {
    state.customData = data;
  },

  SET_CURRENT_MEETING_UNIT_CUSTOM_DATA_KEYS(state, data) {
    state.customDataKeys = data.map(item => item.custom_data_key);
  },

  // Reset the state
  CLEAR_STATE(state) {
    state.meetingUnitsList = {};
    state.units = [];
    state.filters = [];
    state.filterType = 'and';
    state.dataLastFetchedAt = null;
  },

  SET_UNIT_SEARCH_QUERY(state, searchQuery) {
    state.pagination.searchQuery = searchQuery;
  },

  SET_USER_FILTERS(state, filters) {
    Vue.set(state, 'filters', filters);
  },

  SET_FILTER_TYPE(state, type) {
    state.filterType = type;
  },

  SET_CUSTOM_FILTERS(state, filters) {
    state.customFilters = filters;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
