<template>
  <div class="consent-status">
    <v-chip v-if="status === 'consented'" label class="green-alert elevation-0">
      <v-avatar>
        <v-icon color="green darken-2">check_circle</v-icon>
      </v-avatar>
      <span>
        Provided electronic consent on
        <b>{{ statusDate }}</b>
      </span>
    </v-chip>
    <v-chip
      v-else-if="status === 'rejected'"
      label
      class="red-alert elevation-0"
    >
      <v-avatar>
        <v-icon color="error">error</v-icon>
      </v-avatar>
      <span>
        Rejected electronic consent on
        <b>{{ statusDate }}</b>
      </span>
    </v-chip>
    <v-chip v-else label class="yellow-alert elevation-0">
      <v-avatar>
        <v-icon color="amber darken-3">warning</v-icon>
      </v-avatar>
      <span>Has not consented to email communications</span>
    </v-chip>
  </div>
</template>

<script>
import { formatDate } from '@/helpers.js';

export default {
  name: 'ConsentStatusAlert',
  props: {
    consent: {
      type: Object,
      default() {
        return {};
      },
      meetingTimezone: {
        type: String,
        default: null
      }
    }
  },
  computed: {
    status() {
      return this.consent.status;
    },
    statusDate() {
      return formatDate(
        this.consent.timestamp,
        this.meetingTimezone ? this.meetingTimezone : 'America/Toronto',
        'MMMM Do YYYY, h:mmA zz'
      );
    }
  }
};
</script>

<style scoped>
.consent-status > .yellow-alert {
  width: 100%;
  background-color: rgba(255, 152, 0, 0.12);
}
.consent-status > .red-alert {
  width: 100%;
  background-color: rgba(255, 82, 82, 0.12);
}
.consent-status > .green-alert {
  width: 100%;
  background-color: rgba(76, 175, 80, 0.12);
}
</style>
