<template>
  <v-container grid-list-md class="mt-3">
    <v-layout row>
      <h1 class="headline">Schedule New Campaign</h1>
    </v-layout>

    <v-layout row wrap>
      <v-flex grow class="mb-5">
        <v-card class="mt-3 px-5 py-3" min-height="220px">
          <meeting-create-campaign
            :new-meeting="newMeeting"
            :account-list-data="accountListData"
            :loading="loading.searching"
            @search="updateSearch"
            @update="handleUpdate"
          />

          <meeting-create-confirm
            v-if="isEnabled"
            :name="newMeeting.name"
            @update="handleUpdate"
          />

          <meeting-request-proof
            v-if="isEnabled"
            :meeting-proof="newMeeting.meetingProof"
            @update="handleUpdate"
          />

          <v-btn
            class="mb-5"
            v-if="isEnabled"
            :loading="loading.addNewMeeting"
            :disabled="isDisabled"
            color="primary"
            @click.native="dialog = true"
          >
            <v-icon>add</v-icon>Add Campaign
          </v-btn>
        </v-card>
      </v-flex>

      <v-flex
        :shrink="accountNotes.length === 0"
        v-if="accountNotes.length > 0"
      >
        <div class="account-notes-container">
          <span class="body-2 grey--text">Account Notes</span>

          <div v-for="(note, index) in accountNotes" :key="`key-${index}`">
            <note-message :note="note" :index="index" :hide-edit-btn="true" />
          </div>
        </div>
      </v-flex>
    </v-layout>

    <v-dialog max-width="600" v-model="dialog">
      <v-card>
        <v-card-title>
          <span class="title capitalize">Add Checklists?</span>
        </v-card-title>
        <v-card-text
          ><p>
            To create this campaign and move on to adding checklists, click
            <b>Add Checklists</b>.
          </p>
          <p>
            To create this campaign but skip adding checklists, click
            <b>Skip Checklists</b>.
          </p>
        </v-card-text>
        <v-card-actions>
          <v-btn
            flat
            color="error"
            :loading="loading.addNewMeeting"
            :disabled="loading.addNewMeeting"
            @click.native="
              addChecklists = false;
              addNewMeeting();
            "
            >Skip Checklists</v-btn
          >
          <v-spacer />
          <v-btn
            flat
            color="primary"
            :loading="loading.addNewMeeting"
            :disabled="loading.addNewMeeting"
            @click.native="
              addChecklists = true;
              addNewMeeting();
            "
            >Add Checklists</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';

import { mapActions, mapGetters } from 'vuex';

import generateMeetingName from '@/lib/generate-meeting-name';
import MeetingCreateCampaign from './MeetingCreateCampaign';
import MeetingCreateConfirm from './MeetingCreateConfirm';
import MeetingRequestProof from './MeetingRequestProof';
import NoteMessage from '@/components/NoteMessage';

export default {
  name: 'MeetingCreateView',
  components: {
    MeetingCreateCampaign,
    MeetingCreateConfirm,
    NoteMessage,
    MeetingRequestProof
  },
  data() {
    return {
      newMeeting: {
        name: '',
        selectedCorp: null,
        type: null,
        typeService: null,
        customType: 'other',
        subtype: null,
        virtualMeetingType: null,
        meetingProof: {
          type: null,
          value: null,
          crmDeal: null,
          crmDealUrl: null
        },
        meetingTimezone: 'America/Toronto',
        meetingDate: moment()
          .startOf('day')
          .hour(19)
          .minute(0)
          .format(),
        expiryDate: moment()
          .startOf('day')
          .hour(16)
          .minute(0)
          .format(),
        nominationExpiryDate: moment()
          .subtract(17, 'days')
          .startOf('day')
          .hour(19)
          .minute(0)
          .format()
      },
      loading: {
        addNewMeeting: false,
        searching: false
      },
      dialog: false,
      addChecklists: false
    };
  },
  beforeRouteEnter(to, from, next) {
    window.document.title = 'Schedule Meeting';
    next();
  },
  beforeRouteLeave(to, from, next) {
    // Needed to reset page title if exiting wizard without creating a meeting
    window.document.title = 'GetQuorum Dashboard';
    next();
  },
  computed: {
    ...mapGetters('accounts', ['accountListData']),
    ...mapGetters('meetings/partners', ['partnerData', 'isPartnerModel']),
    isEnabled() {
      // if corporation is selected and meeting type is selected
      if (!this.newMeeting.selectedCorp || !this.newMeeting.type) {
        return false;
      }

      // if meeting type is selected, but subtype is not
      if (this.newMeeting.type === 'meeting' && !this.newMeeting.subtype) {
        return false;
      }

      // if virtual meeting is selected, but not webcast type is selected
      if (
        this.newMeeting.type === 'meeting' &&
        this.newMeeting.subtype == 'Virtual' &&
        !this.newMeeting.virtualMeetingType
      ) {
        return false;
      }

      if (this.isPartnerModel && !this.partnerData.key) {
        return false;
      }

      // If all the above passes, then enable the continue button
      return true;
    },
    isDisabled() {
      if (
        (this.newMeeting.meetingProof.type === null &&
          this.newMeeting.meetingProof.value === null) ||
        (this.newMeeting.meetingProof.type !== 'inDeal' &&
          this.newMeeting.meetingProof.value === null)
      ) {
        return true;
      }

      if (
        this.newMeeting.meetingProof.crmDeal === null ||
        (this.newMeeting.meetingProof.crmDeal &&
          !this.newMeeting.meetingProof.crmDealUrl)
      ) {
        return true;
      }

      return false;
    },
    accountNotes() {
      return _.get(this.newMeeting, 'selectedCorp.data.notes', []);
    }
  },
  watch: {
    // If the date or type of meeting changes, defaults will update
    'newMeeting.meetingDate': function(date) {
      // Set the proxy deadline to same day as meetingDate
      this.newMeeting.expiryDate = moment(date)
        .startOf('day')
        .hour(12)
        .minute(0)
        .format();

      // Set the revocation deadline to be right when the meeting starts
      this.newMeeting.revocationDeadlineDate = this.newMeeting.meetingDate;

      // Set the candidate nomination deadline to 17 day before the meetingDate
      this.newMeeting.nominationExpiryDate = moment(date)
        .subtract(17, 'days')
        .startOf('day')
        .hour(19)
        .minute(0)
        .format();

      // Specific check for new florida meetings to add a default consent deadline
      // Florida Statute states requires 14 days, we set to 15 to account for stragglers
      if (this.newMeeting?.selectedCorp?.region === 'FL') {
        this.newMeeting.consentDeadlineDate = moment(date)
          .clone()
          .subtract(14, 'days')
          .endOf('day')
          .format();
      }
    },
    'newMeeting.type': function(type) {
      // If the user swaps between campaign types, ensure it defaults back to In-Person
      // Prevents webcast from being created accidentally
      if (type !== 'meeting') {
        this.newMeeting.subtype = 'In-Person';
      }
      this.setMeetingDefaults();
    },
    'newMeeting.typeService': function() {
      this.setMeetingDefaults();
    },
    'newMeeting.customType': function() {
      this.setMeetingDefaults();
    },
    'newMeeting.subtype': function() {
      this.setMeetingDefaults();
    },
    'newMeeting.selectedCorp': function() {
      if (this.newMeeting.type) {
        this.setMeetingDefaults();
      }
    }
  },
  methods: {
    ...mapActions('meetings', [
      'createMeeting',
      'updateMeeting',
      'addMeetingDocument'
    ]),
    ...mapActions('accounts', ['getAccountListData', 'setSearch']),
    ...mapActions('meetings/webcast', ['createWebcast']),
    ...mapActions('meetings/partners', ['insertPartner']),
    ...mapActions('meetings/files', ['getPutSignedUrl', 'putSignedUrl']),
    ...mapActions('meetings/reports', ['getReportUrl']),
    setMeetingDefaults() {
      this.newMeeting.name = generateMeetingName(this.newMeeting);
      this.newMeeting.virtualMeetingType = 'GQ-Hosted';
    },
    async addNewMeeting() {
      this.loading.addNewMeeting = true;
      try {
        const virtualType = _.get(this.newMeeting, 'virtualMeetingType', null);
        const meetingSubtype = this.newMeeting.subtype;
        const meetingProof = this.newMeeting.meetingProof;
        const isMeeting = virtualType === 'GQ-Hosted-Meeting';

        const newMeeting = await this.createMeeting({
          ...this.newMeeting
        });

        // If it's a virtual / hybrid meeting, automatically create webcast
        if (
          newMeeting.options.type === 'meeting' &&
          ['Virtual', 'Hybrid'].includes(meetingSubtype) &&
          (virtualType === 'GQ-Hosted' || isMeeting)
        ) {
          const payload = { is_meeting: isMeeting };
          await this.createWebcast({
            shortCode: newMeeting.shortCode,
            payload
          });

          // auto generate the user guide report for this meeting
          if (
            newMeeting?.accountCustomerType === 'condo-hoa' &&
            newMeeting?.region === 'ON'
          ) {
            // get the file object from s3
            const document = await this.getReportUrl({
              shortCode: newMeeting.shortCode,
              reportType: 'user-guide',
              region: 'default',
              folder: `${newMeeting.shortCode}/uploads`
            });

            // creates the relationship with the meeting
            await this.addMeetingDocument({
              shortCode: newMeeting.shortCode,
              document
            });
          }
        }

        // if signed agreement file exists
        if (_.get(meetingProof.value, 'size', null) !== null) {
          // upload file
          const fileObj = await this.processFile({
            file: meetingProof.value,
            shortCode: newMeeting.shortCode
          });

          // update meeting meta with the new upload
          await this.updateMeeting({
            shortCode: newMeeting.shortCode,
            meeting: {
              ...newMeeting,
              meetingMeta: {
                meetingRequestProof: { ...meetingProof, value: fileObj }
              }
            }
          });
        }

        // If partner is added to a new meeting, then partner model is created
        if (this.partnerData.key) {
          await this.insertPartner({ shortCode: newMeeting.shortCode });
        }

        this.$events.$emit('toastEvent', 'New Meeting Added');

        // If we're adding checklists, route to the checklists tab
        if (this.addChecklists) {
          this.$router.push({
            name: 'meetingChecklists',
            params: { shortcode: newMeeting.shortCode, launchAddDialog: true }
          });
        }
        // Otherwise, route to settings page
        else {
          this.$router.push({
            name: 'meetingSettings',
            params: { shortcode: newMeeting.shortCode }
          });
        }
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      } finally {
        this.loading.addNewMeeting = false;
      }
    },
    updateSearch: _.debounce(async function(search) {
      this.loading.searching = true;
      await this.setSearch({ search, shortcodeOnly: true });
      await this.getAccountListData();
      this.loading.searching = false;
    }, 350),
    // Updates new meeting settings
    handleUpdate(obj) {
      // set by default compaign type based on category selected
      if (obj.value === 'other') {
        _.set(this.newMeeting, 'typeService', 'custom_consent');
      }

      if (obj.value === 'meeting') {
        _.set(this.newMeeting, 'typeService', 'meeting_agm');
      }

      if (obj.value === 'certs') {
        _.set(this.newMeeting, 'typeService', 'certs_icu');
      }

      _.set(this.newMeeting, obj.key, obj.value);
    },
    async processFile({ file, shortCode }) {
      try {
        // Get the signed url
        let data = await this.getPutSignedUrl({
          shortCode,
          fileName: file.name,
          folder: 'files'
        });

        // Upload to the signed url
        let putRes = await this.putSignedUrl({
          url: data.putObjectSignedUrl,
          file
        });

        if (putRes.status) {
          return {
            name: file.name,
            size: file.size,
            url: data.getObjectSignedUrl,
            Bucket: data.Bucket,
            Key: data.Key
          };
        }
      } catch (err) {
        this.$events.$emit('showErrorDialog', err.response);
      }
    }
  }
};
</script>

<style scoped>
.account-notes-container {
  position: relative;
  bottom: 9px;
}
</style>
